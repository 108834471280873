import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#007079",
    },
    secondary: {
      main: "#DFF5FF",
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ["Equinor"].join(","),
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiListItemButton: {
      defaultProps: {},
      styleOverrides: { root: { fontFamily: "Equinor" } },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
        margin: "dense",
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          margin: "10px",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableRipple: true,
        size: "small",
      },
      styleOverrides: {
        root: {
          margin: "10px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPopover: {
      defaultProps: {},
      styleOverrides: {
        paper: {
          width: "30%",
          height: "50%",
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        //textAlign: "center"
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: "top",
      },
      styleOverrides: {
        tooltip: {
          maxWidth: "none",
        },
      },
    },
    MuiCardMedia: {styleOverrides: {
      img: {
        width: "150px",
        display: "inline"
      }
    }},
    MuiChip: {
      styleOverrides: {
        root: {
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "#007079",
        },
      },
    },
  },
});

export default theme;
