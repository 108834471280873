import React, {useState, useEffect } from "react";
import {
  Grid,} from "@mui/material";
  import { NUMERAL } from "../Constant";
import { appInsightTrackEvent, appInsightTrackException } from "../Logging/Logging";
import { daWinciSynergiCompanyListWithSearch } from "../_axios";
import Select from "react-select";
import styled from 'styled-components';
//input : an array of object with id and description properties
//output: selected dropdown's description and id | typed in text in search bar
const SearchAndDropdown = (props) => {
 

  const StyledSelect = styled(Select)`
  .css-13cymwt-control {
    width:  400px !important;
  }
`;

const handleSelectChange = (selectedOption) => {
  const index = filteredOptions && filteredOptions.length > 0 ?
    filteredOptions.findIndex(option => option.value === selectedOption.value) :
    -1; // default value if filteredOptions is not defined or empty
    setSelectedOption(selectedOption);
  if (index >= 0) {
    const event = { target: { value: selectedOption.value } };
    const child = { label: selectedOption.label, value: selectedOption.value };
  

    props.handleSelection(index, child);
  }
};


  const options = props.synergiCompanyList?.map((element) => {
    
    return { value: element.id, label: element.name };
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setSearchTerm(inputValue);
   
  };

  useEffect(() => {
    if (searchTerm.length >= 3) {
      const filtered = options?.filter((option) =>
        option.label.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions([]);
    }
  }, [searchTerm]);
  const noOptionsMessage =
  searchTerm.length < 3
    ? "Type at least 3 letters to search"
    : "No results found";

  return (
    <React.Fragment>
      <Grid container spacing={2}>
<div style={{ width: "400px",marginTop:"20px" }}>
<Select
  options={filteredOptions}
  value={selectedOption}
  onChange={handleSelectChange}
  onInputChange={handleInputChange}
  noOptionsMessage={() => noOptionsMessage}
/>
</div>


        
        {/* <Grid item xs={12}>
          <TextField
            placeholder="Search company here"
            variant="outlined"
            size="small"
            onChange={handleTextChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ marginLeft: "10px", paddingTop: "2px" }}>
          <Select
            //value={props.value}
            onChange={handleSelection}
            fullWidth
            size="small"
            disabled={props.searchText.length >= NUMERAL.THREE ? false : true}
            MenuProps={{
              PaperProps: { sx: { width: "400px", height: "300px" } },
            }}
          >
            {props.synergiCompanyList.map((element) => {
              return (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              );
            })}
          </Select>
          {props.searchText.length >= NUMERAL.THREE ? (
            props.synergiCompanyList.length > NUMERAL.ZERO ? (
              <FormHelperText style={{ color: "black" }}>
                Please select a company from dropdown
              </FormHelperText>
            ) : (
              <FormHelperText style={{ color: "black" }}>
                No companies found
              </FormHelperText>
            )
          ) : (
            <FormHelperText style={{ color: "black" }}>
              Current company list is too big to display. Please type in atleast
              3 characters to enable the dropdown.
            </FormHelperText>
          )}
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

export default SearchAndDropdown;