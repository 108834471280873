import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import { Button, Divider, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import { deepLevelLocationData } from "../_axios";
import { deepLevelResponsibleUnitData } from "../_axios";
import { StarProgress } from "@equinor/eds-core-react";
import _ from "lodash";
import { locRuContext } from "../Mapping/MappingTab";
import { snackbarContext } from "../../App";
import { API_RESPONSE_STATUS } from "../Constant";
import {
  appInsightTrackEvent,
  appInsightTrackException,
} from "../Logging/Logging";

function PopOverContentB(props) {
  //use context to get values from mapping tab
  const { _locationAndRu, _setLocationAndRu, _setLocRuChangeFlag } =
    useContext(locRuContext);

  //states
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValueId, setSelectedValueId] = useState();
  const [dataFromService, setDataFromService] = useState({
    dataFromService: props.showParentDropdownData
      ? props.levelOneList
      : props.ChildLevelData,
    levelFlag: props.showParentDropdownData ? true : false,
  });
  const [itemSelected, setItemSelected] = useState(false);
  const [itemSelectedIndex, setItemSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  //for snackbar
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
    useContext(snackbarContext);

  //fetch further levels of location selected
  const onLevelOneSelect = async (parameter) => {
    let levelOneResponse;

    if (props.identifier === "location") {
      try {
        levelOneResponse = await deepLevelLocationData(
          props.accessToken,
          parameter
        );
       // console.log(levelOneResponse)
      } catch (error) {
        appInsightTrackException(levelOneResponse.data);
      }
    }

    if (props.identifier === "respUnit") {
      try {
        levelOneResponse = await deepLevelResponsibleUnitData(
          props.accessToken,
          parameter
        );
      } catch (error) {
        appInsightTrackException(error);
      }
    }

    if (levelOneResponse.status === 200) {
      return levelOneResponse.data.data;
    } else {
      appInsightTrackEvent(levelOneResponse.data === "" ? "Something went wrong,Please Try Again" : levelOneResponse.data);
      _setShouldOpen(true);
      if (levelOneResponse.status === 204) {
        _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
        _setSnackbarMessage(levelOneResponse.data.status.message);
      } else {
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage(levelOneResponse.data === "" ? "Something went wrong,Please Try Again" : levelOneResponse.data);
      }

      return [];
    }
  };

  const arrowContentFormat = (content) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {content}
        </Grid>
        <Grid item xs={2}>
          <ArrowRightIcon color="primary" />
        </Grid>
      </Grid>
    );
  };

  const radioContentFormat = (content) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {content}
        </Grid>
        <Grid item xs={2}>
          <RadioButtonCheckedIcon color="primary" />
        </Grid>
      </Grid>
    );
  };

  const handleLevelOneDropdownSelection = async (event, key) => {
    setLoading(true);
    let respo = await onLevelOneSelect(
      dataFromService.dataFromService[key].level1Name
    );

    let selections = _.cloneDeep(selectedValues);
    selections.push(dataFromService.dataFromService[key].level1Name);

    setLoading(false);
    setDataFromService({ dataFromService: respo, levelFlag: false });
    setSelectedValues(selections);
    setLocationAndRuToContext(dataFromService.dataFromService[key].level1Id);
    setSelectedValueId(dataFromService.dataFromService[key].level1Id);
    _setLocRuChangeFlag(true);
  };

  const handleDropdownSelection = (key) => {
    let selections = _.cloneDeep(selectedValues);
    //pushes the selected value to the list of selections and drills the dataservice one level down
    selections.push(dataFromService.dataFromService[key].name);

    setLocationAndRuToContext(dataFromService.dataFromService[key].id);
    setSelectedValueId(dataFromService.dataFromService[key].id);

    setDataFromService({
      ...dataFromService,
      dataFromService: dataFromService.dataFromService[key].value,
    });
    setSelectedValues(selections);
  };

  const handleItemSelection = (key) => {
    //updates which item is selected at the final level

    let selections = _.cloneDeep(selectedValues);

    if (itemSelected) {
      selections.pop();
    }

    selections.push(dataFromService.dataFromService[key].name);

    setLocationAndRuToContext(dataFromService.dataFromService[key].id);
    setSelectedValueId(dataFromService.dataFromService[key].id);

    setItemSelected(true);
    setItemSelectedIndex(key);
    setSelectedValues(selections);
  };

  const setLocationAndRuToContext = (id) => {
    let originalData = _.cloneDeep(_locationAndRu);
    if (props.identifier === "location") {
      originalData.locationId = id;
    }

    if (props.identifier === "respUnit") {
      originalData.ruId = id;
    }

    _setLocationAndRu(originalData);

    //setting flag to enable/disable save button
    _setLocRuChangeFlag(true);
  };

  const displayElementsArray = () => {
    if (loading) {
      return <StarProgress style={{ marginLeft: "50%", marginTop: "15%" }} />;
    } else {
      if (dataFromService.levelFlag) {
        return dataFromService.dataFromService.map((levelOneItem, index) => {
          let elementSelection = (
            <Typography
              style={{ borderBottom: "inset" }}
              onClick={(event) => handleLevelOneDropdownSelection(event, index)}
            >
              {arrowContentFormat(levelOneItem.level1Name)}
            </Typography>
          );

          if (itemSelected && itemSelectedIndex === index)
            elementSelection = (
              <Typography style={{ borderBottom: "inset" }}>
                {radioContentFormat(levelOneItem.level1Name)}
              </Typography>
            );

          return (
            <Grid container key={levelOneItem.level1Id}>
              <Grid item xs={12}>
                {elementSelection}
              </Grid>
            </Grid>
          );
        });
      } else {
        return dataFromService.dataFromService.map((one, index) => {
          let elementSelection = (
            <Typography
              onClick={() => handleItemSelection(index)}
              style={{ borderBottom: "inset" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  {one.name}
                </Grid>
                <Grid item xs={2}>
                  <RadioButtonUncheckedIcon color="primary" />
                </Grid>
              </Grid>
            </Typography>
          );

          if (itemSelected && itemSelectedIndex === index)
            elementSelection = (
              <Typography style={{ borderBottom: "inset" }}>
                {radioContentFormat(one.name)}
              </Typography>
            );

          if (one.value.length > 0) {
            elementSelection = (
              <Typography
                onClick={() => handleDropdownSelection(index)}
                style={{ borderBottom: "inset" }}
              >
                {arrowContentFormat(one.name)}
              </Typography>
            );
          }

          return (
            <Grid container key={one.name}>
              <Grid item xs={12}>
                {elementSelection}
              </Grid>
            </Grid>
          );
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Box m={2}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderRadius: "10px",
              paddingLeft: "5px",
              marginBottom: "10px",
            }}
          >
            <Typography style={{ fontStyle: "italic" }} variant={"caption"}>
              Note: Clicking on <ArrowRightIcon /> selects the option and shows
              further sublevels of selected option. <HomeIcon /> shows the
              current selection
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <HomeIcon color="primary" />
              {selectedValues.join(" > ")}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Box m={2}>
          <Grid container>{displayElementsArray()}</Grid>
        </Box>
        <Box m={2}>
          <Grid container>
            <Divider />
            <Grid item xs={6}>
              <Button
                onClick={() =>
                  props.dropDownSelection(
                    selectedValues,
                    props.identifier,
                    selectedValueId
                  )
                }
              >
                OK
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={props.processCancellation}>Cancel</Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
}

PopOverContentB.defaultProps = {
  showParentDropdownData: true,
};

export default PopOverContentB;
