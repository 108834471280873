import React from "react";
import ReactDOM from "react-dom";
import theme from "./Theme";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import { reactPlugin } from "./Components/Logging/Logging";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <App />
      </AppInsightsContext.Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
