import React from "react";
import { Button, Grid } from "@mui/material";
import DaWinciSynergiDeptReferenceTable from "./DaWinciSynergiDeptReferenceTable";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import { IDENTIFIER } from "../../../Constant";

const DeptRuComponent = (props) => {
  return (
    <React.Fragment>
      <Grid container>
          <DaWinciSynergiDeptReferenceTable
            data={props.data}
            accessToken={props.accessToken}
            handleTableRowDeleteDept={props.handleTableRowDeleteDept}
            childLevelsForRu={props.childLevelsForRu}
            assetId={props.assetId}
            isEditModeOn_ru={props.isEditModeOn_ru}
          />
        
        {/* <Grid item xs={12} textAlign="center">
          <Button
            variant="text"
            onClick={() => props.handleRowAddition(IDENTIFIER.DEPARTMENTRU)}
          >
            <AddBoxTwoToneIcon />
          </Button>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

export default DeptRuComponent;
