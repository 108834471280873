
import React from "react";
import { Grid, Tooltip, Fade } from "@mui/material";
import { Chip } from "@equinor/eds-core-react";
import { NUMERAL } from "./Constant";export const arrayToChip = (_dataArray) => {
  let data;
  let lastElement;

  if (!_dataArray || !Array.isArray(_dataArray)) { // add this check to ensure _dataArray is defined and an array
    return null;
  }

  if (_dataArray.length !== NUMERAL.ZERO) {
    data = _dataArray.join(" ---> ");
    lastElement = _dataArray[_dataArray.length - 1];
  } else {
    return null;
  }

  return (
    <Grid item>
      <Tooltip
        title={data}
        placement={"top"}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
      >
        <Chip>{lastElement}</Chip>
      </Tooltip>
    </Grid>
  );
};