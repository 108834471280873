import React from "react";
import Typography from "@mui/material/Typography";

function GenericError(props) {
  return (
    <React.Fragment>
      <Typography
        variant="h6"
        style={{
          marginTop: "100px",
          marginLeft: "100px",
          color: "red",
        }}
      >
        {props.errorMessage}
      </Typography>
    </React.Fragment>
  );
}

export default GenericError;
