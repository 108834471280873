import { Button, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const MonthDropdown = ({ onMonthYearChange }) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const storedMonthYear = sessionStorage.getItem('selectedMonthYear');
  const [initialYear, initialMonth] = storedMonthYear ? storedMonthYear.split('-') : [currentYear, currentMonth > 1 ? currentMonth - 1 : 12];

  const [selectedMonthName, setSelectedMonthName] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const [selectedMonthYear, setSelectedMonthYear] = useState(() => {
    if (storedMonthYear) {
      return storedMonthYear;
    } else {
      return `${initialYear}-${initialMonth.toString().padStart(2, '0')}`;
    }
  });

  useEffect(() => {
    const [year, month] = selectedMonthYear.split('-');
    const date = new Date(year, month - 1);
    const monthName = date.toLocaleString('en-US', { month: 'long' });
    setSelectedMonthName(monthName);
    setSelectedYear(year);
    onMonthYearChange(monthName, year);
    sessionStorage.setItem('selectedMonthYear', selectedMonthYear);
  }, [selectedMonthYear, onMonthYearChange]);

  const months = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ];

  const years = [];
  for (let i = currentYear; i >= currentYear - 2; i--) {
    years.push({ label: i.toString(), value: i.toString() });
  }

  const handleBackClick = () => {
    let year = parseInt(selectedMonthYear.substring(0, 4));
    let month = parseInt(selectedMonthYear.substring(5)) - 1;
    if (month < 1) {
      month = 12;
      year--;
    }
    setSelectedMonthYear(`${year}-${month.toString().padStart(2, '0')}`);
  };

  const handleNextClick = () => {
    let year = parseInt(selectedMonthYear.substring(0, 4));
    let month = parseInt(selectedMonthYear.substring(5)) + 1;
    if (month > 12) {
      month = 1;
      year++;
    }
    setSelectedMonthYear(`${year}-${month.toString().padStart(2, '0')}`);
  };

  return (
    <Grid>
      <div className="month-dropdown">
        <div className="month-selector">
          Month
          <Button variant="text" onClick={handleBackClick}><ArrowBackIosIcon /></Button>
          <select id="month-year-select" value={selectedMonthYear} onChange={(e) => setSelectedMonthYear(e.target.value)} style={{ fontFamily: 'Equinor', fontSize: '16px', height: "35px", width: "160px", textAlign: "center" }}>

            <option value="">Select Month and Year</option>
            {years.map((year) => (
              months.map((month) => {
                const monthYear = `${year.value}-${month.value}`;
                if ((year.value < currentYear.toString() || (year.value === currentYear.toString() && parseInt(month.value) < currentMonth)) && !(year.value === currentYear.toString() && parseInt(month.value) === currentMonth)) {
                  return (
                    <option key={monthYear} value={monthYear}>
                      {month.label} {year.label}
                    </option>
                  );
                }
                return null;
              })
            ))}
          </select>
          <Button variant="text" onClick={handleNextClick} disabled={selectedMonthYear === `${currentYear}-${(currentMonth - 1).toString().padStart(2, '0')}`}><ArrowForwardIosIcon /></Button>

        </div>
      </div>
    </Grid>
  );
};

export default MonthDropdown;