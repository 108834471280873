import React, { useState, useEffect ,useContext} from 'react';

import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';

import { Button } from '@mui/material';

import { subscribe } from "../_axios";
import { snackbarContext } from "../../App"
import { API_RESPONSE_STATUS, APP_INSIGHT_TYPE } from "../Constant";
import {
  appInsightTrackEvent,
  appInsightTrackException,
} from "../Logging/Logging";
function SubscriptionButton(props) {
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
    useContext(snackbarContext);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const subscription = async (value) => {

    try {

      const response = await subscribe(props.accessToken, { assetId: props.assetId, isSubscribed: value });
      
      if(response.data.message == "Un-Subscribed successfully")
      {
      setIsSubscribed(false);
      appInsightTrackEvent(response.data.message);

      _setShouldOpen(true);
      _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
      _setSnackbarMessage(response.data.message);

      }
      if(response.data.message == "Subscribed successfully")
      {
      setIsSubscribed(true);
      appInsightTrackEvent(response.data.message);

      _setShouldOpen(true);
      _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
      _setSnackbarMessage(response.data.message);
     }

      setIsLoading(false);

    } catch (error) {

      console.error(error);

    }

  };

  useEffect(() => {
    

    // Simulate an API call to check subscription status

    const checkSubscription = async () => {
      try {
       if (typeof props.isSubscribed !== "undefined"){
        setIsSubscribed(props.isSubscribed)

        
       }

        setIsLoading(false);

      } catch (error) {

        console.error(error);

      }

    };

    checkSubscription();

  }, [props.isSubscribed ]);

  return (

    <div>

      {isLoading ? (

        <Button disabled>Loading...</Button>

      ) : (

        <div>

          {/* {isSubscribed ? (

            <Button onClick={() => subscription(false)}>Unsubscribe</Button>

          ) : (

            <Button onClick={() => subscription(true)}>Subscribe</Button>

          )} */}
          <label style={{ fontSize: '18px' }}>
  <input
    type="checkbox"
    checked={isSubscribed}
    onChange={() => subscription(!isSubscribed)}
  />
  {isSubscribed ? 'Unsubscribe' : 'Subscribe'}
  <div style={{marginLeft:"10px",fontSize:"14px"}}>Notify when automated Work Hours are ready for quality check</div>
</label>

        </div>

      )}

    </div>

  );

}

export default SubscriptionButton;