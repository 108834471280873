import React, { useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Grid,
  Button,
  ListItemButton,
  TextField,
  Typography,
  Tooltip,
  ListItemText,
} from "@mui/material";

import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { ruSelectionContext } from "./ParentCompany";
import { globalVariable } from "../../../App";
import { IDENTIFIER, TABLE_ACTION } from "../../Constant";
import SearchAndDropdown from "../../SearchAndDropdown/SearchAndDropdown";

const PAGE_SIZE = 50; // Number of rows to display per page

const GlobalCompany = (props) => {
  //usecontext
  const {
    _handleSynergiCompanyAssignment,
  } = useContext(ruSelectionContext);
  const [selectedIndex, setSelectedIndex] = useState();
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Current page number

  const handleTableRowSelection = (e, index) => {
    setSelectedIndex(index);
  };

  const handleCompanySelectionTemp = (event, child) => {
    //setSynergiCompanyId(event.target.value);
    _handleSynergiCompanyAssignment(
      child.label,
      selectedIndex,
      child.value
    );
  };
  const setEditStatus = (status, index) => {
    _setIsEditModeOn(status);
    setSelectedIndex(index);
  };
  const { _setIsEditModeOn, setUserWarningComapny } =
    useContext(globalVariable);

  const totalPages = Math.ceil(props.data?.length / PAGE_SIZE); // Calculate the total number of pages

  // Get the rows for the current page
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const rows = props.data
    ?.filter((row) =>
      row.dawinciCompanyName && row.dawinciCompanyName.toLowerCase().includes(searchText.toLowerCase())
    )
    .slice(startIndex, startIndex + PAGE_SIZE);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <React.Fragment>
      <TextField
        label="Search"
        variant="outlined"
        size="small"
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
        style={{ marginBottom: "16px", maxWidth: "360px" }}
      />
      <TableContainer>
        <Table
          sx={{ marginTop: "30px", border: 1 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={4}
                style={{
                  fontWeight: "bolder",
                  backgroundColor: "rgba(230, 250, 236)",
                  fontSize: "18px",
                }}
              >
                COMPANY MAPPING
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                width={"500px"}
                style={{
                  fontWeight: "bold",
                  backgroundColor: "rgba(230, 250, 236)",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    DaWinci Company
                  </Grid>
                  <Grid item xs={5}>
                    Synergi Company
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell
                align="center"
                width={"80px"}
                style={{
                  fontWeight: "bold",
                  backgroundColor: "rgba(230, 250, 236)",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
  {rows?.filter(row => row.action !== TABLE_ACTION.DELETE).length > 0 ? (
    rows?.map((row, index) =>
      row.action === TABLE_ACTION.DELETE ? null : (
        <TableRow
          key={row.guid}
          sx={{
            "&:last-child td, &:last-child th": { borderBottom: 0 },
          }}
        >
          <TableCell align="center">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ListItemText
                  primary={
                    <Typography style={{ textAlign: "left",marginLeft:"20px" }}>{row.dawinciCompanyName}</Typography>
                  }
                  sx={{
                    "& .MuiTypography-root": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                />
              </Grid>
              <Grid container item xs={6} alignSelf="center">
                {props.isEditModeOn && index + startIndex === selectedIndex ? (
                  <SearchAndDropdown
                    searchText={searchText}
                    textChange={(event) =>
                      setSearchText(event.target.value)
                    }
                    synergiCompanyList={props.synergiCompanyList}
                    handleSelection={handleCompanySelectionTemp}
                    value={row?.synergiCompanyId}
                  />
                ) : (
                  <div 
                    style={{
                      backgroundColor: row.synergiCompanyName ? '' : (row.requestStatus === 'Pending' ? '' : '#FFE7D6'),
                      width: '350px'
                    }}>
                    {row.requestStatus === "Pending" ? row.requestedSynergiCompanyName : (row.synergiCompanyName || "\u00A0")}
                    {row.requestStatus === "Pending" && <span style={{color: "#007079", marginLeft: "5px"}}>(Pending for Approval)</span>}
                  </div>
                )}
              </Grid>
            </Grid>
          </TableCell>
          <TableCell style={{ verticalAlign: "top" }}>
            <Grid container>
              {props.isEditModeOn && index + startIndex === selectedIndex ? (
                <Grid item xs={5} marginLeft={"10px"}>
                  <Tooltip title="Close" placement="top">
                    <Button variant="text">
                      <CancelIcon
                        onClick={() => (setUserWarningComapny(true), setEditStatus(false, index + startIndex))}
                      />
                    </Button>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <Tooltip title="Edit" placement="top">
                    <Button variant="text">
                      <EditTwoToneIcon
                        onClick={() => (setUserWarningComapny(true), setEditStatus(true, index + startIndex))}
                      />
                    </Button>
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={2}>
                <Tooltip title="Delete" placement="top">
                  <Button variant="text">
                    <DeleteOutlineTwoToneIcon
                      onClick={() => {
                        setEditStatus(true);
                        setUserWarningComapny(true);
                        props.handleTableRowDelete(index + startIndex, IDENTIFIER.COMPANY);
                      }}
                    />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </TableCell>
        </TableRow>
      )
    )
  ) : (
    <TableRow style={{ borderBottom: "1px solid #CCCCCC" }}>
      <TableCell colSpan={2} align="center" style={{ padding: "16px", fontFamily: "equinor",color:"red" }}>
        No records found.
      </TableCell>
    </TableRow>
  )}
</TableBody>
        </Table>

        {/* Pagination */}
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2} style={{ marginTop: "16px" }}>
          <Grid item>
            <Typography>
              Page {currentPage} of {totalPages}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={currentPage === 1}
              onClick={(event) => handlePageChange(event, currentPage - 1)}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={currentPage >= totalPages}
              onClick={(event) => handlePageChange(event, currentPage + 1)}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </TableContainer>
    </React.Fragment>
  );
};

export default GlobalCompany;