import React, { useState, useEffect, useContext } from "react";
// import { useState, useEffect } from 'react'
import { DataGrid,GridToolbar  } from '@mui/x-data-grid'
import { Grid, MenuItem, Select, Tooltip } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import AssetAdminPage from '../../AssetAdminPage';
import { DESCRIPTION } from '../../Constant';
import { utils, writeFileXLSX } from "xlsx";
import {
  Button
} from "@mui/material";
import moment from "moment";
import {daWinciSynergiPreviousMonthWorkHours} from "../../_axios";
import {
  appInsights,
  appInsightTrackEvent,
  appInsightTrackException,
} from "../../Logging/Logging.js";
import { API_RESPONSE_STATUS } from "../../Constant";
import { snackbarContext } from "../../../App";
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';

function DataGridInvolvedUnit({data,x,accessToken}) {
 
  const IUdata = data.map((item) => ({
    ...item,
    guid: Math.random().toString(16).slice(2), // add a unique "guid" property to each row
  })).filter((item) => item.synergiUnit.length > 0);
   
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
  useContext(snackbarContext);

  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
  });

  var sumpob = 0;
  var sumworkHour = 0;

  IUdata.forEach((each) => {
    sumpob += Math.trunc(each.pob);
    sumworkHour += Math.trunc(each.workHour);
  });

  const monthname = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  const today = new Date();
  const month_ = today.getMonth();
  const month = today.getMonth()+1;
  const year = today.getFullYear();
  const datee = today.getDate();
  const time = today.getHours();
  const minute = today.getMinutes();
  let prev_month = month-1;
  const selectedMonthYear = sessionStorage.getItem('selectedMonthYear');
  const monthdigit = selectedMonthYear.split('-')[1];
  const monthNumber = parseInt(monthdigit);
  const monthName = monthname[monthNumber-1]; 
  const yearvalue = selectedMonthYear.split('-')[0];
  if(prev_month === 0) {
    prev_month = 12;
  }

  let downloadyear = year;
  const daysinmonth = moment(year+"-"+prev_month, "YYYY-MM").daysInMonth();
  const pobaverage = Math.round(sumpob/daysinmonth);
  let prev_month_report = month-3;

  if(prev_month_report === -2) {
    prev_month_report = 10;
    downloadyear = year-1;
  }

  if(prev_month_report === -1) {
    prev_month_report = 11;
    downloadyear = year-1;
  }

  const prev_monthname = monthname[prev_month_report];
  
  const values = {
    title: "Excel Download",
    rows: IUdata.map(({ dawinciCompanyName,synergiCompanyName,departmentName,synergiUnit,pob, workHour}) => ({
      dawinciCompanyName,
      synergiCompanyName,
      departmentName,
      synergiUnit: synergiUnit[synergiUnit.length-1],
      pob,
      workHour: Math.trunc(workHour),
    }))
  };
  
  const columns = [
    { field: 'dawinciCompanyName', headerName: 'DaWinci Company', width: 150, flex: 0.8, align: 'center', headerAlign: 'center', headerClassName: 'super-app-theme--header' },
    { field: 'synergiCompanyName', headerName: 'Synergi Company', align: 'center', headerAlign: 'center', headerClassName: 'super-app-theme--header', flex: 1.2, width: 350, renderCell:(params) => {
      if (params.row?.synergiCompanyName === null) {
        return (<div style={{backgroundColor:"#FFE7D6",width:"100%",height:"100%",align: 'center',headerAlign: 'center'}}>
          {params.row?.synergiCompanyName}
        </div>);
      } else {
        return (<div>{params.row?.synergiCompanyName}</div>);
      }
    } },
    { field: 'departmentName', headerClassName: 'super-app-theme--header', headerName: 'DaWinci Department', width: 250, flex: 0.7, align: 'center', headerAlign: 'center' },
    {
      field: 'synergiUnit',
      headerName: 'Synergi Involved Unit',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      width: 450,
      flex: 2,
      valueGetter: (params) => params.row?.synergiUnit[params.row?.synergiUnit.length - 1] || '', // Use the last element of the array for sorting
      renderCell: (params) => {
        if (params.row?.synergiUnit.length === 0) {
          return (
            <div style={{ backgroundColor: '#FFE7D6', width: '100%', height: '80%', align: 'center', headerAlign: 'center' }}>
            </div>
          );
        } else {
          return (
            <>
              <div>{params.row?.synergiUnit[params.row?.synergiUnit.length - 1]}</div>
            </>
          );
        }
      }
    },
    { field: 'pob', headerName: `Days worked (${sumpob})`, headerClassName: 'super-app-theme--header', width: 150, flex: 0.7, align: 'center', headerAlign: 'center' },
    { field: 'workHour', headerName: `Work hours (${sumworkHour})`, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', width: 150, flex: 0.7, renderCell: (params) => {
      return (<div>{Math.trunc(params.row?.workHour)}</div>);
    } },
  ];

  function download() {
    const wb = utils.book_new();
    utils.book_append_sheet(wb, utils.json_to_sheet(values.rows));
    writeFileXLSX(wb, `Workhours>${x}<${monthName}>${yearvalue}>.xlsx`);
  }

  const previousmonth = async (value) => {
    try {
      const response = await daWinciSynergiPreviousMonthWorkHours(accessToken,x,prev_monthname,downloadyear);
      if (response.status === 200) {
        const newDataIU = response.data.data.filter(
          (item) => (item.synergiCaseTypeDescription === DESCRIPTION.INVOLVED_UNIT) 
          && item.synergiUnit.length > 0
        );
        const previousvalue = {
          title: "Excel Download",
          rows: newDataIU.map(({ dawinciCompanyName,synergiCompanyName,departmentName,synergiUnit,pob, workHour}) => ({
            dawinciCompanyName,
            synergiCompanyName,
            departmentName,
            synergiUnit: synergiUnit[synergiUnit.length-1],
            pob,
            workHour: Math.trunc(workHour),
          }))
        };
        
        const wb = utils.book_new();
        utils.book_append_sheet(wb, utils.json_to_sheet(previousvalue.rows));
        writeFileXLSX(wb, `Workhours>${x}<${month}>${year}>${datee}>${time}>${minute}>.xlsx`);
      } else if (response.status === 404) {
        appInsightTrackEvent("DaWinci POB count records not found for the given installation");
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage("DaWinci POB count records not found for the given installation");
        _setShouldOpen(true);
        setError({
          errorStatus: true,
          errorMessage: "DaWinci POB count records not found for the given installation",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Grid container item xs={12} style={{fontSize: "16px"}} >
        <Grid container item xs={6} justifyContent="left">
          <Button variant="text" style={{fontSize: "20px"}} onClick={download}>Download Report <GetAppOutlinedIcon style={{marginTop: "8px", color: "primary"}}/></Button>
        </Grid>
      </Grid>
      <Box sx={{ '& .super-app-theme--header': { backgroundColor: 'rgba(230, 250, 236)', border: '1px solid rgba(0, 0, 0, 0.05)' } }}>
        <DataGrid
          autoHeight
          showCellRightBorder={true}
          sx={{border: 1 }}
          getRowId={(params) => params.guid} // use the "guid" property as the id for each row
          rows={IUdata}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </React.Fragment>   
  );
}

export default DataGridInvolvedUnit;