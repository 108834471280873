import React, { useState, createContext, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Container from "@mui/material/Container";
//import MappingPage from "./MappingPage";
import AssetDetail from "../AssetDetail/AssetDetail";
//import Typography from "@mui/material/Typography";
//import ManHourDisplayTable from "../ManHourDisplayTable/ManHourDisplayTable";
import DaWinciScreenA from "../DaWinciMappingScreens/DaWinciScreenA";
import DaWinciScreenB from "../DaWinciMappingScreens/DaWinciScreenB";
import {
  levelOneRespUnitData,
  daWinciSynergiCompanyListWithSearch,
  locationDropdownData,
  ruDropdownData,
  iuDropdownData,
  updateAssetContractModificationAsync,
} from "../_axios";
import { NUMERAL, API_RESPONSE_STATUS } from "../Constant";
import { snackbarContext } from "../../App";
import { globalVariable } from "../../App";
import { StarProgress } from "@equinor/eds-core-react";
import { appInsights } from "../Logging/Logging";
import {
  appInsightTrackEvent,
  appInsightTrackException,
} from "../Logging/Logging";
import errorHandler from "../Utils/ErrorHandler";
import GenericError from "../GenericError/GenericError";
import AssetDetailB from "../AssetDetail/AssetDetailB";
import { DialogActions, DialogContent,Dialog,Button, Typography } from "@mui/material";
import AssetInfoRow from "../AssetInfoRow/AssetInfoRow";

export const locRuContext = createContext();

// const manHourDisplayData = [
//   {
//     id: 1,
//     company: "TCS",
//     contract: "ContractA_Xdf",
//     manHour: 233243,
//     headCount: 100,
//   },
//   {
//     id: 2,
//     company: "CTS",
//     contract: "ContractA_Xdf",
//     manHour: 25342,
//     headCount: 120,
//   },
//   {
//     id: 3,
//     company: "MINDTREE",
//     contract: "ContractB_Fdg",
//     manHour: 161223,
//     headCount: 130,
//   },
//   {
//     id: 4,
//     company: "HCL",
//     contract: "ContractB_Xdf",
//     manHour: 333243,
//     headCount: 110,
//   },
//   {
//     id: 5,
//     company: "WIPRO",
//     contract: "ContractC_Xdf",
//     manHour: 45342,
//     headCount: 230,
//   },
//   {
//     id: 6,
//     company: "L&T",
//     contract: "ContractC_Fdg",
//     manHour: 561223,
//     headCount: 10,
//   },
//   {
//     id: 7,
//     company: "IBM",
//     contract: "ContractD_Xdf",
//     manHour: 633243,
//     headCount: 300,
//   },
//   {
//     id: 8,
//     company: "GOOGLE",
//     contract: "ContractD_Xdf",
//     manHour: 75342,
//     headCount: 40,
//   },
//   {
//     id: 9,
//     company: "MICROSOFT",
//     contract: "ContractD_Fdg",
//     manHour: 61223,
//     headCount: 10,
//   },
// ];

const MappingTab = (props) => {
  //appinsight metrics
  appInsights.trackPageView();

  //get the state sent from navigate
  const location = useLocation();
  const assetId = useLocation().state.assetId;
  const assetCode = useLocation().state.assetCode;
  const assetName = useLocation().state.assetName;

  //state
  const [tabValue, setTabValue] = useState("2");
  const [metadata, setMetadata] = useState({
    location: [],
    locationId: "",
    responsibleUnit: [],
    ruId: "",
  });
  const [open, setOpen] = useState(false);
  const [locRuChangeFlag, setLocRuChangeFlag] = useState(false);
  const [levelOneRuData, setLevelOneRuData] = useState([]);
  const [synergiCompanyList, setSynergiCompanyList] = useState([]);
  const [synergiCompanyListAuto, setSynergiCompanyListAuto] = useState([]);
  const [childLevelsForLocation, setChildLevelsForLocation] = useState({});
  const [childLevelsForRu, setChildLevelsForRu] = useState({});
  const [childLevelsForIu, setChildLevelsForIu] = useState({});
  const [searchText, setSearchText] = useState("");

  const [saveButtonDisable, setSaveButtonDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
  });
  //for snackbar management
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =useContext(snackbarContext);
    const {setUserWarningComapny ,_setIsEditModeOn,setUserWarningRU} = useContext(globalVariable);
  //loading first level data of ru
  useEffect(() => {
    const loadLevelOneRuData = async () => {
      let responseRuData = await levelOneRespUnitData(props.accessToken);
      if (responseRuData.status === 200) {
        setLevelOneRuData(responseRuData.data.data);
      }
      else
      errorHandler(setError,responseRuData)
    };

    loadLevelOneRuData();
  }, [props.accessToken]);

  const handleTabChange = (event, value) => {
    const editstatus = props.isEditModeOn
    const userwarningcompany=props.userwarningcompany
    const userwarningru=props.userwarningru

    
    if(tabValue == "1" &&  userwarningcompany) return  setOpen(true)
    setTabValue(value);
  setUserWarningComapny(false)
   

  };

  //calling api to get list of all the synergi companies after passing a search parameter
  useEffect(() => {
    const daWinSynCompanyData = async () => {
      let response;

      try {
        response = await daWinciSynergiCompanyListWithSearch(
          props.accessToken,
        );
     
        if (
          response.status === 200 ||
          response.status === 204
        ) {
          if (response.status === 204) {
            appInsightTrackEvent(response.data.message);
          }
          setSynergiCompanyListAuto(response.data.data);
        } else {
          appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data);

          //handle error
          _setShouldOpen(true);
          _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
          _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data);
        }
      } catch (error) {
        appInsightTrackException(error);
      }
    };

    //execute the api only when user types in atleast 3 alphabets
   
      daWinSynCompanyData();
  }, [props.accessToken]);

  const textChange = (synergiCompanySearchText) => {
    setSearchText(synergiCompanySearchText);
  };

  //load child levels of locations and ru
  useEffect(() => {
    const loadChildLevels = async () => {
      const childLevelLocResp = await locationDropdownData(
        props.accessToken,
        assetId
      );
      if(childLevelLocResp?.data){
        setChildLevelsForLocation(childLevelLocResp.data.data);
      }
      const childLevelRuResp = await ruDropdownData(props.accessToken, assetId);
      const childLevelIuResp = await iuDropdownData(props.accessToken, assetId);
if(childLevelRuResp?.data){
  
  setChildLevelsForRu(childLevelRuResp.data.data);
 setIsLoading(false);
}
if(childLevelIuResp?.data){
  
  setChildLevelsForIu(childLevelIuResp.data.data);
 setIsLoading(false);
}
else{ 
 // console.log(childLevelRuResp.message)
  setIsLoading(false);
  setError({
  errorStatus: true,
  errorMessage: childLevelRuResp.data,
  });
}
      
    };

    loadChildLevels();
  }, [props.accessToken, assetId]);

  //disable save once save is successful
  const disableSave = () => {
    setSaveButtonDisable(true);
    setLocRuChangeFlag(false);
  };

  const handleContractSave = async () => {
    let _contractData = [];

    let dataToSend = {
      assetId: location.state.assetId,
      locationId: metadata.locationId,
      responsibleUnitId: metadata.ruId,
      inputData: [{ contractData: _contractData }],
    };

    try {
      let response = await updateAssetContractModificationAsync(
        props.accessToken,
        dataToSend
      );
      //snackbar notification
      if (response.status === 200) {
        _setShouldOpen(true);
        _setApiResponseStatus(API_RESPONSE_STATUS.SUCCESS);
        _setSnackbarMessage("Location/Ru saved");
      
      } else {
        appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data);
        
        //error cases
        _setShouldOpen(true);
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data);
      }
    } catch (error) {
      appInsightTrackException(error);
    }

    //make all actions to no action when save is successful
    //this approach would change when api returns ids back, so that id can be assigned and then based on id, action can be decided
    disableSave();
    //resetAction();

    //this flag is toggled to recall the api again
    //setReloadPageFlag(!reloadPageFlag);
  };

  const handleConfirm = () => {
    setOpen(false);
    setTabValue("2");
  };

  const handleCancel = () => {
    setOpen(false);

  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  return (
    <locRuContext.Provider
      value={{
        _locationAndRu: metadata,
        _setLocationAndRu: setMetadata,
        _setLocRuChangeFlag: setLocRuChangeFlag,
      }}
    >
      <React.Fragment>
      <BootstrapDialog onClose={handleCancel} open={open}>
        <DialogContent>
          <Typography gutterBottom>
            Please ensure to save your data before leaving
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirm}>
            Continue
          </Button>
          <Button autoFocus onClick={handleCancel} variant={"outlined"}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
<AssetInfoRow
asset={metadata.location}
accessToken={props.accessToken}
assetId={assetId}
childLevelsForLocation={childLevelsForLocation}
childLevelsForRu={childLevelsForRu}
metadata={metadata}
setMetadata={setMetadata}
saveButtonDisable={saveButtonDisable}
locRuChangeFlag={locRuChangeFlag}
handleContractSave={handleContractSave}
/>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabValue}>
            {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}> */}
            <Box
  sx={{
    flexGrow: 1,
    marginLeft: "-10px",
    marginRight: "-10px",
    marginTop: "-10px",
  }}
>
              <TabList onChange={handleTabChange} centered style={{ backgroundColor: '#EAF4F9' }}className="custom-tablist">
                {/* <Tab label="Asset Configuration" value="1" />
                <Tab label="Current Month Workhours" value="2" /> */}
               
               <Tab
    label="Work Hours Registration"
    value="2"
    sx={{
      color: '#000',
      '&.Mui-selected': {
        bgcolor: 'white',
      },
    }}
  />
  <Tab
    label="DaWinci-Synergi Mapping"
    value="1"
    sx={{
      color: '#000',
      '&.Mui-selected': {
        bgcolor: 'white',
      },
    }}
  />
                {/* <Tab label=" " value="2" />
                <Tab label="DaWinci-Synergi Mapping" value="1" /> */}
              </TabList>
            </Box>
            {/* <TabPanel value="1">
                  <MappingPage
                    accessToken={props.accessToken}
                    locationId={metadata.locationId}
                    ruId={metadata.ruId}
                    locRuChangeFlag={locRuChangeFlag}
                    setLocRuChangeFlag={setLocRuChangeFlag}
                  />           
            </TabPanel>
            <TabPanel value="2">
              <Typography color={"red"}>
                * Man hours includes overtime for the month of{" "}
                {new Date().toLocaleString("default", { month: "long" })}
              </Typography>
              <ManHourDisplayTable rows={manHourDisplayData} />
            </TabPanel> */}
            <TabPanel value="1">
              
              {isLoading ? (
                <StarProgress style={{ marginLeft: "50%", marginTop: "10%" }} />
              ) : error.errorStatus ? (
                <Container maxWidth="lg">
            <Grid container spacing={1}>
             <GenericError errorMessage="Something went wrong,Please Try Again" />
            </Grid>
          </Container>
        ) : (
                <Grid container item>
                  {/* <Grid item xs={12}>
                    <AssetDetail
                      accessToken={props.accessToken}
                      assetId={assetId}
                      childLevelsForLocation={childLevelsForLocation}
                      childLevelsForRu={childLevelsForRu}
                      metadata={metadata}
                      setMetadata={setMetadata}
                      saveButtonDisable={saveButtonDisable}
                      locRuChangeFlag={locRuChangeFlag}
                      handleContractSave={handleContractSave}
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <DaWinciScreenA
                      accessToken={props.accessToken}
                      synergiCompanyList={synergiCompanyListAuto}

                      searchText={searchText}
                      textChange={textChange}
                      assetCode={assetCode}
                      childLevelsForRu={childLevelsForRu}
                      childLevelsForIu={childLevelsForIu}
                      assetId={assetId}
                      setIsEditModeOn={_setIsEditModeOn}
                     isEditModeOn={props.isEditModeOn}
                     userwarningcompany={props.userwarningcompany}
                     isEditModeOn_ru={props.isEditModeOn_ru}
                     isEditModeOn_iu={props.isEditModeOn_iu}
                    />
                  </Grid>
                </Grid>
              )}
            </TabPanel>
            <TabPanel value="2">
               <AssetDetailB
                      accessToken={props.accessToken}
                      assetId={assetId}
                      childLevelsForLocation={childLevelsForLocation}
                      childLevelsForRu={childLevelsForRu}
                      metadata={metadata}
                      setMetadata={setMetadata}
                      saveButtonDisable={saveButtonDisable}
                      locRuChangeFlag={locRuChangeFlag}
                      handleContractSave={handleContractSave}
                    />
              <DaWinciScreenB
                accessToken={props.accessToken}
                assetCode={useLocation().state.assetCode}
                location={metadata.location}
                levelOneRuData={levelOneRuData}
                synergiCompanyList={synergiCompanyList}
                searchText={searchText}
                textChange={textChange}
                childLevelsForRu={childLevelsForRu}
                assetId={useLocation().state.assetId}
                locationId={metadata.locationId}
                ruId={metadata.ruId}
                assetName={assetName}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </React.Fragment>
    </locRuContext.Provider>
  );
};

export default MappingTab;
