import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useState, useEffect } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Locationdropdown({ value, onChange, options, row, userLocation }) {
  const [selected, setSelected] = useState(value);
  const [assetIds, setAssetIds] = useState({});

  useEffect(() => {
    const newAssetIds = {};
    row.location.forEach((loc) => {
      newAssetIds[loc.assetName] = loc.assetId;
    });
    setAssetIds(newAssetIds);
  }, [row.location]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(value);
    const newLocation = value.map((name) => {
      const locationObj = row.location.find((loc) => loc.assetName === name.trim());
      const assetId = assetIds[name.trim()] || userLocation.find((loc) => loc.name === name.trim())?.id || "";
      return {
        assetName: name.trim(),
        UserId: "",
        assetId,
        assetAdminId: locationObj ? locationObj.assetAdminId : 0,
      };
    });
    const newRow = { ...row, location: newLocation };
    onChange(event, newRow);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 570 }}>
        <InputLabel id="demo-multiple-checkbox-label">Asset</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput label="Asset" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              <Checkbox checked={selected.indexOf(option.name) > -1} />
              <ListItemText primary={option.name} />
              <input type="hidden" name={`assetId_${option.name}`} value={assetIds[option.name]} />
              {row.location.find((loc) => loc.assetName === option.name.trim()) && (
                <input type="hidden" name={`assetAdminId_${option.name}`} value={row.location.find((loc) => loc.assetName === option.name.trim()).assetAdminId} />
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};