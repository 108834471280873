import axios from "axios";
//change
//basic instance setting
const _axios = axios.create({
  //baseURL: process.env.REACT_APP_BASEURL,
  baseURL: process.env.REACT_APP_BASEURL + "api/time-record/",
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

export const apiUrlList = {
  assetContractCompanyVendorDetails: "asset",//not being used
  manhourDataForAsset: "WorkHoursForUser",//cant test not used
  contractDetailsForUser: "ContractDetailsForUser",//nothere
  assetMappedToUser: "asset-user-mapping",//checked
  levelOneLocationData: "level1-locations",
  deepLevelLocationData: "sub-location-levels",
  levelOneResponsibleUnitData: "level1-responsible-units",
  deepLevelRuData: "sub-responsible-units",
  assetConfiguration: "asset-configuration",
  daWinciSynergiMapping: "dawinci-synergy-mapping",
  daWinciSynergiCompanyMapping:"company-mapping",
  assetCompanyMapping:"asset-company-mapping",
  daWinciSynergiCurrentMonthWH: "case-detail",
  daWinciSynergiPreviousMonthWorkHours: "case-detail-history",
  daWinciSynergiCompanyWithSearch: "synergi-company",
  childLevelsLocationData: "child-locations",
  childLevelsRuData: "child-responsible-units",
  childLevelsIuData: "child-involved-units",
  submitCaseToSynergi: "case",
  autoPopulateResponsibleUnit:"responsible-units",
  cluster:"cluster" ,//checked
  isSubcribed:"subscribe-qc-notification",
  allocatedassets:"allocated-assets" ,//assettagged
  assetsbycluster:"cluster-assets",//assetintagging
  audit:"audit" ,//auditlogging,
  assetallocation:"asset-allocation",
  activeAsset:"asset",
  requestStatus:"request-status",
  requestType:"request-type",
  request:"request",
  approveRequest:"approve-request"


};

//get all the contract details, companies and vendors mapped with contract.
export async function getAssetContractCompanyVendorDetails(
  accessToken,
  assetId
) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  const response = await _axios.get(
    apiUrlList.assetContractCompanyVendorDetails,
    {
      params: { ID: assetId },
    }
  );

  return response.data;
}
//Cluster
export async function getcluster(accessToken) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  try {
    const res = await _axios.get(apiUrlList.cluster);

    let data = res.data;
    return data;
  } catch (error) {
    //console.log(error.response);

    return error.response;
  }

}

//get asset mapped to a user
export async function getAssetsMappedToUserAsync(accessToken,clustervalue) {
  //console.log(clustervalue)
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  try {
    const res = await _axios.get(apiUrlList.assetMappedToUser+"?clusterId"+clustervalue,{
      params: { clusterName: clustervalue },
    });

    let data = res.data;
    return data;
  } catch (error) {
    //console.log(error.response); // this is the main part. Use the response property from the error object

    return error.response;
  }

}

//get man hours for an asset; associated with vendor functionality
export async function getManHourDataForAsset(
  accessToken,
  assetId,
  _startDate,
  _endDate
) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  const response = await _axios.get(apiUrlList.manhourDataForAsset, {
    params: { assetId: assetId, Start_Date: _startDate, End_Date: _endDate },
  });

  return response.data;
}

//post, put and delete for man hour details associated with an asset; vendor functionality
export async function updateManHourDataForAsset(
  accessToken,
  methodIdentifier,
  body,
  id
) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    if (methodIdentifier === "post") {
      response = await _axios.post(apiUrlList.manhourDataForAsset, body);
    } else if (methodIdentifier === "put") {
      response = await _axios.put(
        apiUrlList.manhourDataForAsset + `/${id}`,
        body
      );
    } else if (methodIdentifier === "delete") {
      response = await _axios.delete(apiUrlList.manhourDataForAsset + `/${id}`);
    } else {
      //console.log("unknown http request method");
    }
  } catch (error) {
    //console.log(error);

    return error;
  }

  return response.data;
}

//
export async function contractDetailsForUser(accessToken, assetId) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.contractDetailsForUser, {
      params: { assetId: assetId },
    });
  } catch (error) {
    //console.log(error.response);

    return error.response;
  }

  return response.data;
}

//dropdown api call for location
export async function levelOneLocationData(accessToken) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.levelOneLocationData);
  } catch (error) {
    //console.log(error.message);

    return error.response;
  }

  return response;
}

//dropdown api call for child level in location
export async function locationDropdownData(accessToken, _assetId) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.childLevelsLocationData, {
      params: { assetId: _assetId },
    });
  } catch (error) {
    //console.log(error.message);

    return error.response;
  }

  return response;
}

//dropdown api call for child level in ru
export async function ruDropdownData(accessToken, _assetId) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.childLevelsRuData, {
      params: { assetId: _assetId },
    });
  } catch (error) {
//console.log(error)
    return error.response;
  }

  return response;
}

//dropdown api call for child level in ru
export async function iuDropdownData(accessToken, _assetId) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.childLevelsIuData, {
      params: { assetId: _assetId },
    });
  } catch (error) {
//console.log(error)
    return error.response;
  }

  return response;
}

//dropdown api call for location
export async function deepLevelLocationData(accessToken, _location) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.deepLevelLocationData, {
      params: { location1: _location },
    });
  } catch (error) {
    //console.log(error.message);

    return error.response;
  }

  return response;
}

//dropdown api call for ru
export async function levelOneRespUnitData(accessToken) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.levelOneResponsibleUnitData);
  } catch (error) {
    //console.log(error.message);

    return error.response;
  }

  return response;
}

//dropdown api call for ru
export async function deepLevelResponsibleUnitData(accessToken, _unit) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.deepLevelRuData, {
      params: { unit1: _unit },
    });
  } catch (error) {
    //console.log(error.message);

    return error.response;
  }

  return response;
}

//api returning metadata related to an asset like location and ru details
export async function assetDetailsAsync(accessToken, _assetId) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.assetConfiguration, {
      params: { assetId: _assetId },
    });
  } catch (error) {
    //console.log(error.message);

    return error.response;
  }

  return response;
}

export async function updateAssetContractModificationAsync(accessToken, _body) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.put(apiUrlList.assetConfiguration, _body);
  } catch (error) {
    //console.log(error.message);

    return error;
  }

  return response;
}

export async function daWinciSynergiReferenceData(accessToken, assetCode) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.daWinciSynergiMapping, {
      params: { dawinciInstallationCode: assetCode

      },
    });
  } catch (error) {
    //console.log(error.response);

    return error.response;
  }

  return response;
}

export async function daWinciSynergiCompanyReferenceData(accessToken, assetCode) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.daWinciSynergiCompanyMapping, {
      params: { daWinciLocCode: assetCode },
    });
  } catch (error) {
    //console.log(error.response);

    return error.response;
  }

  return response;
}

export async function assetCompanyMapping(accessToken, assetId,month,year) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.assetCompanyMapping, {
      params: { 
                assetId: assetId,
                month:month,
                year:year

       },
    });
  } catch (error) {
    //console.log(error.response);

    return error.response;
  }

  return response;
}
//Saving company data
export async function daWinciSynergiReferenceSave(accessToken, _body) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.post(apiUrlList.daWinciSynergiCompanyMapping, _body);
  } catch (error) {
   // console.log(error.message);

    return error;
  }

  return response;
}

//Saving company data
export async function daWinciSynergiReferenceSaveAssetWise(accessToken, _body) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.post(apiUrlList.assetCompanyMapping, _body);
  } catch (error) {
   // console.log(error.message);

    return error;
  }

  return response;
}
//Saving department data
export async function daWinciSynergiDepartmentSave(accessToken, _body) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.put(apiUrlList.daWinciSynergiMapping, _body);
  } catch (error) {
   // console.log(error.message);

    return error;
  }

  return response;
}

export async function daWinciSynergiCurrentMonthWorkHours(
  accessToken,
  assetcode,tabResponse
) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.daWinciSynergiCurrentMonthWH, {
      params: { dawinciInstallationCode: assetcode},
    });
  } catch (error) {
    
//console.log(error.response)
    return error.response;
  }

  return response;
}
export async function daWinciSynergiPreviousMonthWorkHours(
  accessToken,
  assetCode,
  prev_monthname,
  year
) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.daWinciSynergiPreviousMonthWorkHours, {
      params: { dawinciInstallationCode: assetCode,previousMonth:prev_monthname,year},
    });
  } catch (error) {
    
//console.log(error.response)
    return error.response;
  }

  return response;
}
//new api auto populate for Responsible Unit
// export async function autoPopulateRU(
//  locationCode,
//  accessToken
// ) {
//   _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

//   let response;

//   try {
//     response = await _axios.get(apiUrlList.autoPopulateResponsibleUnit+"?DawinciLocCode="+locationCode, {
//       params: { DawinciLocCode: "JSF" },
//     });
//   } catch (error) {
//     //console.log(error.message);

//     return error;
//   }

//   return response;
// }


export async function daWinciSynergiCompanyListWithSearch(
  accessToken,
  searchText
) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.get(apiUrlList.daWinciSynergiCompanyWithSearch, {
      params: { company: searchText },
    });
  } catch (error) {
    //console.log(error.message);

    return error;
  }

  return response;
}

export async function submitCaseToSynergi(
  accessToken,
  caseBody
  // _installation,
  // _assetName,
  // _locationId,
  // _responsibleUnitId,
  // _caseType,
) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.post(apiUrlList.submitCaseToSynergi,caseBody) 
    // {
    // params: {
    //   installation: _installation,
    //   assetName: _assetName,
    //   location: _locationId,
    //   resUnit: _responsibleUnitId,
    //   caseType: _caseType,
      
    // },
  // });
  } catch (error) {
    console.log(error.response);

    return error.response;
  }

  return response;
}

export async function subscribe(accessToken, body) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.post(apiUrlList.isSubcribed ,body);
  } catch (error) {
    //console.log(error.response);

    return error.response;
  }

  return response;
}

export async function allocatedassets(accessToken,pageNumber,recordsPerPage) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  try {
    const res = await _axios.get(apiUrlList.allocatedassets+"?pageNumber"+pageNumber,{
      params: { pageNumber: pageNumber ,
        recordsPerPage:recordsPerPage},

    });

    let data = res.data;
    return data;
  } catch (error) {
    //console.log(error.response); // this is the main part. Use the response property from the error object

    return error.response;
  }

}

export async function audit(accessToken,pageNumber,recordsPerPage,searchQuery) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  try {
    const res = await _axios.get(apiUrlList.audit+"?pageNumber"+pageNumber,
    {
      params: { pageNumber: pageNumber ,
        recordsPerPage:recordsPerPage,
        searchQuery:searchQuery},

    });

    let data = res.data;
    return data;
  } catch (error) {
    //console.log(error.response); // this is the main part. Use the response property from the error object

    return error.response;
  }

}

export async function assetsbycluster(accessToken,clusterId) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  try {
    const res = await _axios.get(apiUrlList.assetsbycluster+"?clusterId"+clusterId,{
      params: { clusterId: clusterId ,},

    });

    let data = res.data;
    return data;
  } catch (error) {
    //console.log(error.response); // this is the main part. Use the response property from the error object

    return error.response;
  }

}

export async function assetallocation(
  accessToken,
  caseBody
) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.post(apiUrlList.assetallocation,caseBody) 
  
  } catch (error) {
    console.log(error.response);

    return error.response;
  }

  return response;
}

export async function activeAsset(accessToken) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  try {
    const res = await _axios.get(apiUrlList.activeAsset);

    let data = res.data;
    return data;
  } catch (error) {
    //console.log(error.response);

    return error.response;
  }

}

export async function assetPost(
  accessToken,
  caseBody
) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  let response;

  try {
    response = await _axios.post(apiUrlList.activeAsset,caseBody) 
  
  } catch (error) {
    console.log(error.response);

    return error.response;
  }

  return response;
}

export async function requestStatus(accessToken) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  try {
    const res = await _axios.get(apiUrlList.requestStatus);

    let data = res.data;
    return data;
  } catch (error) {
    //console.log(error.response);

    return error.response;
  }

}

export async function requestType(accessToken) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  try {
    const res = await _axios.get(apiUrlList.requestType);
   
    let data = res.data;
    return data;
  } catch (error) {
    //console.log(error.response);

    return error.response;
  }

}
//audit?pageNumber1&pageNumber=1&recordsPerPage=25
export async function request(accessToken,requestStatusId) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

   try {
    const res = await _axios.get(apiUrlList.request+"?requestStatusId"+requestStatusId,
    {
      params: { requestStatusId: requestStatusId ,
       },

    });

    let data = res.data;
    return data;
  }
  catch (error) {
    //console.log(error.response); // this is the main part. Use the response property from the error object

    return error.response;
  }

}

export async function approveRequestCompany(accessToken,body) {
  _axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  try {
    const res = await _axios.post(apiUrlList.approveRequest,body);

    let data = res.data;
    return data;
  } catch (error) {
    //console.log(error.response);

    return error.response;
  }

}

export default _axios;






