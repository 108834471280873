import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@material-ui/core/styles';
import { Grid, Button, TextField, Container, Typography, Dialog, DialogActions, DialogContent, } from '@mui/material';
import { green } from '@mui/material/colors';
import { StarProgress } from '@equinor/eds-core-react';
import { API_RESPONSE_STATUS } from '../../Constant';
import { approveRequestCompany, request } from '../../_axios';
import { appInsightTrackEvent } from '../../Logging/Logging';
import GenericError from '../../GenericError/GenericError';
import { snackbarContext } from '../../../App';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@material-ui/core';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

const CompanyRequest = (props) => {
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
    useContext(snackbarContext);

  const columns = [
    { field: 'dawinciCompanyName', headerName: 'Dawinci Co', flex: 1,headerClassName: 'super-app-theme--header'} ,
    { field: 'companyName', headerName: 'Company Name', flex: 2.5, headerClassName: 'super-app-theme--header'},
    { field: 'updatedCompanyName', headerName: 'Updated Company Name', flex: 2.5 , headerClassName: 'super-app-theme--header'},
    { field: 'requestedBy', headerName: 'Requested By', flex: 2 ,headerClassName: 'super-app-theme--header'},
    {
      field: 'requestedDate',
      headerName: 'Requested Date',
      flex: 2,
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) => {
        const date = new Date(params.row.requestedDate);
        const formattedDate = date.toISOString().slice(0,16); // get date and time in format 'yyyy-MM-ddTHH:mm'
        return formattedDate;
      }
    },
    {
      field: 'comments',
      headerName: 'Remark/Comments',
      flex: 3,
       headerClassName: 'super-app-theme--header',
      valueGetter: (params) => params.row.requestStatusId === 1 ? params.row.remark : params.row.comments,
      renderCell: (params) => {
        return params.row.requestStatusId === 1 ? (
        
          <TextField
          multiline
          value={params.row.remark}
          onChange={(e) => handleRemarkChange(params.row.requestId, e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 32 && e.target.selectionStart === e.target.selectionEnd) {
              // Check if the pressed key is spacebar and there is no text selected in the input
              e.preventDefault(); // Prevent the default behavior (insert space)
              e.stopPropagation(); // Prevent the event from propagating to the DataGrid
              const start = e.target.selectionStart;
              const end = e.target.selectionEnd;
              const value = e.target.value;
              const newValue = value.substring(0, start) + ' ' + value.substring(end);
              e.target.value = newValue;
              e.target.selectionStart = e.target.selectionEnd = start + 1;
              // Update the value and move the caret position after the inserted space
            }
          }}
        />
        ) : (
          <div style={{ height: '100%', width: '100%', overflowY: 'auto', whiteSpace: 'pre-wrap' }}>
  {params.row.comments}
</div>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
       headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return params.row.requestStatusId === 1 ? (
          <div>
            <Button variant="contained" style={{ marginLeft:"-10px",width: "15px", height: "25px", fontSize: "11px",  marginTop: "8px", backgroundColor: "#007079", color: "white", textTransform: "none" }} onClick={() => handleApprove(params.row)}>Approve</Button>
            <Button variant="contained" style={{marginLeft:"-7px",width: "15px", height: "25px", fontSize: "11px", marginTop: "8px", textTransform: "none" }} onClick={() => handleReject(params.row)}>Reject</Button>
</div>
        ) : (
          params.row.requestStatusId === 2 ? (
            <div>Approved</div>
          ) : (
            <div>Rejected</div>
          )
        )
      }
    }
  ];

  const [rows, setRows] = useState([]);
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [comment, setComment] = useState(null);
  const [approveOpen, setApproveOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [body, setBody] = useState(null);

  const handleApprove = (row) => {
    setApproveOpen(true);

    const updatedItem = {
      ...row,
      requestStatusId: 2,
      comments: comment
    };
    delete updatedItem.dawinciCompanyName;

    setBody(updatedItem);
  };

  const handleReject = (row) => {
    setRejectOpen(true);

    const updatedItem = {
      ...row,
      requestStatusId: 3,
      comments: comment
    };
    delete updatedItem.dawinciCompanyName;

    setBody(updatedItem);
  };

  const handleApproveCancel = () => {
    setApproveOpen(false);
  };

  const handleApproveConfirm = async () => {
    setApproveOpen(false);

    try {
      const response = await approveRequestCompany(props.accessToken, body);
      if (response.status === 200) {
        appInsightTrackEvent(response.data);
        _setShouldOpen(true);
        _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
        _setSnackbarMessage(response.data);
      } else {
        appInsightTrackEvent(response.statusText);
        _setShouldOpen(true);
        _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
        _setSnackbarMessage(response.statusText);
      }

      setIsLoading(false);

    } catch (error) {
      console.error(error);
    }
  };

  const handleRejectCancel = () => {
    setRejectOpen(false);
  };

  const handleRejectConfirm = async () => {
    setRejectOpen(false);
    setApproveOpen(false);

    try {
      const response = await approveRequestCompany(props.accessToken, body);
      if (response.status === 200) {
        appInsightTrackEvent(response.data);
        _setShouldOpen(true);
        _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
        _setSnackbarMessage(response.data);
      } else {
        appInsightTrackEvent(response.statusText);
        _setShouldOpen(true);
        _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
        _setSnackbarMessage(response.statusText);
      }
      setIsLoading(false);
     
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemarkChange = (id, value) => {
    setComment(value);
  }

  const handleSearchChange = (value) => {
    const filtered = rows.filter(row => {
      return row.companyName.toLowerCase().includes(value.toLowerCase()) ||
             row.updatedCompanyName.toLowerCase().includes(value.toLowerCase()) ||
             row.requestedBy.toLowerCase().includes(value.toLowerCase()) ||
             row.requestedDate.toLowerCase().includes(value.toLowerCase())
    });
    setRows(filtered);
  }

  const fetchRows = async (props) => {
    try {
      const response = await request(props.accessToken, null );
      if (response.status === 200) {
        setRows(response.data);
        setIsLoading(false);
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      setError({
        errorStatus: true,
        errorMessage: error.message || "No Record Found,Please try again"
      });
      setIsLoading(false);
    }
  };
  const getRowId = (row) => row.requestId;
  useEffect(() => {
    fetchRows(props);
  }, [props]);

  const approveDialogBoxJsxIU = () => {
    return (
      <BootstrapDialog onClose={handleApproveCancel} open={approveOpen}>
        <DialogContent>
          <Typography gutterBottom>
            Would you like to go ahead with the approval ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" style={{ backgroundColor: "#007079" ,color:"white"}} onClick={handleApproveConfirm}>
            Continue
          </Button>
          <Button autoFocus onClick={handleApproveCancel} variant={"outlined"}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  const rejectDialogBoxJsxIU = () => {
    return (
      <BootstrapDialog onClose={handleRejectCancel} open={rejectOpen}>
        <DialogContent>
          <Typography gutterBottom>
            Would you like to go ahead with the rejection ?
          </Typography>
        </DialogContent>
        <DialogActions>
        <Button autoFocus variant="contained" style={{ backgroundColor: "#007079" ,color:"white"}} onClick={handleRejectConfirm}>
            Continue
        </Button>
          <Button autoFocus onClick={handleRejectCancel} variant={"outlined"}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };
    
  return (
    <>
      {isLoading ? (
        <StarProgress style={{ marginLeft: "50%", marginTop: "5%" }} />
      ) : error.errorStatus ? (
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <GenericError errorMessage={error.errorMessage} />
          </Grid>
        </Container>
      ) : (
        
        <Grid item xs={12} style={{marginLeft:"40px",marginTop:"20px"}}>
          <Box sx={{ '& .super-app-theme--header': { backgroundColor: 'rgba(230, 250, 236)', border: '1px solid rgba(0, 0, 0, 0.05)' } }}>
            <DataGrid 
              autoHeight
              rows={rows} 
              columns={columns}
              pageSize={15}
              disableSelectionOnClick
              getRowId={getRowId} // specify the getRowId prop
              onSelectionModelChange={(newSelection) => setSelectedRowIds(newSelection.selectionModel)}
              selectionModel={selectedRowIds}
              headerClassName={'super-app-theme--header'} // add headerClassName prop
            />
          </Box>
        </Grid>
     
      )}
      {approveDialogBoxJsxIU()}
      {rejectDialogBoxJsxIU()}
    </>
  );
}

export default CompanyRequest;