import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import PopoverContent from "./PopoverContent";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import LocationCityTwoToneIcon from "@mui/icons-material/LocationCityTwoTone";

function DropDown(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleInputs = (selectedValue, identifier, selectedValueId) => {
    setAnchorEl(null);
    setOpen(false);
    props.assignMetadata(selectedValue, identifier, selectedValueId);
  };

  return (
    <React.Fragment>
      <Grid container justifyContent={"center"}>
        <Grid item>
          {props.showIcon ? (
            props.identifier === "location" ? (
              <div  variant="text" style={{marginTop:"12px"}}>
                <Tooltip title={props.buttonName} placement={"top"}>
                  <LocationOnTwoToneIcon sx={{ color: "rgb(0, 112, 121)" }}/>
                </Tooltip>
                </div>
            ) : (
              <Button onClick={handleClick} variant="text">
                <Tooltip title={props.buttonName} placement={"top"}>
                  <LocationCityTwoToneIcon />
                </Tooltip>
              </Button>
            )
          ) : null}
        </Grid>
      </Grid>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <PopoverContent
          dropDownSelection={handleInputs}
          processCancellation={handleClose}
          levelOneList={props.levelOneData}
          ChildLevelData={props.ChildLevelData}
          accessToken={props.accessToken}
          assetId={props.assetId}
          identifier={props.identifier}
          showParentDropdownData={false}
        />
      </Popover>
    </React.Fragment>
  );
}

DropDown.defaultProps = {
  showIcon: true,
};

export default DropDown;
