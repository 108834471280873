import React, { useState, useEffect, useContext } from "react";
import {
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Grid,
 Button,
 TextField,
 Tooltip,
 Typography,
 DialogContent,
 DialogActions,
 Dialog,
 ListItemButton,
} from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { styled } from "@mui/material/styles";
import { levelOneRespUnitData } from "../../../_axios";
import { ruSelectionContext } from "../../DaWinciScreenA";
import { IDENTIFIER, TABLE_ACTION } from "../../../Constant";
import { appInsightTrackEvent, appInsightTrackException } from "../../../Logging/Logging";
import { globalVariable } from "../../../../App";
import DropDown from "../../../DropDown/DropDown";
import { arrayToChip } from "../../../CommonFunction";
import CancelIcon from '@mui/icons-material/Cancel';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
 "& .MuiDialogContent-root": {
   padding: theme.spacing(2),
 },
 "& .MuiDialogActions-root": {
   padding: theme.spacing(1),
 },
}));
const DaWinciSynergiDeptReferenceTable = (props) => {
 const { _handleRuAssignmentToDept, _handleDawinciTextChangeDept } = useContext(ruSelectionContext);
 const { setUserWarningComapny, _setIsEditModeOn_ru } = useContext(globalVariable);
 const [selectedIndex, setSelectedIndex] = useState(null);
 const [levelOneRuData, setLevelOneRuData] = useState([]);
 const [open, setOpen] = useState(false);
 const [deleteIndex, setDeleteIndex] = useState(null);
 useEffect(() => {
   const loadLevelOneRuData = async () => {
     try {
       const responseRuData = await levelOneRespUnitData(props.accessToken);
       if (responseRuData.status === 200) {
         setLevelOneRuData(responseRuData.data.data);
       } else {
         appInsightTrackEvent("failed to load level one responsible unit data");
       }
     } catch (error) {
       appInsightTrackException(error);
     }
   };
   loadLevelOneRuData();
 }, [props.accessToken]);
 const handleTableRowSelection = (e, index) => {
  setSelectedIndex(index);
};
 const handleRuSelection = (selection, identifier, selectionId) => {
   _handleRuAssignmentToDept(selection, selectedIndex, selectionId, identifier);
 };
 const setEditStatus = (status, index) => {
   _setIsEditModeOn_ru(status);
   setSelectedIndex(index);
 };
 const handleDeleteClick = (index) => {
   setDeleteIndex(index);
   setOpen(true);
 };
 const handleConfirmDelete = () => {
   setUserWarningComapny(true);
   if(deleteIndex !== null){
   
   props.handleTableRowDeleteDept(deleteIndex, IDENTIFIER.DEPARTMENTRU);
   setOpen(false);
 }
else {console.log("null")}};
 const handleCancel = () => {
   setOpen(false);
   setDeleteIndex(null);
 };
 return (
<>
<TableContainer>
<Table sx={{ marginTop: "30px", border: 1 }} aria-label="simple table">
<TableHead>
<TableRow>
<TableCell
               align="center"
               colSpan={4}
               style={{ fontWeight: "bolder", backgroundColor: "rgba(230, 250, 236)" }}
>
               DEPARTMENT - RESPONSIBLE UNIT MAPPING
</TableCell>
</TableRow>
<TableRow>
<TableCell align="left" style={{ fontWeight: "bold", backgroundColor: "rgba(230, 250, 236)" }}>
<Grid container spacing={2}>
<Grid item xs={3}>
                   DaWinci Department
</Grid>
<Grid item xs={8} textAlign="center"marginLeft="20px">
                   Synergi Responsible Unit
</Grid>
</Grid>
</TableCell>
<TableCell align="center" width={"280px"} style={{ fontWeight: "bold", backgroundColor: "rgba(230, 250, 236)" }}>
               Actions
</TableCell>
</TableRow>
</TableHead>
<TableBody>
           {props.data.map((row, index) => 
            row.action ===  TABLE_ACTION.DELETE ? null:(
<TableRow key={row.guid} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
<TableCell align="center">

<Grid container spacing={1}>
<Grid item xs={6}>
                     {props.isEditModeOn_ru && index === selectedIndex ? (
                    <Typography style={{ textAlign: "left",marginLeft:"20px" }} >{row.dawinciDepartment}</Typography>
                     ) : (
<Typography style={{ textAlign: "left",marginLeft:"20px" }}>{row.dawinciDepartment}</Typography>
                     )}
</Grid>
<Grid container item xs={6} alignSelf="center">
                     {props.isEditModeOn_ru && index === selectedIndex ? (
<Grid container spacing={1}>
<Grid item>
<DropDown
                             levelOneData={levelOneRuData}
                             ChildLevelData={props.childLevelsForRu}
                             assetId={props.assetId}
                             accessToken={props.accessToken}
                             buttonName={"Select RU"}
                             identifier={"respUnit"}
                             assignMetadata={handleRuSelection}
                             showIcon={props.isEditModeOn_ru}
                           />
</Grid>
</Grid>
                     ) : null}
<Grid container spacing={1}>
<div style={{
  backgroundColor: row.synergiUnit ? "" : "#FFE7D6",
  minWidth: "300px",
  minHeight: "20px",
  fontSize:"12px",
  ...(props.isEditModeOn_ru && index === selectedIndex && { backgroundColor: "" }) // add the condition here
}}>
  {row.synergiUnit && typeof row.synergiUnit === "string"
    ? arrayToChip(row.synergiUnit.split("->"))
    : arrayToChip(row.synergiUnit)}
</div>
</Grid>
</Grid>
</Grid>

</TableCell>
<TableCell align="right">
<Grid container justifyContent={"right"}>
                   {props.isEditModeOn_ru && index === selectedIndex ? (
<Grid item xs={6}>

<Tooltip title="Close" placement="top">
                            <Button variant="text">
                              <CancelIcon
                                onClick={() => (
                                  setEditStatus(false, index))}
                              />
                            </Button>
                          </Tooltip>
</Grid>
                   ) : (
<Grid item xs={4}>
<Tooltip title="Edit">
<Button variant="text">
<EditTwoToneIcon  onClick={() => (setUserWarningComapny(true),
                                  setEditStatus(true, index))} />
</Button>
</Tooltip>
</Grid>
                   )}
<Grid item xs={2}>
<Tooltip title="Delete">
<Button variant="text" onClick={() => handleDeleteClick(index)}>
<DeleteOutlineTwoToneIcon />
</Button>
</Tooltip>
</Grid>
<Grid item xs={3}></Grid>
</Grid>
</TableCell>
</TableRow>
           ))}
</TableBody>
</Table>
</TableContainer>
<BootstrapDialog onClose={handleCancel} open={open}>
<DialogContent>
<Typography gutterBottom>Would you like to go ahead with deleting the record ?</Typography>
</DialogContent>
<DialogActions>
<Button autoFocus onClick={handleConfirmDelete}>
           Continue
</Button>
<Button autoFocus onClick={handleCancel} variant={"outlined"}>
           Cancel
</Button>
</DialogActions>
</BootstrapDialog>
</>
 );
};
export default DaWinciSynergiDeptReferenceTable;
