import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import DaWinciSynergiDeptReferenceTable from "./DaWinciSynergiDeptReferenceTable";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import { IDENTIFIER } from "../../../Constant";
import DaWinciSynergiDeptIUReferenceTable from "./DaWinciSynergiDeptIUReferenceTable";

const DeptIuComponent = (props,onUpdate) => {
  function handleChildClick(value) {
    props.onChildClick(value);
  }

  
  return (
    <React.Fragment>
      <Grid container>
          <DaWinciSynergiDeptIUReferenceTable
            data={props.data}
            accessToken={props.accessToken}
            handleTableRowDeleteDept={props.handleTableRowDeleteDept}
            childLevelsForIu={props.childLevelsForIu}
            assetId={props.assetId}
            isEditModeOn_iu={props.isEditModeOn_iu}
            dawinciInstallationCode={props.dawinciInstallationCode}
            onChildClick={handleChildClick}
          />
       
        
        {/* <Grid item xs={12} textAlign="center">
          <Button
            variant="text"
            onClick={() => props.handleRowAddition(IDENTIFIER.DEPARTMENTIU)}
          >
            <AddBoxTwoToneIcon />
          </Button>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

export default DeptIuComponent;
