import React, { Component } from "react";
import { config, loginRequest, tokenRequest } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { StarProgress } from "@equinor/eds-core-react";
import jwt_decode from "jwt-decode";



function logTokenExpiry(accessToken){
  const accessTokenDatalog = jwt_decode(accessToken);
  if(accessTokenDatalog.exp){

  const expiryTime = new Date (accessTokenDatalog.exp * 1000)
  
}else{
}
}


export default function withAuth(WrappedComponent) {
 class AuthenticatedComponent extends Component {
   constructor(props) {
     super(props);
     this.state = { authStatus: false, userName: null };
     this.msalObject = new PublicClientApplication(config);
   }

   componentDidMount() {
     if (!this.state.authStatus) {
       this.signInHandler();
       this.checkTokenExpirationandRenew();
       setInterval(()=> this.checkTokenExpirationandRenew(),1*60*1000)
     } else {
       // checking the token

       this.checkTokenExpirationandRenew();
       setInterval(()=> this.checkTokenExpirationandRenew(),1*60*1000)
     }
   }

   signInHandler = () => {
     this.msalObject
       .handleRedirectPromise()
       .then(() => {
         this.handleResponse();
       })
       .catch((err) => {
         this.setState({ error: err });
      
       });
   };

   handleResponse = (response) => {
     const userAccounts = this.msalObject.getAllAccounts();
    
     if (userAccounts.length === 0) {
       this.msalObject.loginRedirect(loginRequest);
     } 
     else if (userAccounts.length > 0) {
       let roles = [];
       if (userAccounts[0].idTokenClaims.roles) {
         roles = userAccounts[0].idTokenClaims.roles;
       }
       this.msalObject
         .acquireTokenSilent({
           account: userAccounts[0],
           scopes: tokenRequest.scopes,
         })
         .then((token) => {
          const accessTokenData = jwt_decode(token.accessToken);
           this.setState({
             userName: userAccounts[0].name,
             authStatus: true,
             userAccount: userAccounts[0],
             roles: accessTokenData.roles,
             accessToken: token.accessToken,
           });
          //  logTokenExpiry(token.accessToken);
         })
         .catch((err) => {
           this.setState({
             userName: userAccounts[0].name,
             authStatus: true,
             userAccount: userAccounts[0],
             roles: roles,
           });
         });
     }
   };

   renewTokenSilently = () => {
     this.msalObject
       .acquireTokenSilent({
         account: this.state.userAccount,
         scopes: tokenRequest.scopes,
       })
       .then((token) => {
         this.setState({ accessToken: token.accessToken });
        
       })
       .catch((err) => {
         console.error("Failed to renew access token silently:", err);
       });
   };

   getAccessToken = async (userAccount) => {
     const token = await this.msalObject.acquireTokenSilent({
       account: userAccount,
       scopes: tokenRequest.scopes,
     });

   
     this.setState({ accessToken: token.accessToken });
     return token.accessToken;
   };

   checkTokenExpirationandRenew = () =>{
    const now = new Date();
   
    if(this.state.userAccount && this.state.accessToken){
    
      const accessTokenData = jwt_decode(this.state.accessToken);
      if(accessTokenData.exp * 1000 < now.getTime()){
       
        this.renewTokenSilently();

      }
    }
   };
   render() {
     let authenticationComponent = (
<React.Fragment>
<StarProgress
           color="primary"
           style={{ marginLeft: "50%", marginTop: "15%" }}
         />
</React.Fragment>
     );

     if (this.state.authStatus) {
       authenticationComponent = (
<WrappedComponent
           isAuthenticated={this.state.authStatus}
           user={this.state.userName}
           getAccessToken={this.getAccessToken}
           signIn={this.signInHandler}
           accessToken={this.state.accessToken}
           userAccount={this.state.userAccount}
           roles={this.state.roles}
           renewToken={this.renewTokenSilently}
           {...this.props}
         />
       );
     }
     return authenticationComponent;
   }
 }
 return AuthenticatedComponent;
}