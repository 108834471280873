import React, { useState, Fragment, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';
import { deepLevelLocationData } from '../../_axios';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledBox = styled(Box)(({ theme }) => ({
  width: '550px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

const DropdownItem = ({ data, onSelect, selectedItem }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasChildren = data.value && data.value.length > 0;

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    onSelect(data);
    if (!hasChildren) {
      setIsOpen(false);
    }
  };

  return (
    <Fragment>
      <ListItem disablePadding>
        <ListItemButton
          onClick={handleClick}
          selected={selectedItem && selectedItem.id === data.id}
          sx={{
            ...(selectedItem && selectedItem.id === data.id && {
              bgcolor: 'primary.light', // adjust the color to suit your theme
            }),
          }}
        >
          <ListItemText primary={data.name} />
          {hasChildren && (
            isOpen ? <ExpandLessIcon onClick={toggleDropdown} /> : <ExpandMoreIcon onClick={toggleDropdown} />
          )}
        </ListItemButton>
      </ListItem>
      {hasChildren && isOpen && (
        <List disablePadding component="div" sx={{ bgcolor: 'background.paper', pl: 4 }}>
          {data.value.map((child) => (
            <DropdownItem
              key={child.id}
              data={child}
              onSelect={onSelect}
              selectedItem={selectedItem}
            />
          ))}
        </List>
      )}
    </Fragment>
  );
};

const MultiLevelDropdown = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [tempSelectedOption, setTempSelectedOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const[options,setOptions]=useState()
  const[locationId,setLocationId]=useState()
  useEffect(() => {
    // Fetch data from API when the component mounts
    const fetchData = async () => {
      try {
        // Replace with your API endpoint
        const response = await deepLevelLocationData(props.accessToken,"Norway");;
    
        setOptions(response.data.data[0])
        // 0
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  //  options = [
  //   {
  //     id: '1',
  //     name: 'Option 1',
  //     children: [
  //       { id: '1-1', name: 'Sub Option 1-1' },
  //       { id: '1-2', name: 'Sub Option 1-2' },
  //       {
  //         id: '1-3',
  //         name: 'Sub Option 1-3',
  //         children: [
  //           { id: '1-3-1', name: 'Sub Sub Option 1-3-1' },
  //           { id: '1-3-2', name: 'Sub Sub Option 1-3-2' },
  //           {
  //             id: '1-3-3',
  //             name: 'Sub Sub Option 1-3-3',
  //             children: [
  //               { id: '1-3-3-1', name: 'Sub Sub Sub Option 1-3-3-1' },
  //               {
  //                 id: '1-3-3-2',
  //                 name: 'Sub Sub Sub Option 1-3-3-2',
  //                 children: [
  //                   { id: '1-3-3-2-1', name: 'Sub Sub Sub Sub Option 1-3-3-2-1' },
  //                   { id: '1-3-3-2-2', name: 'Sub Sub Sub Sub Option 1-3-3-2-2' },
  //                 ],
  //               },
  //               { id: '1-3-3-3', name: 'Sub Sub Sub Option 1-3-3-3' },
  //             ],
  //           },
  //           { id: '1-3-4', name: 'Sub Sub Option 1-3-4' },
  //         ],
  //       },
  //       { id: '1-4', name: 'Sub Option 1-4' },
  //     ],
  //   },
  //   // ... more top-level items as needed
  // ];

  const handleSelect = (option) => {
    setTempSelectedOption(option);
    setLocationId(option.id); // Update the locationId state with the selected option's id

    props.onLocationIdChange(option.id); // Call the callback function with the updated value of locationId
  };


  const handleConfirmSelection = () => {
    setSelectedOption(tempSelectedOption);
    setIsDropdownOpen(false);
    };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <div>
    <Button variant="outlined" style={{color:"black", borderColor:"#CECECE",width:"530px",height:"40px"}} onClick={toggleDropdown}>
  {tempSelectedOption ? tempSelectedOption.name : props.dropdownValue }
  <ArrowDropDownIcon style={{marginLeft: 'auto',color:"#6C6666"}} />
</Button>
      {isDropdownOpen && (
        <StyledBox>
          <Typography variant="subtitle1" sx={{width:"540px", p: 2, bgcolor: 'primary.main', color: 'primary.contrastText' }}>
            Selected Option: {tempSelectedOption ? tempSelectedOption.name : 'None'}
          </Typography>
          <List component="nav" aria-name="multi-level-dropdown" sx={{ maxWidth:"570px",bgcolor: 'background.paper', flex: 1, overflow: 'auto' }}>
            {options?.value?.map((option) => (
              <DropdownItem
                key={option.id}
                data={option}
                onSelect={handleSelect}
                selectedItem={tempSelectedOption}
              />
            ))}
          </List>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button variant="contained" color="primary" onClick={handleConfirmSelection}>
              OK
            </Button>
          </Box>
        </StyledBox>
      )}
    </div>
  );
};

export default MultiLevelDropdown;
