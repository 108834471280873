import React, { useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Grid,
  Button,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import SearchAndDropdown from "../../../SearchAndDropdown/SearchAndDropdown";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { ruSelectionContext } from "../../DaWinciScreenA";
import { IDENTIFIER, TABLE_ACTION } from "../../../Constant";
import { globalVariable } from "../../../../App";

const DaWinciSynergiCompanyReferenceTable = (props) => {
  //usecontext
  const {
    _handleRuAssignmentToDept,
    _handleDawinciTextChange,
    _handleSynergiCompanyAssignment,
  } = useContext(ruSelectionContext);

  const [selectedIndex, setSelectedIndex] = useState();

  const handleTableRowSelection = (e, index) => {
    setSelectedIndex(index);
  };
  const handleCompanySelectionTemp = (event, child) => {
    //setSynergiCompanyId(event.target.value);
    _handleSynergiCompanyAssignment(
      child.label,
      selectedIndex,
      child.value
    );
  };
  const setEditStatus = (status, index) => {

    _setIsEditModeOn(status);
    setSelectedIndex(index);
  };
  const {_setIsEditModeOn ,setUserWarningComapny} =useContext(globalVariable);
  return (
    <React.Fragment>
     <TableContainer>
        <Table
          sx={{ marginTop: "30px" ,border: 1 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={4}
                style={{ fontWeight: "bolder", backgroundColor: 'rgba(230, 250, 236)',fontSize:"18px" }}
              >
                COMPANY MAPPING
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                width={"500px"}
                style={{ fontWeight: "bold" ,backgroundColor: 'rgba(230, 250, 236)', }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                  DaWinci Company
                  </Grid>
                  <Grid item xs={5}>
                    Synergi Company
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center" width={"80px"} style={{ fontWeight: "bold", backgroundColor: "rgba(230, 250, 236)" }}>
               Actions
</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.data.map((row, index) =>
              row.action === TABLE_ACTION.DELETE ? null : (
                <TableRow
                  key={row.guid}
                  sx={{
                    "&:last-child td, &:last-child th": { borderBottom: 0 },
                  }}
                >
                  <TableCell>
                 
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <ListItemText
                            primary={
                              <Typography style={{ textAlign: "left",marginLeft:"20px" }}>{row.dawinciCompanyName}</Typography>
                            }
                            sx={{
                              "& .MuiTypography-root": {
                                backgroundColor: "transparent !important",
                      
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          {props.isEditModeOn && index === selectedIndex ? (
                            <SearchAndDropdown
                              searchText={props.searchText}
                              textChange={props.textChange}
                              synergiCompanyList={props.synergiCompanyList}
                              handleSelection={handleCompanySelectionTemp}
                              value={row.synergiCompanyId}
                            />
                          ) : (
                            <div style={{
                              backgroundColor: row.synergiCompanyName ? '' : (row.requestStatus === 'Pending' ? '' : '#FFE7D6'),
                              width: '350px'
                            }}>
                              {row.requestStatus === "Pending" ? row.requestedSynergiCompanyName : (row.synergiCompanyName || "\u00A0")}
                              {row.requestStatus === "Pending" && <span style={{color: "#007079", marginLeft: "5px"}}>(Pending for Approval)</span>}
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    
                  </TableCell>
                  <TableCell style={{ verticalAlign: "top" }}>
                    <Grid container>
                      {props.isEditModeOn && index === selectedIndex ? (
                        <Grid item xs={5} marginLeft={"10px"}>
                          <Tooltip title="Close" placement="top">
                            <Button variant="text">
                              <CancelIcon
                                onClick={() => (setUserWarningComapny(true),
                                  setEditStatus(false, index))}
                              />
                            </Button>
                          </Tooltip>
                        </Grid>
                      ) : (
                        <Grid item xs={4}>
                          <Tooltip title="Edit" placement="top">
                            <Button variant="text">
                              <EditTwoToneIcon
                                onClick={() =>(setUserWarningComapny(true),
                                   setEditStatus(true, index))}
                              />
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item xs={2}>
                        <Tooltip title="Delete" placement="top">
                          <Button variant="text">
                            <DeleteOutlineTwoToneIcon
                              onClick={() =>( setEditStatus(true),setUserWarningComapny(true),
                                props.handleTableRowDelete(
                                  index,
                                  IDENTIFIER.COMPANY
                                )
                              )
                              }
                            />
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3}></Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default DaWinciSynergiCompanyReferenceTable;