//refer documentation for more
//https://github.com/microsoft/ApplicationInsights-JS#sending-telemetry-to-the-azure-portal
//https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { APP_INSIGHT_TYPE } from "../Constant";

const browserHistory = createBrowserHistory({ basename: "" });

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPINSIGHT_CONNECTION_STRING,
    //instrumentationKey: process.env.REACT_APP_APPINSIGHT_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();

export const appInsightTrackException = (error) => {
  appInsights.trackException({
    exception: new Error(error),
  });
};

export const appInsightTrackEvent = (inputMessage) => {
  appInsights.trackEvent({
    name: APP_INSIGHT_TYPE.HTTP_RESPONSE,
    properties: { message: inputMessage },
  });
};

export { reactPlugin, appInsights };
