export const config = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority : 'https://login.microsoftonline.com/' + process.env.REACT_APP_TENANT_ID,
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
    },
    cache:{
        cacheLocation :"localStorage",
        storeAuthStatedInCookie:false,
    },
};

export const loginRequest = {
    scopes: ["User.Read","openid", "profile"]
};

export const tokenRequest = {
    scopes: ["openid","profile","offline_access",process.env.REACT_APP_TOKEN_SCOPE]
};