
import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, MenuItem, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { getAssetsMappedToUserAsync ,getcluster
} from "./_axios";
import { StarProgress } from "@equinor/eds-core-react";
import GenericError from "./GenericError/GenericError";
import { API_RESPONSE_STATUS, APP_INSIGHT_TYPE } from "./Constant";
import { snackbarContext } from "../App";
import {
  appInsights,
  appInsightTrackEvent,
  appInsightTrackException,
} from "./Logging/Logging";
import { size } from "lodash";
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import Select from 'react-select';
import FilterListIcon from '@mui/icons-material/FilterList';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import { Icon } from '@equinor/eds-core-react'
import { tag_relations } from '@equinor/eds-icons' // import "save" icon


const useStyles = makeStyles({
  assetCard: {
    width: 100,
    height: 130,
    padding: 20,
    margin: 10,
    backgroundColor: "#DFF5FF",
  },
});

const AssetAdminPage = (props) => {
  //app insight metric
  appInsights.trackPageView();

  const classes = useStyles();
  const navigate = useNavigate();

  //state
  //const [asset] = useState(assetMasterData);
  const [asset, setAsset] = useState([]);
  const [cluster, setCluster] = useState([]);
  const [assetId, setAssetId] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const[errorFive,setErrorFive]=useState("");
  const[noValue,setNoValue]=useState("");
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
  });
  const [selectedasset,setSelectedasset]=useState("");
  //sending location
const[sendautopopulate,setautopoulate]=useState([]);
  //state for snackbar
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
    useContext(snackbarContext);

    const [selectedItem, setSelectedItem] = useState(
      sessionStorage.getItem('selectedCluster') || ''
    );
    const [clustername, setClustername] = useState(
      sessionStorage.getItem('selectedClustername') || ''
    );
//use effect to load cluster
useEffect(()=>{
  const getclusterdata = async ()=>{
    try{
      const response= await getcluster(props.accessToken);
      setIsLoading(false)
      if (response.status === 200) {
       
        setCluster(response.data);
       if(!selectedItem){
        setClustername(response.data[0].name)
     setSelectedItem(response.data[0].name)
       }
     sessionStorage.setItem('selectedCluster', selectedItem);
     sessionStorage.setItem('selectedClustername', clustername)

      } else if (response.status === 403) {
        appInsightTrackEvent("You are not Authorized to Access this Application");
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
         _setSnackbarMessage("You are not Authorized to Access this Application");
                   _setShouldOpen(true);
                   setIsLoading(false);
                   setError({
                     errorStatus: true,
                   errorMessage: "You are not Authorized to Access this Application",
                 });
      }
      else if (response.status === 500) {
        appInsightTrackEvent("The site seems to be unavailable, please try after some time.");
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
         _setSnackbarMessage("The site seems to be unavailable, please try after some time.");
                   _setShouldOpen(true);
                   setIsLoading(false);
                   setError({
                     errorStatus: true,
                   errorMessage: "The site seems to be unavailable, please try after some time.",
                 });
      }
      else if (response.status === 404) {
        appInsightTrackEvent("The site seems to be unavailable, please try after some time.");
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
         _setSnackbarMessage("The site seems to be unavailable, please try after some time.n");
                   _setShouldOpen(true);
                   setIsLoading(false);
                   setError({
                     errorStatus: true,
                   errorMessage: "The site seems to be unavailable, please try after some time.",
                 });
      }
       else {
        appInsightTrackEvent(response.data === "" ? "Something went wrong,Please try again" : response.data);
        //notification for error
        _setShouldOpen(true);
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage(response.data === "" ? "Something went wrong,Please try again" : response.data)
        setIsLoading(false);
        setError({
          errorStatus: true,
          errorMessage: (response.data === "" ? "Something went wrong,Please try again": response.data),
        });
      }
    } catch (error) {
      setIsLoading(false);
       setError({
        errorStatus: true,
        errorMessage: "Something went wrong,Please try again"
      });
      appInsightTrackException(error);

    }
  };

 

  getclusterdata();
}, [props.accessToken]);

 


  //use effect to load assets mapped to a user
  useEffect(() => {
  
    const getAssetsRelatedToUser = async (x) => {
      try {
          // Save the selected cluster to session storage whenever it changes
   sessionStorage.setItem('selectedCluster', selectedItem);
   sessionStorage.setItem('selectedClustername', clustername);
        const response = await getAssetsMappedToUserAsync(props.accessToken,selectedItem);
setIsLoading(false);
        if (response.status === 200) {
          setError({...error,errorStatus:false,errorMessage:""})
          setAsset(response.data);
       
       
        } else if (response.status === 403) {
          appInsightTrackEvent("You are not Authorized to Access this Application");
          _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
           _setSnackbarMessage("You are not Authorized to Access this Application");
                     _setShouldOpen(true);
                     setIsLoading(false);
                     setError({
                       errorStatus: true,
                     errorMessage: "You are not Authorized to Access this Application",
                   });
        }
        else if (response.status === 404) {
          appInsightTrackEvent("You are not Authorized to Access this Application");
          _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
           _setSnackbarMessage(response.data);
                     _setShouldOpen(true);
                     setIsLoading(false);
                     setError({
                       errorStatus: true,
                     errorMessage: response.data,
                   });
        }
         else {
          appInsightTrackEvent(response.data === "" ? "Something went wrong,Please try again" : response.data);
          //notification for error
          _setShouldOpen(true);
          _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
          _setSnackbarMessage(response.data === "" ? "Something went wrong,Please try again" : response.data)
          setIsLoading(false);
      
          setError({
            errorStatus: true,
            errorMessage: (response.data === "" ? "Something went wrong,Please try again": response.data),
          });
        }
      } catch (error) {
        setIsLoading(false);
         setError({
          errorStatus: true,
          errorMessage: "Something went wrong,Please try again"
        });
        appInsightTrackException(error);
       
      }
    };
   // Call the fetchData function whenever 'selectedItem' changes
   if (selectedItem) {
    getAssetsRelatedToUser()
  }
}, [selectedItem]);
    // useEffect(() => {
  
    //   x && 
    //   getAssetsRelatedToUser(x);
    // },[x]);
  //   getAssetsRelatedToUser(clustervalue);
  // }, [props.accessToken,clustervalue]);
  
//   const getAutoPopulateRU= async (loc) => {
//     try {
//       const response = await autoPopulateRU(loc,props.accessToken);
     
//       setIsLoading(false);
//       if (response.status === 200) {
        
//       }  }catch (error) {
// //console.log("error")
//     }
//   }; disabled

//   function ClusterChange(event){
    
   
//  if(event.value === "0")
//  {
//   //console.log(event.target.value)
//   setAsset([])
//   setError({
//                        errorStatus: false,
//                      errorMessage: "",
//                    });
//   setClustervalue(0)
//   return setNoValue()
//  }
//     setClustervalue(event.value)
//    getAssetsRelatedToUser(event.value)
// setNoValue("")
//   }

 const handleAssetClick = (index) => {
  
    //note asset if clicked
    setAssetId(asset[index].id)

    setSelectedasset((asset[index].dawinciInstallationCode.trim()))
  

      navigate("/mappingTab", {
        state: {
          assetId: asset[index].assetId,
          assetCode: asset[index].dawinciInstallationCode.trim(),
          assetName: asset[index].assetName,
        },
      });
      return;
    
  };


  const handleSelectChange = (event) => {
    setSelectedItem(event.label);
    setClustername(event.label)
    //console.log(event.label)
  };
  const assetsJsx = asset.map((assetObj, index) => {
   return (
      
      // <Grid className={assetObj.asset_Image_Url ? "" : "CardImage" } item xs={2} key={assetObj.id} textAlign="center">
        <Card className={classes.assetCard}>
          <CardActionArea onClick={() =>{ handleAssetClick(index);
      
 } }>
           {
             <img
            component="img"
            height="85px"
            width="100px"
            src={require('./asseticon.png')}
            
            />
            }
            <CardContent>
            <Typography variant="h7" component="div" style={{ fontWeight:"bold",fontFamily:"Equinor"}}>
               
               {assetObj.assetName}
             </Typography>
              {/* <Typography variant="body2" color="text.secondary">
                {assetObj.asset_Description}
              </Typography> */}
            </CardContent>
          </CardActionArea>
         
        </Card>
      // </Grid>
    );
  });

  return (
    <React.Fragment
    >
      <div style={{  fontFamily:"Equinor",}}>
       <Box
    sx={{
      flexGrow: 1,
      marginLeft: "-10px",
      marginRight: "-10px",
      marginTop: "-10px",
      
    }}
  >
  <Grid 
  style={{ 
    backgroundColor: "#EAF4F9", 
    padding: "10px",
    position: "relative",
    top: "-2px",
    width: "100%",
    height: "90px",
    boxSizing: "border-box",
    margin: "0",
    display: "flex", // Add display flex to enable flexbox layout
    justifyContent: "center", // Center the text horizontally
    alignItems: "center",
     // Center the text vertically
  }}
>
<span 
    style={{
      position: "absolute", // Position the text absolutely inside the Grid element
   
      fontSize: "18px",
      
      color: "#000000",
      fontFamily:"Equinor",
    marginLeft: "60px",
    marginRight: "120px"
    }}>
     The objective of this application is to collect work hours information from multiple sources and present a uniform approach to collecting and exposing this information. The work hours calculation is very important since this is key data point on which SIF and TRIF numbers for the organization are calculated.
      
  </span>
        </Grid>
        </Box>
      <Grid style={{marginLeft:"40px",marginTop:"40px"}}>
        Cluster
      
      <Box sx={{ flexGrow: 2 }}>
        <div style={{display: 'flex', justifyContent:'flex-start'}}>
          
        
         <Icon style={{marginRight:"5px",marginTop:"20px",color:"#007079"}}data={tag_relations} size={32}/>
          
      <div  style={{ marginTop: "20px",fontWeight:"bold",fontSize:"18px",alignContent:"center",width:"200px"}}>
      {/* <Select 
    label= ""
    name="Selected Cluster" defaultvalue="EPN North"
      onChange={handleSelectChange}
       options={ cluster.map((api)=>
            ({label: api.cluster, value: api.id})
         
        )}
       value={clustername ? {label:clustername, value:selectedItem}:null}
      // placeholder="Search Cluster"
    /> */}
      <Select 
    label= ""
    defaultValue={ cluster.map((api)=>
      (api.name[0])
  )}
      onChange={handleSelectChange}
       options={ cluster.map((api)=>
            ({label: api.name, value: api.name})
         
        )}
       value={clustername ? {label:clustername, value:selectedItem}:null}
    >
    
    </Select>


    
         {/* <Select
    
 options={ cluster.map((api)=>
            ({label: api.cluster, value: api.id})
         
        )}
        placeholder="Search Cluster"
         onChange={ClusterChange}
      /> */}
      </div>
      
     
     
      </div>
      
     
      {/* <Typography variant="h9" style={{ marginTop: "1px",color: "#007079", fontSize:"20px" ,marginLeft:"10px"}}>  {clustername}</Typography> 
     */}
        {isLoading ? (
          <StarProgress style={{ marginLeft: "50%", marginTop: "5%" }} />
        ) : error.errorStatus ? (
          <Container maxWidth="lg">
            <Grid container spacing={1}>
              <GenericError errorMessage={error.errorMessage} />
            </Grid>
          </Container>
        ) : (
          <div style={{marginTop:"40px"}}>
            Location
            <Grid container spacing={1}>
              {assetsJsx}
              {noValue}
            </Grid>
            </div>
        
        )}
      </Box>
      {/* <div style={{ position: "absolute", width: "100%", height: "auto", display: "flex",alignItems: "flex-end"}}>

              <img
                src="https://cdn.eds.equinor.com/logo/equinor-logo-primary.svg#red"
                alt="Equinor"
                width="110"
                height="70"
              />
            </div> */}

<div style={{ position: "relative", maxWidth: "20%",marginTop:"7.5%" }}>
  <img
    src="https://cdn.eds.equinor.com/logo/equinor-logo-primary.svg#red"
    alt="Equinor"
    style={{ position: "absolute", left: "0", width: "25%", height: "auto" }}
  />
</div>
            
   
            </Grid>
            </div>
    </React.Fragment>
  );
};

export default AssetAdminPage

