import React, { useState, useEffect, useContext } from "react";
import {
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Grid,
 Button,
 TextField,
 Tooltip,
 Typography,
 DialogContent,
 DialogActions,
 Dialog,
 ListItemButton,
 Checkbox,
 FormControlLabel,
} from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { styled } from "@mui/material/styles";
import { daWinciSynergiReferenceData, levelOneRespUnitData } from "../../../_axios";
import { ruSelectionContext } from "../../DaWinciScreenA";
import { IDENTIFIER, TABLE_ACTION } from "../../../Constant";
import { appInsightTrackEvent, appInsightTrackException } from "../../../Logging/Logging";
import { globalVariable } from "../../../../App";
import DropDownIU from "../../../DropDown/DropDownIU";
import { arrayToChip } from "../../../CommonFunction";
import CancelIcon from '@mui/icons-material/Cancel';
import DepartmentSelectionPopup from "./DepartmentSelectionPopup";
import PopupForm from "./DepartmentSelectionPopup";
import PopupFormIU from "./DepartmentSelectionPopup";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Deptdropdown } from "./Deptdropdown";
 
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
 "& .MuiDialogContent-root": {
   padding: theme.spacing(2),
 },
 "& .MuiDialogActions-root": {
   padding: theme.spacing(1),
 },
}));
 
const DaWinciSynergiDeptIUReferenceTable = (props) => {
 
  const { _handleRuAssignmentToDept, _handleDawinciTextChangeDept } = useContext(ruSelectionContext);
  const { setUserWarningComapny, _setIsEditModeOn, _setIsEditModeOn_iu } = useContext(globalVariable);
 
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [levelOneRuData, setLevelOneRuData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  let [groupedRows, setGroupedRows] = useState([]);
  let [finalRows, setFinalRows] = useState([]);
 
  useEffect(() => {
    const loadLevelOneRuData = async () => {
      try {
        const responseRuData = await levelOneRespUnitData(props.accessToken);
        if (responseRuData.status === 200) {
          setLevelOneRuData(responseRuData.data.data);
        } else {
          appInsightTrackEvent("failed to load level one responsible unit data");
        }
      } catch (error) {
        appInsightTrackException(error);
      }
    };
    loadLevelOneRuData();
  }, [props.accessToken]);

  const handleTableRowSelection = (e, index) => {
    setSelectedIndex(index);
  };
 
  const handleRuSelection = (selection, identifier, selectionId) => {

    const departmentArray = finalRows[selectedIndex].departments || [{id: finalRows[selectedIndex].id}];
const idList = departmentArray.map(dept => dept.id);
  
    _handleRuAssignmentToDept(selection,selectedIndex, selectionId, identifier,idList);

    
  };
  const handleIuSelection = (event) => {
 
    const departmentArray = finalRows[selectedIndex].departments || [{id: finalRows[selectedIndex].id}];
    const IUidfordept = finalRows[selectedIndex].synergiUnitId;
    const IUnamefordept = finalRows[selectedIndex].synergiUnit;
 
    _handleDawinciTextChangeDept(event,selectedIndex,IDENTIFIER.DEPARTMENTIU,departmentArray,IUidfordept,IUnamefordept)
   
  };

  
  const setEditStatus = (status, index) => {
    _setIsEditModeOn_iu(status);
    setSelectedIndex(index);
  };
 
  const handleDeleteClick = (index) => {
    setDeleteIndex(index);
    setOpen(true);

  };
 
  const handleConfirmDelete = () => {
    setUserWarningComapny(true);
 
    if (deleteIndex !== null) {
      props.handleTableRowDeleteDept(deleteIndex, IDENTIFIER.DEPARTMENTIU,finalRows);
      setOpen(false);
    }
  };
 
  const handleCancel = () => {
    setOpen(false);
    setDeleteIndex(null);
  };
 
  const [iuopen, setIuopen] = useState(false);
 
  const handleAddRow = () => {
    setIuopen(true);
  };
 
  const handleClose = () => {
    setIuopen(false);
    let responseupdate = daWinciSynergiReferenceData(props.accessToken, props.dawinciInstallationCode);
  };
 
  const [selectedValues, setSelectedValues] = useState([]);
 
  const [updated, setUpdated] = useState(props.data);
 
  useEffect(() => {
    // this function will be called whenever props.data changes
    setUpdated(props.data);
  }, [props.data]);

  const allDepartments = updated.reduce((arr, { dawinciDepartment, id }) => {
    
    const index = arr.findIndex(item => item.department === dawinciDepartment);
    if (index < 0) {
      arr.push({ department: dawinciDepartment, id: id });
    } else {
      arr[index].id = arr[index].id + ', ' + id;
    }
    return arr;
  }, []); 
  useEffect(() => {
  const groupedData = updated.reduce((map, { id, action, synergiUnit, synergiUnitId, dawinciDepartment }) => {
    if (!synergiUnit) {
      synergiUnit = '';
    }
    if (!map[synergiUnit]) {
      map[synergiUnit] = { [dawinciDepartment]: [{ id, synergiUnitId, action: action ? action : null }] };
    } else if (!map[synergiUnit][dawinciDepartment]) {
      map[synergiUnit][dawinciDepartment] = [{ id, synergiUnitId, action: action ? action : null }];
    } else {
      map[synergiUnit][dawinciDepartment].push({ id, synergiUnitId, action: action ? action : null });
    }
    return map;
  }, {});
  
  const groupedRows = Object.entries(groupedData).map(([synergiUnit, deptObj]) => {
    const departments = Object.entries(deptObj).map(([department, ids]) => {
      const idList = ids.map(({ id }) => id);
      const synergiUnitIdList = ids.map(({ synergiUnitId }) => synergiUnitId);
      const actionList = ids.map(({ action }) => action);
      return { department, id: idList[0], action: actionList[0] };
    });
    const synergiUnitId = deptObj[Object.keys(deptObj)[0]][0].synergiUnitId;
    return { synergiUnit, synergiUnitId, departments };
  }).flat();
  
  const blankDepts = groupedRows.filter(row => row.synergiUnit === '');
  const newRows = blankDepts.flatMap(({ departments }) => {
    return departments?.map(({ department, id, action }) => ({ synergiUnit: '', department, id, action }));
  });
  
  const finalRows = groupedRows.filter(row => row.synergiUnit !== '').concat(newRows);
  
  setFinalRows(finalRows);
  }, [updated]);

  // ungroup the data
  // ungroup the data
  const [ungroupedData, setUngroupedData] = useState([]);
  useEffect(() => {

  }, [finalRows, setFinalRows]);
  useEffect(() => {
    const newData = finalRows.reduce((acc, curr) => {
      const { departments, synergiUnit, synergiUnitId } = curr;
      if (departments) {
        departments.forEach((dept) => {
          const { department, id } = dept;
          if (id) { // Check if id property is present
            acc.push({
              action:"update",
              id,
              dawinciDepartment: department,
              synergiUnitId,
              synergiUnit,
              synergiCaseTypeDescription: 'Man Hours - Internal suppliers/Units involved',
              // add other properties as needed
            });
          }
        });
      } else {
        if (curr.id) { // Check if id property is present
          acc.push({
            action:"update",
            id: curr.id,
            dawinciDepartment: curr.department,
            synergiUnitId: null,
            synergiUnit: null,
            synergiCaseTypeDescription: 'Man Hours - Internal suppliers/Units involved',
            // add other properties as needed
          });
        }
      }
      return acc;
    }, []);
    setUngroupedData(newData);
  }, [selectedValues]);
  useEffect(() => {
    function createDataArray() {
      let dataArray = [];
 
      for (let i = 0; i < ungroupedData.length; i++) {
        const currentObj = ungroupedData[i];
        const existingObj = dataArray.find(obj => obj.id === currentObj.id);
 
        if (!existingObj) {
          // If id is unique, add it to the array
          dataArray.push(currentObj);
        } else {
          // If id is not unique, check if synergiUnitId is also the same
          if (existingObj.synergiUnitId !== currentObj.synergiUnitId && existingObj.synergiUnitId !== null && currentObj.synergiUnitId !== null) {
            // If synergiUnitId is different and neither is null, throw an error
         
          }
            else if (existingObj.synergiUnitId === null && currentObj.synergiUnitId !== null) {
            // If synergiUnitId is different and one of them is null, remove the one with null synergiUnitId
            dataArray = dataArray.filter(obj => obj.id !== existingObj.id);
            dataArray.push(currentObj);
          } else {
            // If synergiUnitId is the same, do nothing
          }
        }
      }
 
    }
 
    createDataArray();
 
  }, [ungroupedData,selectedValues]);
  

 
const [departmentList, setDepartmentList] = useState([]);

useEffect(() => {
if (selectedValues?.some(value => value?.selectedOption)) {
  // loop through each index of selectedValues

  selectedValues.forEach((selectedValue, index) => {
   
    // check if the selectedOption is not an empty array before updating departments
    if(selectedValues[index]?.index >= 0 ) {
    
      finalRows[index].departments = selectedValue.selectedOption.map(option => {
        return { department: option.label, id: option.value };
      });
    }
  });
}
}, [selectedValues]);
 
useEffect(() => {
  setDepartmentList(props.data);
}, []);
 
// const finalDepartments = finalRows.flatMap(row => {
//   const departments = row?.departments || [{ department: row?.department, id: row.id }];
//   return departments.map(({ department, id }) => ({ department, id }));
// })
 
 
  return (
    <>
    <TableContainer>
      <Table sx={{ marginTop: "30px", border: 1 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={4} style={{ fontWeight: "bolder", backgroundColor: "rgba(230, 250, 236)" }}>
              DEPARTMENT - INVOLVED UNIT MAPPING
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" style={{ fontWeight: "bold", backgroundColor: "rgba(230, 250, 236)" }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  Synergi Involved Unit
                </Grid>
                <Grid item xs={8} textAlign="center">
                  DaWinci Department
                </Grid>
              </Grid>
            </TableCell>
            <TableCell align="center" width={"280px"} style={{ fontWeight: "bold", backgroundColor: "rgba(230, 250, 236)" }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {finalRows.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Typography color={"red"} variant="h6">No Record Found</Typography>
              </TableCell>
            </TableRow>
          ) : (
            finalRows.map((row, rowIndex) =>
              row.action === TABLE_ACTION.DELETE ? null : (
                <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center">
                    <Grid container spacing={2}>
                      <Grid container item xs={6} style={{ display: 'flex' }}>
                        {props.isEditModeOn_iu && rowIndex === selectedIndex ? (
                          <Grid>
                            <DropDownIU
                              levelOneData={levelOneRuData}
                              ChildLevelData={props.childLevelsForIu}
                              assetId={props.assetId}
                              accessToken={props.accessToken}
                              buttonName={"Select IU"}
                              identifier={"involvedUnit"}
                              assignMetadata={handleRuSelection}
                              showIcon={props.isEditModeOn_iu}
                            />
                          </Grid>
                        ) : null}
                        <Grid item xs={4.5}>
                          {row.synergiUnit instanceof Array ? row.synergiUnit.join(', ') : row.synergiUnit?.split("->").pop()}
                        </Grid>
                      </Grid>
                      <Grid item xs={3} style={{ fontSize: 16 /* increase font size of commas */ }}>
                        {props.isEditModeOn_iu && rowIndex === selectedIndex ? (
                          <Deptdropdown
                            data={departmentList}
                            index={selectedIndex}
                            finalRows={finalRows}
                            getSelectedValue={(selected) => {
                              const newSelectedValues=[]
                              newSelectedValues[rowIndex] = selected;
                              setSelectedValues(newSelectedValues);
                            }}// pass in the selected values
                          />
                        ) : (
                          <>
                            {/* {selectedValues[rowIndex] && selectedValues[rowIndex]?.selectedOption?.length > 0 && (
                              <Typography>
                                {selectedValues[rowIndex]?.selectedOption
                                  ?.map((option) => option.label) // This creates an array with only the label properties of the selectedOption array for the current row
                                  // This filter excludes the selected value for the current row based on the label property
                                  .join(' , ')}
                              </Typography>
                            )} */}

                            <Typography style={{wordWrap: "break-word", whiteSpace:"pre-wrap"}}>
                              {row.department}
                              {row.departments?.map((deptObj, i) => (
                                <React.Fragment key={i}>
                                  {deptObj.department}
                                  {i < (row.departments?.length - 1) && ', '}
                                </React.Fragment>
                              ))}
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="right">
                    <Grid container justifyContent={"right"}>
                      {props.isEditModeOn_iu && rowIndex === selectedIndex ? (
                        <Grid item xs={6}>
                          {/* <Tooltip title="OK">
                            <Button variant="text">
                              <CheckCircleTwoToneIcon onClick={() => setEditStatus(false, rowIndex)} />
                            </Button>
                          </Tooltip> */}
                          <Tooltip title="Close" placement="top">
                            <Button variant="text">
                              <CancelIcon
                                onClick={() => (
                                  setEditStatus(false, rowIndex),
                                  // props.data[rowIndex].dawinciDepartment = row.department,
                                  handleIuSelection()
                                )}
                              />
                            </Button>
                          </Tooltip>
                        </Grid>
                      ) : (
                        <Grid item xs={4}>
                          <Tooltip title="Edit">
                            <Button variant="text" onClick={() => (setUserWarningComapny(true), setEditStatus(true, rowIndex))}>
                              <EditTwoToneIcon />
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item xs={2}>
                        <Tooltip title="Delete">
                          <Button 
                            variant="text" 
                            onClick={() => handleDeleteClick(rowIndex)}
                            disabled={row.synergiUnit === ''}
                          >
                            <DeleteOutlineTwoToneIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3}></Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <BootstrapDialog onClose={handleCancel} open={open}>
      <DialogContent>
        <Typography gutterBottom>Would you like to go ahead with deleting the record ?</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleConfirmDelete}>
          Continue
        </Button>
        <Button autoFocus onClick={handleCancel} variant={"outlined"}>
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>
  </>
  );
};
 
export default DaWinciSynergiDeptIUReferenceTable;