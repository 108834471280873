import React from "react";
import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

//how to use
//all the states are managed in APp.js and are accessed by children using context hook.

//use these lines in child components to pass on data to snackbar
//import {useContext} from react;
//import snackbarContext from App component;
//const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
//useContext(snackbarContext);

//props needed
//open: to open or close the snackbar
//message: to display message
//status: one of the status as defined in Constants.API_RESPONSE_STATUS
//handleclose from parent component

const Feedback = (props) => {
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={props.handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={props.open}
      autoHideDuration={1000}
      onClose={props.handleClose}
      action={action}
    >
      <Alert variant="outlined" severity={props.status} style={{backgroundColor: "white"}}>
  {props.message}
</Alert>
    </Snackbar>
  );
};

Feedback.defaultProps = {
  open: false,
  message: "",
  severity: "info",
};

export default Feedback;
