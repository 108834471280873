import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Container, Grid } from '@mui/material';
import PopupForm from './AssetForm';
import { activeAsset, assetPost } from '../_axios';
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { appInsights,
  appInsightTrackEvent,
  appInsightTrackException, } from '../Logging/Logging';
  
import { snackbarContext } from '../../App';
import { API_RESPONSE_STATUS } from '../Constant';
import { StarProgress } from '@equinor/eds-core-react';
import GenericError from '../GenericError/GenericError';
import {  Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
export const MyContext = React.createContext();

function AssetTable(props) {
  const [refresh, setRefresh] = useState('');
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
    useContext(snackbarContext);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false); // added state variable for showing delete dialog

  // Fetch data from API when the component mounts
  useEffect((refresh) => {
    const fetchData = async (refresh) => {
      try {
        // Replace with your API endpoint
        const response = await activeAsset(props.accessToken);
        if (response.status === 200) {
          setIsLoading(false);
          setRows(response);
        } else if (response.status !== 200) {
          appInsightTrackEvent(response.data);
          _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
          _setSnackbarMessage(response.data);
          _setShouldOpen(true);
          setIsLoading(false);
          setError({
            errorStatus: true,
            errorMessage: response.data,
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(refresh);
  }, [refresh]);

  // Opens the dialog for adding a new row
  const handleAddRow = () => {
    setRowData({ actionType: "add", id: null, textField1: '', textField2: '', dropdown1: '', dropdown2: '', dropdown3: '', dropdown4: '', dropdown5: '' });
    setOpen(true);
  };

  // Opens the dialog for editing a row
  const handleEditRow = (row) => {
    setRowData({
      actionType: "update",
      textField1: row.assetName,
      textField2: row.dawinciInstallationCode,
      dropdown1:row.clusterName,
      dropdown2:row.locationName,
      dropdown3:row.parentLocationName,
      dropdown4:row.unitName,
      dropdown5:row.parentUnitName,
      assetId:row.assetId,
      clusterId:row.clusterId,
      locationId:row.locationId,
      parentLocationId:row.parentLocationId,
      unitId:row.unitId,
      parentUnitId:row.parentUnitId
      // Include other fields that the PopupForm expects
    });
    setOpen(true);
  };

  // Logic to handle deleting a row, this should integrate with your API
  const handleDeleteRow = (row) => {
    setShowDeleteDialog(true); // set showDeleteDialog state variable to true when user clicks Delete button
    setRowData(row);
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false); // set showDeleteDialog state variable to false when user cancels delete operation
    setRowData(null);
  };

  const handleConfirmDelete = async () => {
    setShowDeleteDialog(false);
    setRowData(null);

    const transformedData = {
      ActionName:"delete",
      AssetId:rowData.assetId ,
    };

    try {
      let response = await assetPost(props.accessToken, transformedData);
      if (response.status === 200) {
        _setApiResponseStatus(API_RESPONSE_STATUS.SUCCESS);
        _setSnackbarMessage("Deleted successfully");
        // Call callback function with true value if API call is successful
      } else if (response !== 200) {
        appInsightTrackEvent("Error");
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage("Error");
        _setShouldOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      appInsightTrackException(error);
    }
    setRefresh(rows);
  };

  // Closes the dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Logic to handle saving a row (adding or updating)
  const handleSaveRow = async (row) => {
    // Replace with your API endpoint and save logic
  };

  // Handles search input change and updates searchTerm state
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to filter rows based on search term
  const [filteredTableData, setFilteredTableData] = useState([]);

  useEffect(() => {
    // Assuming 'rows' has a 'data' property which is an array of row objects
    if (rows && rows.data) {
      const filteredData = rows.data.filter(row =>
        row.clusterName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.locationName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredTableData(filteredData);
    }
  }, [rows, searchTerm]);

  // Render delete confirmation dialog
  const dialogBoxJsx = () => {
    return (
      <div inert={showDeleteDialog ? "true" : null}>
        <Dialog onClose={handleCancelDelete} open={showDeleteDialog}>
          <DialogContent>
            <Typography gutterBottom>
              Would you like to go ahead with deleting record?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleConfirmDelete}>
              Continue
            </Button>
            <Button autoFocus onClick={handleCancelDelete} variant={"outlined"}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <StarProgress style={{ marginLeft: "50%", marginTop: "5%" }} />
      ) : error.errorStatus ? (
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <GenericError errorMessage={error.errorMessage} />
          </Grid>
        </Container>
      ) : (
        <div style={{marginLeft:"40px",marginRight:"30px"}}>
          <TextField
            id="search-field"
            label="Search"
            variant="outlined"
            size="small"
            margin="normal"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{marginTop:"40px",width:"250px"}}
          />
          
          <Table size="small" style={{ border: "1px solid #CCCCCC", fontSize: "12px",marginLeft:"7px",marginRight:"20px",marginTop:"20px" }}>
            <TableHead>
              <TableRow style={{ borderBottom: "1px solid #CCCCCC" ,padding: "16px", width: "15%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor" }}>
                <TableCell style={{ borderBottom: "1px solid #CCCCCC", padding: "16px", width: "15%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor" }}>Asset Name</TableCell>
                <TableCell style={{ borderBottom: "1px solid #CCCCCC", padding: "16px", width: "15%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor" }}>Dawinci Installation Code</TableCell>
                <TableCell style={{ borderBottom: "1px solid #CCCCCC", padding: "16px", width: "15%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor" }}>Cluster</TableCell>
                <TableCell style={{ borderBottom: "1px solid #CCCCCC", padding: "16px", width: "15%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor" }}>Location</TableCell>
                <TableCell style={{ borderBottom: "1px solid #CCCCCC", padding: "16px", width: "15%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor" }}>Parent Location</TableCell>
                <TableCell style={{ borderBottom: "1px solid #CCCCCC", padding: "16px", width: "15%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor" }}>Responsible Unit</TableCell>
                <TableCell style={{ borderBottom: "1px solid #CCCCCC", padding: "16px", width: "15%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor" }}>Parent Responsible Unit</TableCell>
                <TableCell style={{ borderBottom: "1px solid #CCCCCC", padding: "16px", width: "15%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor", textAlign: "center" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {filteredTableData?.length > 0 ? (
    filteredTableData?.map((row) => (
      <TableRow key={row?.id}>
        <TableCell>{row?.assetName}</TableCell>
        <TableCell>{row?.dawinciInstallationCode}</TableCell>
        <TableCell>{row?.clusterName}</TableCell>
        <TableCell>{row?.locationName}</TableCell>
        <TableCell>{row?.parentLocationName}</TableCell>
        <TableCell>{row?.unitName}</TableCell>
        <TableCell>{row?.parentUnitName}</TableCell>
        <TableCell>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="text"> <EditTwoToneIcon style={{ color: "#007079" }} onClick={() => handleEditRow(row)} /></Button>
            <Button variant="text"> <DeleteOutlineTwoToneIcon style={{ color: "#007079" }} onClick={() => handleDeleteRow(row)} /></Button>
          </div>
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow style={{ borderBottom: "1px solid #CCCCCC" }}>
      <TableCell colSpan={8} align="center" style={{ padding: "16px", fontFamily: "equinor",color:"red" }}>
        No records found.
      </TableCell>
    </TableRow>
  )}
</TableBody>
          </Table>
     
          <Button variant="contained" color="primary" style={{padding: '10px 20px', backgroundColor: 'transparent', color: '#007079', fontWeight: 'bold', boxShadow: 'none', border: 'none'}} onClick={handleAddRow}>
            Add New Row
          </Button>
          
          <MyContext.Provider value={{ refresh, setRefresh }}>
            {rowData && (
              <PopupForm
                open={open}
                rowData={rowData}
                handleClose={handleClose}
                handleSaveRow={handleSaveRow}
                accessToken={props.accessToken}
              />
            )}
          </MyContext.Provider>

          {/* Render delete confirmation dialog */}
          {dialogBoxJsx()}
        </div>
      )}
    </React.Fragment>
  );
}

export default AssetTable;