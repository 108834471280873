import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import { CASE_TYPE } from "../../Constant.js";

export default function CaseTypeButtonGroup(props) {
  return (
    <FormControl>
      {/* <Typography>Please select the case type to be submitted</Typography>
      <RadioGroup
        row
        name="caseTypeRadioGroup"
        defaultValue={CASE_TYPE.CASE_TYPE_RESPONSIBLE_UNIT}
        onChange={props.onCaseTypeChange}
      >
        <FormControlLabel
          value={CASE_TYPE.CASE_TYPE_RESPONSIBLE_UNIT}
          control={<Radio 
            />}
          label="Responsible Unit"
        />
        <FormControlLabel
          value={CASE_TYPE.CASE_TYPE_INVOLVED_UNIT}
          control={<Radio 
            disabled={true}/>}
          label="Involved Unit"
        />
        <FormControlLabel
          value={CASE_TYPE.CASE_TYPE_BOTH}
          control={<Radio 
            disabled={true}
            />}
          label="Both"
        />
      </RadioGroup> */}
    </FormControl>
  );
}
