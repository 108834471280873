import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FormControl, InputLabel } from '@material-ui/core';
 
export function Deptdropdown(props) {
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  useEffect(() => {
    if (props.getSelectedValue) {
      props.getSelectedValue(selectedDepartment);
    }
 
  }, [selectedDepartment]);
  const [updated, setUpdated] = useState(props.data);
  const [selected, setSelected] = useState(props.finalRows);
 
useEffect(() => {
  // this function will be called whenever props.data changes
  setUpdated(props.data);
}, [props.data]); // add props.data as a dependency to the useEffect hook
 
useEffect(() => {
  // this function will be called whenever props.data changes
  setSelected(props.finalRows);

}, [props.finalRows]);
 
const handleDepartmentChange = (selectedOption) => {
  const department = { index: props.index, selectedOption };
  
  setSelectedDepartment(department);

};

 const isChecked = (selected[props.index].departments || [{department: selected[props.index].department, id: selected[props.index].id}])?.map((selectedValue) =>{
  // access departments of the element at index
 

 
  //const dept=selectedValue[props.index].departments?.map((ticket)=>console.log(ticket))
  // const departments = selected[props.index].departments || [];
   return selectedValue;
});
const filteredOptions = updated.filter((row) => {
  const isCheckedIds = isChecked?.map((checkedRow) => parseInt(checkedRow.id));
 
  return !isCheckedIds?.includes(parseInt(row.id));
});

  return (<>
<FormControl>
 
{/*
<Select
  value={isChecked[0].department}
  options={updated}
  isMulti
  onChange={handleDepartmentChange}
  closeMenuOnSelect={false}
/> */}
       
 
 
<InputLabel id="department-label">Department</InputLabel>
 
<Select
        defaultValue={isChecked?.map((row) => {
          return { value: row.id, label: row.department,synergiUnitId:selected[props.index].synergiUnitId };
        })}
        inputId="department-select"
        isMulti
       onChange={handleDepartmentChange}
       options={updated.map((row) => {
        return { value: row.id, label: row.dawinciDepartment, synergiUnitId: selected[props.index].synergiUnitId };
      })}
        placeholder="Search Department"
        components={{
          Option: CustomOption,
        }}
        closeMenuOnSelect={false}
      />
</FormControl>
</>
  );
}
 
function CustomOption(props) {
  const isSelected = Array.isArray(props.getSelectedValue) && props.getSelectedValue.some((item) => item.department);
  return (
    <div style={{ display: 'flex', alignItems: 'left' }}>
    {props.getSelectedValue}
  
    <input
      type="checkbox"
      checked={isSelected}
      onChange={() => props.selectOption(props.data)}
    />
    <label>{props.label}</label>
  </div>
  );
}