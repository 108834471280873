import React from 'react';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { Divider } from '@material-ui/core';
const MonthlySumupIU = (props) => {
    const boxStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        height: '150px',
        backgroundColor: 'transparent',
        border: '1px solid black',
        font:"Equinor"
      };
    
      const colStyle = {
        border: '1px solid black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginTop:"50px"
      };
    
      
      const useStyles = makeStyles((theme) => ({
        container: {
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
          gridGap: theme.spacing(4),
          width: '100%',
          height: 'auto',
          margin: '0 auto',
          backgroundColor: 'transparent',
          border: '1px solid #ccc',
          font: 'Equinor',
        },
        box: {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          height: '60px',
          width: '120px',
          border: '1px solid #ccc',
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
          font: 'Equinor',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        boxoutside: {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: theme.spacing(4),
          font: 'Equinor',
          width: '150px',
        },
        boxempty: {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: theme.spacing(4),
          font: 'Equinor',
        },
        text: {
          marginTop: theme.spacing(1),
          fontSize: '1rem',
          fontWeight: 'bold',
          font: 'Equinor',
        },
        '@media (max-width: 960px)': {
          container: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
            gridGap: theme.spacing(2),
          },
          box: {
            height: '50px',
            width: '100px',
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
          },
          boxoutside: {
            marginTop: theme.spacing(2),
          },
          boxempty: {
            marginTop: theme.spacing(2),
          },
          text: {
            fontSize: '0.8rem',
          },
        },
      }));
      
      const classes = useStyles();

      const getDaysInMonth = (monthName, year) => {
        const month = new Date(year, new Date(monthName + ' 1, ' + year).getMonth());
        const lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
        return lastDay.getDate();
      };
      
      // Example usage
      let daysInMonth = getDaysInMonth(props.selectedMonthIU, props.selectedYearIU);
     


 const IUdata= props.currentMonthData.filter((item) => item.synergiCaseTypeDescription === "Man Hours - Internal suppliers/Units involved");
 var sumpob=0

  var sumworkHour=0

 var sum=IUdata.map((each,idea) => {

  sumpob=sumpob+Math.trunc(each.pob)

  sumworkHour=sumworkHour+Math.trunc(each.workHour)
 })
 const monthname = ["January","February","March","April","May","June","July","August","September","October","November","December"];
 const today = new Date();
  const month = today.getMonth()+1;
  let year = today.getFullYear();
  const datee = today.getDate();
  const time=today.getHours();
  const minute=today.getMinutes();
  let downloadyear=year
let prev_month=month-1;
if(prev_month == 0)
prev_month =12
 
const pobaverage=Math.round(sumpob/daysInMonth)

let prev_month_report=month-3
if(prev_month_report == -2){prev_month_report=10
  downloadyear=year-1}

if(prev_month_report == -1)
{
prev_month_report=11
downloadyear=year-1
}
  return (    <div style={{ font: 'Equinor' }}>
  <div >Monthly Sumup</div>

  <Box className={classes.container}>
    <Box className={classes.boxempty}></Box>

    <Box className={classes.boxoutside}>
      <Box className={classes.box}>
        <div style={{ font: 'Equinor' }}>{sumpob}</div>
      </Box>
      <div style={{ marginLeft: '2px', marginTop: '10px', font: 'Equinor',fontSize:"16px" }}>Total Worked Days</div>
    </Box>

    <Box className={classes.boxempty}>
      <div style={{ fontSize: '60px' }}>/</div>
    </Box>

    <Box className={classes.boxoutside} style={{ marginRight: '70px' }}>
      <Box className={classes.box}>
        <div>{daysInMonth}</div>
      </Box>
      <div style={{ marginLeft: '2px', marginTop: '10px', font: 'Equinor',fontSize:"16px" }}>Total Calendar Days</div>
    </Box>

    <Box className={classes.boxempty}>
      <div style={{ fontSize: '80px' }}>=</div>
    </Box>

    <Box className={classes.boxoutside} style={{ marginRight: '50px' }}>
      <Box className={classes.box}>
        <div>{pobaverage}</div>
      </Box>
      <div style={{ marginLeft: '2px', marginTop: '10px', font: 'Equinor',fontSize:"16px" }}>Average Per Day</div>
    </Box>

    <Box className={classes.boxempty}>
      <Divider orientation="vertical" style={{ borderLeft: '1px dotted #CCCCCC', height: '80%' }} />
    </Box>

    <Box className={classes.boxoutside} style={{ marginLeft: '-50px' }}>
      <Box className={classes.box}>
        <div>{sumworkHour}</div>
      </Box>
      <div style={{ marginLeft: '2px', marginTop: '10px', font: 'Equinor',fontSize:"16px" }}>Total work Hours</div>
    </Box>
  </Box>
</div>
  );
}

export default MonthlySumupIU;