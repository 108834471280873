
import React, { useState, useEffect, useContext, useCallback } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, MenuItem, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { Icon, StarProgress } from "@equinor/eds-core-react";
import { arrayToChip } from "../CommonFunction";

import { snackbarContext } from "../../App";
import {
  appInsights,
  appInsightTrackEvent,
  appInsightTrackException,
} from "../Logging/Logging";
import { assetDetailsAsync, levelOneRespUnitData } from "../_axios";
import SubscriptionButton from "../Notification/SubscriptionButton";
import { tag_relations } from "@equinor/eds-icons";
import LocationOnTwoTone from "@mui/icons-material/LocationOnTwoTone";



const useStyles = makeStyles({
  assetCard: {
    width: 100,
    height: 130,
    padding: 20,
    margin: 10,
    backgroundColor: "#DFF5FF",
  },
});

const AssetInfoRow = (props) => {
  //app insight metric
  appInsights.trackPageView();

  const classes = useStyles();

  //state
  //const [asset] = useState(assetMasterData);
  const [asset, setAsset] = useState([]);
  const [cluster, setCluster] = useState([]);
  const [assetId, setAssetId] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
  });
  const[isSubscribed,setIsSubscribed]=useState()
  const [levelOneRuData, setLevelOneRuData] = useState([]);
  const [selectedasset,setSelectedasset]=useState("");
  //sending location
const[sendautopopulate,setautopoulate]=useState([]);
  //state for snackbar
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
    useContext(snackbarContext);

    const [selectedItem, setSelectedItem] = useState(
      sessionStorage.getItem('selectedCluster') || ''
    );
    const [clustername, setClustername] = useState(
      sessionStorage.getItem('selectedClustername') || ''
    );

 

  const handleSelectChange = (event) => {
    setSelectedItem(event.label);
    setClustername(event.label)
    //console.log(event.label)
  };

  const loadLevelOneRuData = useCallback(async () => {
    try {
      let responseRuData = await levelOneRespUnitData(props.accessToken);
      if (responseRuData.status === 200) {
        setLevelOneRuData(responseRuData.data.data);
      } else {
        appInsightTrackEvent(responseRuData.data);
      }
    } catch (error) {
      appInsightTrackException(error);
    }
  }, [props.accessToken]);
  useEffect(() => {
    loadLevelOneRuData();
  }, [loadLevelOneRuData]);
  const assetMetadataDetails = useCallback(async () => {
    try {
      let assetData = await assetDetailsAsync(props.accessToken, props.assetId);
      setIsSubscribed(assetData.data.data.isSubscribed)
      if (assetData.status !== 200) {
        appInsightTrackEvent(assetData.data);
      }

      // console.log(assetData.data.status);

      let _location = assetData.data.data.locationName.split("->");
      let _ru = assetData.data.data.ruName.split("->");

      props.setMetadata({
        location: _location.length > 0 ? _location : [],
        responsibleUnit: _ru.length > 0 ? _ru : [],
        locationId: assetData.data.data.locationId,
        ruId: assetData.data.data.ruId,
      });
    } catch (error) {
      appInsightTrackException(error);
    }
  }, [props.accessToken, props.assetId]);
  useEffect(() => {
    assetMetadataDetails();
  }, [assetMetadataDetails]);

  return (
    <React.Fragment
    >
      <div style={{  fontFamily:"Equinor",}}>
      <Box
  sx={{
    flexGrow: 1,
    marginLeft: "-10px",
    marginRight: "-10px",
    marginTop: "-10px",
  }}
>
  <Grid
    container // Add container to enable flexbox layout
    style={{
      backgroundColor: "#EAF4F9",
      padding: "10px",
      position: "relative",
      top: "-2px",
      width: "100%",
      height: "180px",
      boxSizing: "border-box",
      marginRight: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    
    {/* First column */}
    <Grid item xs={3} style={{padding: "60px"}}>
  Cluster
  <div style={{display: "block"}}></div>
  
  <span>
  <Icon style={{ marginRight: "5px", marginTop: "10px", color: "#007079" }} data={tag_relations} size={32} />
  </span>
  
  <span style={{ marginRight: "5px", marginBottom: "10px", color: "#007079" }} > {selectedItem}
   </span>
</Grid>


    
    {/* Second column */}
    <Grid item xs={3}>
      Location : 
      <div style={{display: "block"}}></div>
      <LocationOnTwoTone style={{ marginRight: "5px", marginTop: "15px", color: "#007079" }}/>
      <span style={{ marginRight: "5px", marginBottom: "10px", color: "#007079" }} > {props.asset[3]}
      </span>
    </Grid>
    
    {/* Third column */}
    <Grid item xs={3}>
      Responsible Unit:
      {props.metadata.responsibleUnit && arrayToChip(props.metadata.responsibleUnit)}
    </Grid>
    
    {/* Fourth column */}
    <Grid item xs={3}>
    

     <SubscriptionButton
          accessToken={props.accessToken}
          assetId={props.assetId}
          isSubscribed={isSubscribed}
          />
       
    
    </Grid>
    
  </Grid>
</Box>
            </div>
    </React.Fragment>
  );
};

export default AssetInfoRow

