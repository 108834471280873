import React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import {  useLocation } from 'react-router-dom';
import { useEffect,useState } from 'react';
import FontFaceObserver from "fontfaceobserver";

const Header = ({ isHomePage, role }) => {
  const navigate = useNavigate();

  const handleButtonClick = (value) => {
    if (value === "home") {
      navigate("/");
    } else if (value === "manHour") {
      navigate("/manHour");
    } else if (value === "userManual") {
      navigate("/userManual");
    } else if (value === "info") {
      navigate("/info");
    } else {
      navigate("/error");
    }
  };
  const { pathname } = useLocation();
  const isUserManualPage = pathname === '/Usermanual';
  const isInfo = pathname === '/info';
  const isadmin = pathname === '/assettagging';
  const isauditlog = pathname === '/auditlog';
  const isassetadd = pathname === '/assetadd';
  const iscompany = pathname === '/globalcompamy';
  const isrequest = pathname === '/requestlist';

  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const font = new FontFaceObserver("Equinor");

    font.load().then(() => {
      setFontLoaded(true);
    });
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-10px",
      }}
    >
      <Grid
        container
        sx={{
          bgcolor: "#D5EAF4",
          padding: "20px 40px !important",
          width: "100%",
          height: "100px !important",
          fontFamily: "Equinor !important",
        }}
        alignItems="center !important"
      >
        <Grid item xs={2}>
          <Typography
            component="div"
            sx={{
              display: "flex !important",
              alignItems: "center !important",
              color: "red !important",
              marginLeft: "20px !important",
              fontSize: "30px !important",
              fontFamily: "Equinor !important",
            }}
          >
            Work Hours
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Link
            href="/"
            // underline={isHomePage ? 'always' : 'hover'}
            color={isHomePage ? "#FF0000" : "inherit"}
            sx={{
              fontSize: "18px",
              color: "black",
              marginLeft: "30px !important",
              textDecoration: "none", // Remove the default underline
              borderBottom: isHomePage
                ? "3px solid #FF0000"
                : "3px solid transparent", // Adjust underline properties
              paddingBottom: "4px", // Push the underline below the text
            }}
          >
            Home
          </Link>
          {role?.includes("Admin") && (
            <>
              <Link
                href="/assettagging"
                // underline={isadmin ? 'always' : 'hover'}
                color={isadmin ? "#FF0000" : "inherit"}
                sx={{
                  fontSize: "18px",
                  fontFamily: "Equinor",
                  color: "black",
                  marginLeft: "30px !important",
                  textDecoration: "none", // Remove the default underline
                  borderBottom: isadmin
                    ? "3px solid #FF0000"
                    : "3px solid transparent", // Adjust underline properties
                  paddingBottom: "4px", // Push the underline below the text
                }}
              >
                Asset Tagging
              </Link>

              <Link
                href="/auditlog"
                // underline={isauditlog ? 'always' : 'hover'}
                color={isauditlog ? "#FF0000" : "inherit"}
                sx={{
                  fontSize: "18px",
                  fontFamily: "Equinor",
                  color: "black",
                  marginLeft: "30px !important",
                  textDecoration: "none", // Remove the default underline
                  borderBottom: isauditlog
                    ? "3px solid #FF0000"
                    : "3px solid transparent", // Adjust underline properties
                  paddingBottom: "4px", // Push the underline below the text
                }}
              >
                Audit Log
              </Link>

              <Link
                href="/assetadd"
                // underline={isassetadd ? 'always' : 'hover'}
                color={isassetadd ? "#FF0000" : "inherit"}
                sx={{
                  fontSize: "18px",
                  fontFamily: "Equinor",
                  color: "black",
                  marginLeft: "30px !important",
                  textDecoration: "none", // Remove the default underline
                  borderBottom: isassetadd
                    ? "3px solid #FF0000"
                    : "3px solid transparent", // Adjust underline properties
                  paddingBottom: "4px", // Push the underline below the text
                }}
              >
                Asset
              </Link>

              <Link
                href="/globalcompamy"
                // underline={isassetadd ? 'always' : 'hover'}
                color={iscompany ? "#FF0000" : "inherit"}
                sx={{
                  fontSize: "18px",
                  fontFamily: "Equinor",
                  color: "black",
                  marginLeft: "30px !important",
                  textDecoration: "none", // Remove the default underline
                  borderBottom: iscompany
                    ? "3px solid #FF0000"
                    : "3px solid transparent", // Adjust underline properties
                  paddingBottom: "4px", // Push the underline below the text
                }}
              >
                Company
              </Link>

              <Link
                href="/requestlist"
                // underline={isassetadd ? 'always' : 'hover'}
                color={isrequest ? "#FF0000" : "inherit"}
                sx={{
                  fontSize: "18px",
                  fontFamily: "Equinor",
                  color: "black",
                  marginLeft: "30px !important",
                  textDecoration: "none", // Remove the default underline
                  borderBottom: isrequest
                    ? "3px solid #FF0000"
                    : "3px solid transparent", // Adjust underline properties
                  paddingBottom: "4px", // Push the underline below the text
                }}
              >
                Request
              </Link>
            </>
          )}
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "right" }}>
          <Link
            href="/info"
            underline={isInfo ? "always" : "hover"}
            color={isUserManualPage ? "#FF0000" : "inherit"}
            sx={{
              fontSize: "17px",
              color: "black",
              marginRight: "10px!important",
              textDecoration: "none", // Remove the default underline
              borderBottom: isInfo
                ? "3px solid #FF0000"
                : "3px solid transparent", // Adjust underline properties
              paddingBottom: "2px", // Push the underline below the text
            }}
          >
            FAQ
          </Link>
          &nbsp;|&nbsp;
          <Link
            href="/Usermanual"
            underline={isUserManualPage ? "always" : "hover"}
            color={isUserManualPage ? "#FF0000" : "inherit"}
            sx={{
              fontSize: "17px",
              color: "black",
              marginLeft: "10px !important",
              textDecoration: "none", // Remove the default underline
              borderBottom: isUserManualPage
                ? "3px solid #FF0000"
                : "3px solid transparent", // Adjust underline properties
              paddingBottom: "2px", // Push the underline below the text
            }}
          >
            User Manual
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;