import React, { useState, createContext } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { Grid } from "@mui/material";
import Feedback from "./Components/Feedback/Feedback";
import ManHour from "./Components/ManHour/ManHour";
import AssetAdminPage from "./Components/AssetAdminPage";
import MappingTab from "./Components/Mapping/MappingTab";
import withAuth from "./Components/Authentication/withAuth";
import UserManual from "./Components/UserManual/UserManual";
import GenericError from "./Components/GenericError/GenericError";
import Info from "./Components/UserManual/Info";
import Header from "./Components/HeaderBar/HeaderBar copy";
import LocationTagging from "./Components/Admin/LocationTagging";
import AuditLog from "./Components/Admin/AuditLog";
import TableWithPopupForm from "./Components/Admin/AssetTable";
import AssetTable from "./Components/Admin/AssetTable";
import GlobalCompany from "./Components/Admin/Company/GlobalCompany";
import ParentCompany from "./Components/Admin/Company/ParentCompany";
import CompanyRequest from "./Components/Admin/Request/companyrequest";

export const snackbarContext = createContext();
export const globalVariable = createContext();
function App(props) {
  //state for snackbar
  const [shouldOpen, setShouldOpen] = useState(false);
  const [apiResponseStatus, setApiResponseStatus] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [userwarningcompany, setUserWarningComapny] = useState(false);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [isEditModeOn_ru, setIsEditModeOn_ru] = useState(false);
  const [isEditModeOn_iu, setIsEditModeOn_iu] = useState(false);
  const [roles, setRoles] = useState(props.roles);
  //for snackbar closing
  const handleClose = () => {
    setShouldOpen(false);
  };
  const HeaderConatiner=()=>{
    const location=useLocation();
    const isHomePage =location.pathname ==='/';
    return <Header 
    isHomePage={isHomePage}
    role={roles}/>
  }
  
  return (
    <React.Fragment>
      <snackbarContext.Provider
        value={{
          _setShouldOpen: setShouldOpen,
          _setApiResponseStatus: setApiResponseStatus,
          _setSnackbarMessage: setSnackbarMessage,
        }}
      >
        <globalVariable.Provider
        value={{
          setUserWarningComapny:setUserWarningComapny,
          _setIsEditModeOn:setIsEditModeOn,
          _setIsEditModeOn_ru:setIsEditModeOn_ru,
          _setIsEditModeOn_iu:setIsEditModeOn_iu,

        }}
      >
             
          <Feedback
            open={shouldOpen}
            message={snackbarMessage}
            status={apiResponseStatus}
            handleClose={handleClose}
          />
    
        <BrowserRouter>
          <HeaderConatiner
         ></HeaderConatiner>
          <Routes>
            <Route
              path="/"
              element={
                <AssetAdminPage
                  user={props.user}
                  roles={props.roles}
                  accessToken={props.accessToken}
                />
              }
            ></Route>
            <Route
              path="/mappingTab"
              element={<MappingTab accessToken={props.accessToken}
              userwarningcompany={userwarningcompany}
              isEditModeOn={isEditModeOn}
              isEditModeOn_ru={isEditModeOn_ru} 
              isEditModeOn_iu={isEditModeOn_iu} 

              />}
            ></Route>
            {/* <Route
              path="/manHour"
              element={<ManHour accessToken={props.accessToken} />}
            ></Route> */}
            <Route path="/userManual" element={<UserManual />}></Route>
            <Route path="/info" element={<Info/>}></Route>
            <Route path="/assettagging" element={<LocationTagging accessToken={props.accessToken}/>}></Route>
            <Route path="/auditlog" element={<AuditLog accessToken={props.accessToken}/>}></Route>
            <Route path="/assetadd" element={<AssetTable accessToken={props.accessToken}/>}></Route>
            <Route path="/globalcompamy" element={<ParentCompany accessToken={props.accessToken} isEditModeOn={isEditModeOn}/>}></Route>
            <Route path="/requestlist" element={<CompanyRequest accessToken={props.accessToken}/>}></Route>
           
            <Route
              path="/error"
              element={
                <GenericError errorMessage="Error !!! Page doesnt exist." />
              }
            ></Route>

          </Routes>
          {/* <div style={{ position: "relative", maxWidth: "20%",marginTop:"3%" }}>
  <img
    src="https://cdn.eds.equinor.com/logo/equinor-logo-primary.svg#red"
    alt="Equinor"
    style={{ position: "absolute", marginLeft: "30px", width: "35%", height: "auto" }}
  />
</div> */}
        </BrowserRouter> 
        </globalVariable.Provider>
      </snackbarContext.Provider>
    </React.Fragment>
  );
}

export default withAuth(App);
