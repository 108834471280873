import React from "react";
import { Button, Grid } from "@mui/material";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";

import GlobalCompany from "./GlobalCompany";
import { IDENTIFIER } from "../../Constant";

const GlobalCompanyMappingComponent = (props) => {
  return (
    <React.Fragment>
        <GlobalCompany
            data={props.data}
            searchText={props.searchText}
            synergiCompanyList={props.synergiCompanyList}
            handleTableRowDelete={props.handleTableRowDelete}
            textChange={props.textChange}
            isEditModeOn={props.isEditModeOn}
            isEditModeOn_iu={props.isEditModeOn_iu}
          />
        {/* <Grid item xs={12} textAlign="center">
          <Button variant="text" onClick={() => props.handleRowAddition(IDENTIFIER.COMPANY)}>
            <AddBoxTwoToneIcon />
          </Button>
        </Grid> */}
    </React.Fragment>
  );
};

export default GlobalCompanyMappingComponent;
