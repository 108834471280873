import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import ImageRenderer from "./ImageRenderer";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Typography } from "@equinor/eds-core-react";
import About from "../../Documentation/About.md";
import Home from "../../Documentation/Home.md";

class UserManual extends Component {
  constructor(props) {
    super(props);

    this.state = { userManual: null };
  }

  componentDidMount() {
    fetch(Home)
      .then((response) => response.text())
      .then((text) => {
        this.aboutText = text;
        this.setState({ userManual: this.aboutText });
      })
      .catch((error) => {
       // console.log(error);
      });

    fetch(Home)
      .then((response) => response.text())
      .then((text) => {
        this.homeText = text;
      })
      .catch((error) => {
       // console.log(error);
      });
  }

  // handleClick = (selection) => {
  //   if (selection === "about") this.setState({ userManual: this.aboutText });
  //   if (selection === "home") this.setState({ userManual: this.homeText });
  // };

  imageURIchange = (input) => {
    return input.replace("../../public/", process.env.PUBLIC_URL);
  };

  render() {
    return (
      <React.Fragment>
        <Box m={2}>
          {/* <Box m={2}>
            <Grid container>
              <Typography >
                Select the relevant sections of user manual
              </Typography>
            </Grid>
          </Box> */}
          <Box m={2}>
            <Grid container>
              {/* <Grid item xs>
                <Button onClick={() => this.handleClick("about")}>About & FAQs</Button>
              </Grid> */}
              <Grid item xs>
                <Button >
                 User Manual
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box m={5}>
          <Paper elevation={7}>
            <Box m={3}>
              <Typography style={{fontFamily:"Equinor"}}>
              <ReactMarkdown
                children={this.state.userManual}
                components={{ image: ImageRenderer }}
                transformImageUri={this.imageURIchange}
                
              />
              </Typography>
            </Box>
          </Paper>
        </Box>
      </React.Fragment>
    );
  }
}

export default UserManual;
