import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button, Tooltip ,TableSortLabel,SortDirection } from "@mui/material";
import DropDown from "../../DropDown/DropDown";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import { NUMERAL, IDENTIFIER } from "../../Constant";
import { arrayToChip } from "../../CommonFunction";
import SearchAndDropdown from "../../SearchAndDropdown/SearchAndDropdown";
import moment from "moment";
import DaWinciScreenB from "../DaWinciScreenB";
import AssetAdminPage from "../../AssetAdminPage";
import DataGridInvolvedUnit from "./DataGridInvolvedUnit";
function AssetMetadataMappingTableIU(props) {
  const [selectedIndex, setSelectedIndex] = useState();
  const [isRuEditModeOn, setIsRuEditModeOn] = useState(false);
  const [isIuEditModeOn, setIsIuEditModeOn] = useState(false);
  const [isSynEditModeOn, setIsSynEditModeOn] = useState(false);
const[direction,setDirection]=useState("asc");
const[sortedvalue,setSortedvalue]=useState([]);
  const handleRuSelection = (selection, identifier, selectionId) => {
    props.handleSelection(
      selection,
      selectedIndex,
      selectionId,
      IDENTIFIER.DEPARTMENTRU
    );
  };

  const handleIuSelection = (selection, identifier, selectionId) => {
    props.handleSelection(
      selection,
      selectedIndex,
      selectionId,
      IDENTIFIER.DEPARTMENTIU
    );
  };

  const handleCompanySelection = (event, child) => {
    props.handleSelection(
      child.props.children,
      selectedIndex,
      child.props.value,
      IDENTIFIER.COMPANY
    );
  };
// const sortTable=()=>{
//   const sort=props.currentMonthData.sort((a,b)=>direction === "asc"? a-b : b-a)
//   sortedvalue(sort)
//   setDirection("dsc")
// }

  const setEditStatus = (status, index, identifier) => {
    if (identifier === IDENTIFIER.COMPANY) {
      setIsSynEditModeOn(status);
    }

    if (identifier === IDENTIFIER.DEPARTMENTRU) {
      setIsRuEditModeOn(status);
    }

    if (identifier === IDENTIFIER.DEPARTMENTIU) {
      setIsIuEditModeOn(status);
    }

    setSelectedIndex(index);
  };
  
  const tablemonth = (moment().subtract(1, "month").format("MMMM"))
  let tableyear=(moment().format("YYYY"))
  if(tablemonth == "December")
  tableyear=tableyear-1
  const ruButtonGroup = (index, value) => {
    return (
      <Button variant="text">
        {isRuEditModeOn && index === selectedIndex ? (
          <Tooltip title="OK" placement="top">
            <CheckCircleTwoToneIcon
              onClick={() =>
                setEditStatus(false, index, IDENTIFIER.DEPARTMENTRU)
              }
            />
          </Tooltip>
        ) : value.length !== NUMERAL.ZERO ? (
          <Tooltip title="Edit" placement="top">
            <EditTwoToneIcon
              onClick={() =>
                setEditStatus(true, index, IDENTIFIER.DEPARTMENTRU)
              }
            />
          </Tooltip>
        ) : (
          <Tooltip title="Add" placement="top">
            <AddBoxTwoToneIcon
              onClick={() =>
                setEditStatus(true, index, IDENTIFIER.DEPARTMENTRU)
              }
            />
          </Tooltip>
        )}
      </Button>
    );
  };
    const iuButtonGroup = (index, value) => {
    return (
      <Button variant="text">
        {isIuEditModeOn && index === selectedIndex ? (
          <Tooltip title="OK" placement="top">
            <CheckCircleTwoToneIcon
              onClick={() =>
                setEditStatus(false, index, IDENTIFIER.DEPARTMENTIU)
              }
            />
          </Tooltip>
        ) : value?.length !== NUMERAL.ZERO ? (
          <Tooltip title="Edit" placement="top">
            <EditTwoToneIcon
              onClick={() =>
                setEditStatus(true, index, IDENTIFIER.DEPARTMENTIU)
              }
            />
          </Tooltip>
        ) : (
          <Tooltip title="Add" placement="top">
            <AddBoxTwoToneIcon
              onClick={() =>
                setEditStatus(true, index, IDENTIFIER.DEPARTMENTIU)
              }
            />
          </Tooltip>
        )}
      </Button>
    );
  };

  const companyButtonGroup = (index, value) => {
    return (
      <Button variant="text">
        {isSynEditModeOn && index === selectedIndex ? (
          <Tooltip title="OK" placement="top">
            <CheckCircleTwoToneIcon
              onClick={() => setEditStatus(false, index, IDENTIFIER.COMPANY)}
            />
          </Tooltip>
        ) : value !== null ? (
          <Tooltip title="Edit" placement="top">
            <EditTwoToneIcon
              onClick={() => setEditStatus(true, index, IDENTIFIER.COMPANY)}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Add" placement="top">
            <AddBoxTwoToneIcon
              onClick={() => setEditStatus(true, index, IDENTIFIER.COMPANY)}
            />
          </Tooltip>
        )}
      </Button>
    );
  };

  const previousMonth = () => {
    return (<React.Fragment>
      {/* <Typography color={"red"} variant="caption">
        * Table shows data for the month of{" "}
      {tablemonth} {tableyear}
      </Typography> */}
     </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent={"space-between"}
       
        marginLeft={"5px"}
        marginRight={"30px"}
      >
        <Grid item xs={4}>
          {previousMonth()}
        </Grid>
        {/* {props.caseId === NUMERAL.DEFAULT_CASE_ID ? 
        ( <Grid item xs={2.8}>
          <Typography color={"red"} variant="caption">
       Note:Please fill all the mapping data to enable submit button
     </Typography>
        </Grid>)
        :( <Grid item xs={2}>
        </Grid>) } */}
      </Grid>
     
        <Table
          sx={{  marginTop: "5px" }}
          aria-label="simple table"
        
        >
          <TableHead>
            <TableRow
              style={{
                borderWidth: "1px",
                borderStyle: "double",
                borderColor: "black",
              }}
            >
              {/* <TableCell
                align="center"
               
                sx={{ width:"370px", border: 1 }}
              
              > */}
                {/* <TableSortLabel
                active
                direction="dsc"



                ></TableSortLabel> */}
                {/* Company
              </TableCell> */}
              {/* <TableCell
                align="center"
                
                style={{ fontWeight: "bold" }}
              >
                Department - Responsible Unit
              </TableCell> */}
            </TableRow>
            <TableRow
              style={{
                borderWidth: "1px",
                borderBottomStyle: "double",
                borderTopStyle: "double",
                borderColor: "black",
              }}
            >
              {/* <TableCell
                align="center"
                style={{
                  borderWidth: "1px",
                  borderRightStyle: "groove",
                  borderLeftStyle: "solid",
                }}
              >
                DaWinci  Company
              </TableCell>
              <TableCell
                align="center"
                style={{ borderWidth: "1px", borderRightStyle: "solid" }}
              >
                Synergi Company
              </TableCell>
              <TableCell
                align="center"
                style={{ borderWidth: "1px", borderRightStyle: "groove" }}
              >
                DaWinci  Department
              </TableCell>
              <TableCell
                align="center"
                style={{ borderWidth: "1px", borderRightStyle: "groove" }}
              >
                Synergi Responsible Unit
              </TableCell> */}
              {/* <TableCell
                align="center"
                style={{ borderWidth: "1px", borderRightStyle: "groove" }}
              >
                {" "}
                Synergi Involved Unit
              </TableCell> */}
              {/* <TableCell
                align="center"
                style={{ borderWidth: "1px", borderRightStyle: "groove" }}
              >
                Days worked
              </TableCell>
              <TableCell
                align="center"
                style={{ borderWidth: "1px", borderRightStyle: "solid" }}
              >
                Work hours
              </TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.currentMonthData.map((row, index) => (
              <TableRow
                key={row.guid}
                sx={{
                  "&:last-child td, &:last-child th": { borderBottom: 0 },
                  backgroundColor:
                    row.synergiUnit.length === 0 ||
                    row.synergiCompany === null
                      ? "antiqueWhite"
                      : "white",
                }}
              >
              
                {/* <TableCell
                  align="center"
                  width={"5%"}
                  style={{
                    borderWidth: "1px",
                    borderRightStyle: "groove",
                    borderLeftStyle: "solid",
                  }}
                >
                  {row.daWinciCompany}
                </TableCell>
                <TableCell
                  align="center"
                  width="10%"
                  style={{ borderWidth: "1px", borderRightStyle: "solid" }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={props.caseId === NUMERAL.DEFAULT_CASE_ID ? 8 : 12}
                      alignSelf="center"
                    >
                      {isSynEditModeOn && index === selectedIndex ? (
                        // ? companyDropdown(row.synergiCompanyId)
                        <SearchAndDropdown
                          searchText={props.searchText}
                          textChange={props.textChange}
                          synergiCompanyList={props.synergiCompanyList}
                          handleSelection={handleCompanySelection}
                          //value={row.synCompanyId}
                        />
                      ) : (
                        row.synergiCompany
                      )}
                    </Grid>
                    {props.caseId === NUMERAL.DEFAULT_CASE_ID ? (
                      <Grid container spacing={1} item xs={4}>
                        <Grid item>
                          {companyButtonGroup(index, row.synergiCompany)}
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </TableCell> */}
                {/* <TableCell
                  align="center"
                  width={"5%"}
                  style={{
                    borderWidth: "1px",
                    borderRightStyle: "groove",
                    borderLeftStyle: "solid",
                  }}
                >
                 {row.daWinciDepartment}
                </TableCell> */}
                {/* <TableCell
                  align="right"
                  width={"10%"}
                  style={{ borderWidth: "1px", borderRightStyle: "groove" }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={props.caseId === NUMERAL.DEFAULT_CASE_ID ? 8 : 12}
                      alignSelf="center"
                    >
                      <Grid container spacing={1} justifyContent="center">
                        {row.synergiResponsibleUnit && arrayToChip(row.synergiResponsibleUnit)}
                      </Grid>
                    </Grid>
                    {props.caseId === NUMERAL.DEFAULT_CASE_ID ? (
                      <Grid container spacing={1} item xs={4}>
                        <Grid item xs={3}>
                          {isRuEditModeOn && index === selectedIndex ? (
                            <DropDown
                              levelOneData={props.levelOneRuData}
                              ChildLevelData={props.childLevelsForRu}
                              assetId={props.assetId}
                              accessToken={props.accessToken}
                              buttonName={"Select Ru"}
                              identifier={"respUnit"}
                              assignMetadata={handleRuSelection}
                              showIcon={true}
                            />
                          ) : null}
                        </Grid>
                        <Grid item xs={3}>
                          {ruButtonGroup(index, row.synergiResponsibleUnit)}
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </TableCell> */}
                
                {/* <TableCell
                  align="right"
                  width={"10%"}
                  style={{ borderWidth: "1px", borderRightStyle: "groove" }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={props.caseId === NUMERAL.DEFAULT_CASE_ID ? 8 : 12}
                      alignSelf="center"
                    >
                     
                      <Grid container spacing={1} justifyContent="center">
                 
                        {row.synInvolvedUnit && arrayToChip(row.synInvolvedUnit)}
                      </Grid>
                     
                    </Grid>
                    {props.caseId === NUMERAL.DEFAULT_CASE_ID ? (
                      <Grid container spacing={1} item xs={4}>
                        <Grid item xs={3}>
                          {isIuEditModeOn && index === selectedIndex ? (
                            <DropDown
                              levelOneData={props.levelOneRuData}
                              ChildLevelData={props.childLevelsForRu}
                              assetId={props.assetId}
                              accessToken={props.accessToken}
                              buttonName={"Select Iu"}
                              identifier={IDENTIFIER.DEPARTMENTIU}
                              assignMetadata={handleIuSelection}
                              showIcon={true}
                            />
                          ) : null}
                        </Grid>
                        <Grid item xs={3}>
                          {iuButtonGroup(index, row.synInvolvedUnit)}
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </TableCell> */}

                
                {/* <TableCell
                  align="center"
                  width={"3%"}
                  style={{ borderWidth: "1px", borderRightStyle: "groove" }}
                >
                  {row.pob}
                </TableCell>
                <TableCell
                  align="center"
                  width={"3%"}
                  style={{ borderWidth: "1px", borderRightStyle: "solid" }}
                >
                  {row.workHour}
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      
      <DataGridInvolvedUnit
       data={props.currentMonthData}
       x={props.assetCode}
       accessToken={props.accessToken}></DataGridInvolvedUnit>
       <p>
    Note,if needed other Dawinci department could be mapped under the tab
    "Dawinci-Synergi mapping".
  </p>
    </React.Fragment>
  );
}

export default AssetMetadataMappingTableIU;
