import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { appInsightTrackEvent, appInsightTrackException } from '../Logging/Logging';
import { audit } from '../_axios';
import { snackbarContext } from '../../App';
import { Button, ButtonBase, Container, Grid, TextField } from '@mui/material';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { StarProgress } from '@equinor/eds-core-react';
import GenericError from '../GenericError/GenericError';

const useStyles = makeStyles({
  root: {
    width: '100%',
   
  },
  container: {
    maxHeight: 440,
  },
});

function AuditLog(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
  });
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
  useContext(snackbarContext);
  const columns = [
    { id: 'name', label: 'Entity Name', minWidth: 100 },
    { id: 'Column Name', label: 'Column Name', minWidth: 100 },
    { id: 'phone', label: 'Old Value', minWidth: 100 },
    { id: 'city', label: 'New Value', minWidth: 100 },
    { id: 'state', label: 'Modified By', minWidth: 100 },
    { id: 'country', label: 'Modified Date', minWidth: 100 },
  ];

  const [rows, setRows] = useState([]);

  const getaudit = async (pageNumber, rowsPerPage,searchQuery) => {
    try {
      const response = await audit(props.accessToken, pageNumber, rowsPerPage,searchQuery);
      setIsLoading(false);
      if (response.status === 200) {
        const updatedTableData = response.data.map((item) => ({
          id: item.userId,
          name: item.userId,
          location: item.allocatedAssetsList,
          editing: false,
        }));
        setRows(response.data);
        setError({ errorStatus: false, errorMessage: "" });
      } else if (response.status === 403) {
        appInsightTrackEvent("You are not Authorized to Access this Application");
        _setSnackbarMessage("You are not Authorized to Access this Application");
        _setShouldOpen(true);
        setIsLoading(false);
        setError({
          errorStatus: true,
          errorMessage: "You are not Authorized to Access this Application",
        });
      } else if (response.status === 500) {
        appInsightTrackEvent("The site seems to be unavailable, please try after some time.");
        _setSnackbarMessage("The site seems to be unavailable, please try after some time.");
        _setShouldOpen(true);
        setIsLoading(false);
        setError({
          errorStatus: true,
          errorMessage: "The site seems to be unavailable, please try after some time.",
        });
      } else {
        appInsightTrackEvent(response.data === "" ? "Something went wrong,Please try again" : response.data);
        _setShouldOpen(true);
        _setSnackbarMessage(response.data === "" ? "Something went wrong,Please try again" : response.data)
        setIsLoading(false);
        setError({
          errorStatus: true,
          errorMessage: (response.data === "" ? "Something went wrong,Please try again": response.data),
        });
      }
    } catch (error) {
      setIsLoading(false);
      setError({
        errorStatus: true,
        errorMessage: "Something went wrong,Please try again"
      });
      appInsightTrackException(error);
    }
  };

  useEffect(() => {
    getaudit(page + 1, rowsPerPage); // call getaudit with initial values of page and rowsPerPage
  }, [props.accessToken]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getaudit(newPage + 1, rowsPerPage); // call getaudit with new values of page and rowsPerPage
  };
    const [selectedDate, setSelectedDate] = useState(null);
    const [searchText, setSearchText] = useState("");
    const handleDateChange = (date) => {
      setSelectedDate(date);
      setSearchText(date)
    };
  
    const handleSearch=()=>{
      setPage(0);
    getaudit(1, rowsPerPage, searchText); // call getaudit with page 1, rowsPerPage, and searchText


    }
  return (
    <React.Fragment>

    <div style={{marginRight:"100px"}}>
      <TextField label="Search by entity name or column Name or modified by " variant="outlined" onChange={(event) => setSearchText(event.target.value)} style={{  marginTop: "20px", width: "25%",marginLeft:"55px" }} />
    
<div style={{display:"inline-block",marginLeft:"15px",paddingTop:"40px",fontFamily:"Equinor"}}>  OR </div>
   
      <MuiThemeProvider style={{ marginTop: "20px", backgroundColor:"#E6FAEC", display: "inline-block"}}>
        
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
  <DatePicker
  autoOk
  variant="inline"
  inputVariant="outlined"
  label="Select a date"
  value={selectedDate}
  onChange={handleDateChange}
  style={{ marginTop: "20px", width: "25%", marginLeft: "20px" }}
  
/>

  </MuiPickersUtilsProvider>
  <style>
    {`.MuiPickersToolbar-toolbar {
      background-color: #007079;
    }`}
  </style>
</MuiThemeProvider>
    <Button variant="contained" onClick={handleSearch} style={{width:"150px",height:"50px",backgroundColor: '#007079',color:"#fff",marginLeft:"30px",fontSize:"18px",marginTop:"-5px"}} >
                      Search
                    </Button>
                    {isLoading ? (
            <StarProgress style={{ marginLeft: "50%", marginTop: "5%" }} />
          ) : error.errorStatus ? (
            <Container maxWidth="lg">
              <Grid container spacing={1}>
                <GenericError errorMessage={error.errorMessage} />
              </Grid>
            </Container>
          ) : (<>     
        <TableContainer style={{marginTop:"50px",marginLeft:"55px"}}>
          <Table stickyHeader style={{border: "1px solid #CCCCCC" }}>
            <TableHead >
            <TableRow >
                {columns.map(column => (
                  <TableCell key={column.id} align="left" style={{ minWidth: column.minWidth ,backgroundColor:"#E6FAEC",fontFamily:"Equinor",fontWeight:"bold"}}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.entityName}
                  </TableCell>
                  <TableCell align="left">{row.columnName}</TableCell>
                  <TableCell align="left">{row.oldValue}</TableCell>
                  <TableCell align="left">{row.newValue}</TableCell>
                  <TableCell align="left">{row.modifiedBy}</TableCell>
                  <TableCell align="left">{row.modifiedDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          
          </Table>
        </TableContainer>
        <TablePagination
  rowsPerPageOptions={rows.length > 24 ? [25] : []}
  component="div"
  count={rows.length > 24 ? -1 : rows.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  style={{ marginRight: '-72px' }}
/>
    </>)}
    
    </div>
      
    </React.Fragment>
  );
}

export default AuditLog;