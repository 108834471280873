import React from 'react'

function errorHandler(setError,error) {
    setError({
        errorStatus: true,
        errorMessage: error.message,
      });

}

export default errorHandler