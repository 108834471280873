import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Tab,
} from "@mui/material";
import { CircularProgress, StarProgress } from "@equinor/eds-core-react";
import AssetMetadataMappingTable from "./AssetMetadataMappingTable/AssetMetadataMappingTable";
import {
  daWinciSynergiReferenceSave,
  submitCaseToSynergi,
} from "../_axios";
import _ from "lodash";
import { arrayToChip } from "../CommonFunction";
import { IDENTIFIER, API_RESPONSE_STATUS, NUMERAL,DESCRIPTION } from "../Constant";
import { snackbarContext } from "../../App";
import useInterval from "../useInterval";
import {
  appInsightTrackEvent,
  appInsightTrackException,
} from "../Logging/Logging";
import CaseTypeButtonGroup from "./CaseTypeButtonGroup/CaseTypeButtonGroup.js";
import Container from "@mui/material/Container";
import GenericError from "../GenericError/GenericError";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import LocationCityTwoToneIcon from "@mui/icons-material/LocationCityTwoTone";
import Tooltip from '@mui/material/Tooltip';
import { TextArea } from '@react-ui-org/react-ui';
import TabList from "@mui/lab/TabList/TabList";
import TabContext from "@mui/lab/TabContext/TabContext";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import AssetMetadataMappingTableIU from "./AssetMetadataMappingTable/AssetMetadataMappingTableIU";
import { daWinciSynergiPreviousMonthWorkHours } from "../_axios";
import { utils, writeFileXLSX } from "xlsx";
import moment from "moment";
import MonthDropdown from "./YearDropDown.js";
import MonthlySumup from "./MonthlySumup.js";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { BiExport } from "react-icons/bi";
import MonthDropdownIU from "./YearDropDownIU.js";
import MonthlySumupIU from "./MonthlySumupIU.js";

const DaWinciScreenB = (props) => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [currentMonthWorkHourData, setCurrentMonthWorkHourData] = useState([]);
  const [currentMonthWorkHourDataIU, setCurrentMonthWorkHourDataIU] = useState([]);
  const [tableRefreshFlag, setTableRefreshFlag] = useState(false);
  const [submitToSynergiFlag, setSubmitToSynergiFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [caseId, setCaseId] = useState("");
  const [caseIdIU, setCaseIdIU] = useState("");
  const [open, setOpen] = useState(false);
  const [caseType, setCaseType] = useState("ru");
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
  });
  const [tabValue, setTabValue] = useState("1");
  const [tabResponse, setTabResponse] = useState("RU");
  const [postContent, setPostContent] = useState('');
  const [postContentIU, setPostContentIU] = useState('');
  //state for snackbar
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
    useContext(snackbarContext);

  //for dialog box
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonthIU, setSelectedMonthIU] = useState('');
  const [selectedYearIU, setSelectedYearIU] = useState('');
  const handleMonthYearChange = (month, year) => {
    setSelectedMonth(month);
    setSelectedYear(year);
  
  };
  const handleMonthYearChangeIU = (month, year) => {
    setSelectedMonthIU(month);
    setSelectedYearIU(year);

  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    setOpen(false);
    handleSubmitToSynergi();
  };
  const handleConfirmIU = () => {
    setOpen(false);
    handleSubmitToSynergiIU();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  //use effects
  useEffect(() => {
    const getCurrentMonthWorkHour = async () => {
      try {
        
        let response = await daWinciSynergiPreviousMonthWorkHours(
          props.accessToken,
          props.assetCode,
          selectedMonth,
          selectedYear


        );
     
        //handle error case notifications
        if (response.status !== 200 ) {
          appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)

          _setShouldOpen(true);
          if (response.status === 204) {
            _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
            _setSnackbarMessage(response.data);
            setIsLoading(false);
        setError({
          errorStatus: true,
          errorMessage:  response.data
        });
          } else {
            setIsLoading(false);
        setError({
          errorStatus: true,
          errorMessage: response.data
        });
            _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
            _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data)

          }
       return
        }

        if (response.status == 200 ) 
        {
          setError({
            errorStatus: false,
           
          });
        response.data.data.forEach((element, index) => {
          element["guid"] = index;
          element["ruAction"] = null;
          element["iuAction"] = null;
          element["companyAction"] = null;

        
        });

    
     
      // Set the state for Responsible Unit
     setCurrentMonthWorkHourData((prevData) =>{
     const newData = response.data.data.filter(
       (item) => item.synergiCaseTypeDescription === DESCRIPTION.RESPONSIBLE_UNIT)
      
       setCaseId(newData[0].synergiCaseId);
       return newData;
     });
        setIsLoading(false);
      
      }
    }
       catch (error) {
        appInsightTrackException(error);
      }
    };
   

    getCurrentMonthWorkHour();
  }, [
    props.accessToken,
    props.assetCode,
    tabValue,
    tableRefreshFlag,
    submitToSynergiFlag,
    selectedMonth,
    selectedYear
  


  ]);
  //use effects
  useEffect(() => {
    const getCurrentMonthWorkHour = async () => {
      try {
        let response = await daWinciSynergiPreviousMonthWorkHours(
          props.accessToken,
          props.assetCode,
          selectedMonthIU,
          selectedYearIU


        );
     
       if(response.status == 200)
       {
   // Set the state for Internal Unit
   setCurrentMonthWorkHourDataIU((prevDataIU) =>{
    const newDataIU =  response.data.data.filter(
        (item) => (item.synergiCaseTypeDescription === DESCRIPTION.INVOLVED_UNIT) 
        && item.synergiUnit.length > 0
      )
      if(newDataIU.length>0){
      setCaseIdIU(newDataIU[0].synergiCaseId);
      }
      else{
       setCaseIdIU(NUMERAL.DEFAULT_CASE_ID)
      }
      return newDataIU
     } );

       }
     
        if (response.status !== 200 ) {
          appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
          setIsLoading(false);
          setError({
            errorStatus: true,
            errorMessage:  response.data
          });
          _setShouldOpen(true);
          if (response.status === 404) {
            _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
            _setSnackbarMessage(response.data);
            setIsLoading(false);
        setError({
          errorStatus: true,
          errorMessage:  response.data
        });
          } else {
            setIsLoading(false);
        setError({
          errorStatus: true,
          errorMessage: response.data
        });
            _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
            _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data)

          }
       return
        }
       
        
     } 
     catch (error) {
        appInsightTrackException(error);
      }
    };
    
    getCurrentMonthWorkHour();
  }, [
    props.accessToken,
    props.assetCode,
    tabValue,
    tableRefreshFlag,
    submitToSynergiFlag,
    selectedMonthIU,
    selectedYearIU
  


  ]);

  
  // const handleSelection = (selection, index, selectionId, identifier) => {
  //   let data = _.cloneDeep(currentMonthWorkHourData);

  //   if (identifier === IDENTIFIER.DEPARTMENTRU) {
  //     let dwDepartment = data[index].daWinciDepartment;

  //     data.forEach((element) => {
  //       if (element.daWinciDepartment === dwDepartment) {
  //         element.ruAction =
  //           element.synergiResponsibleUnitId === null ? "add" : "update";
  //         element.synergiResponsibleUnit = selection;
  //         element.synergiResponsibleUnitId = selectionId;
  //       }
  //     });
  //   }

  //   if (identifier === IDENTIFIER.DEPARTMENTIU) {
  //     let dwDepartment = data[index].daWinciDepartment;

  //     data.forEach((element) => {
  //       if (element.daWinciDepartment === dwDepartment) {
  //         element.iuAction =
  //           element.synInvolvedUnitId === null ? "add" : "update";
  //         element.synInvolvedUnit = selection;
  //         element.synInvolvedUnitId = selectionId;
  //       }
  //     });
  //   }

  //   if (identifier === IDENTIFIER.COMPANY) {
  //     let dwCompany = data[index].daWinciCompany;

  //     data.forEach((element) => {
  //       if (element.daWinciCompany === dwCompany) {
  //         element.companyAction =
  //           element.synergiCompanyId === null ? "add" : "update";
  //         element.synergiCompany = selection;
  //         element.synergiCompanyId = selectionId;
  //       }
  //     });
  //   }

  //   setCurrentMonthWorkHourData(data);
  // };

  const handleSave = async () => {
    let apicallStatus = false;
    const data = _.cloneDeep(currentMonthWorkHourData);

    let requestBody = {
      DawinciInstallation: props.assetCode,
      companyMapLst: [],
      deptRuMapLst: [],
    };

    data.forEach((element) => {
      let tempRuIuObj;
      let tempCompanyObj;

      if (element.ruAction !== null || element.iuAction !== null) {
        let action;
        if (element.daWinciDeptSynRuMapId === null) {
          action = "add";
        } else {
          action = "update";
        }

        tempRuIuObj = {
          Action: action,
          Id: element.daWinciDeptSynRuMapId,
          DawinciDepartment: element.daWinciDepartment,
          SynResponsibleUnitId: element.synergiResponsibleUnitId,
          SynInvolvedUnitId: element.synInvolvedUnitId,
        };

        requestBody.deptRuMapLst.push(tempRuIuObj);
      }

      if (element.companyAction !== null) {
        tempCompanyObj = {
          Action: element.companyAction,
          Id: element.daWinciCompSynCompMapId,
          DawinciCompany: element.daWinciCompany,
          SynCompanyId: element.synergiCompanyId,
        };

        requestBody.companyMapLst.push(tempCompanyObj);
      }
    });


    _setShouldOpen(true);

    return apicallStatus;
  };

  //implementing auto save
  //useInterval(handleSave, NUMERAL.AUTO_SAVE_TIME, caseId,caseIdIU);

  //handle case change
  const onCaseTypeChange = (event) => {
    //uncomment this to enable case selections. Until then, default is responsible unit
    //setCaseType(event.target.value);
  };

  
    const handleSubmitToSynergi = async () => {
    setIsLoaded(false);

    //save before submit to synergi, if save fails, exit.
    let responseStatus = handleSave();

    if (responseStatus === false) {
      return;
    }

    let caseBody={	
    	assetId: props.assetId,		
    	synergiCaseTypeDescription: currentMonthWorkHourData[0].synergiCaseTypeDescription,		
    	synergiCaseTypeId: currentMonthWorkHourData[0].synergiCaseTypeId,		
      synergiUnitId:currentMonthWorkHourData[0].synergiUnitId,	
      synergiLocationId:props.locationId,			
      comment:postContent,		
      	};

    try {
      let response = await submitCaseToSynergi(
        props.accessToken,caseBody
        // props.assetCode,
        // props.assetName,
        // props.locationId,
        // props.ruId,
        // caseType
      );
    //  console.log(response)
      
      setIsLoaded(true);
      _setShouldOpen(true);

      if (response.status === 200) {
        appInsightTrackEvent("case created in synergi");

        _setApiResponseStatus(API_RESPONSE_STATUS.SUCCESS);
        _setSnackbarMessage("Case created in synergi");
      } else {
       // console.log("This is error")
        appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)

        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
      }
    } catch (error) {
      _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
      _setSnackbarMessage("Case creation failed");
      appInsightTrackException(error);
    }

    setSubmitToSynergiFlag(!submitToSynergiFlag);
  };

  
  const handleSubmitToSynergiIU = async () => {
    setIsLoaded(false);

    //save before submit to synergi, if save fails, exit.
    let responseStatus = handleSave();

    if (responseStatus === false) {
      return;
    }

    let caseBody={	
    	assetId: props.assetId,		
    	synergiCaseTypeDescription: currentMonthWorkHourDataIU[0].synergiCaseTypeDescription,		
    	synergiCaseTypeId: currentMonthWorkHourDataIU[0].synergiCaseTypeId,		
      synergiUnitId:currentMonthWorkHourDataIU[0].synergiUnitId,	
      synergiLocationId:props.locationId,			
      comment:postContentIU,		
      	};

    try {
      let response = await submitCaseToSynergi(
        props.accessToken,caseBody
        // props.assetCode,
        // props.assetName,
        // props.locationId,
        // props.ruId,
        // caseType
      );
    //  console.log(response)
      
      setIsLoaded(true);
      _setShouldOpen(true);

      if (response.status === 200) {
        appInsightTrackEvent("case created in synergi");

        _setApiResponseStatus(API_RESPONSE_STATUS.SUCCESS);
        _setSnackbarMessage("Case created in synergi");
      } else {
       // console.log("This is error")
        appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)

        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
      }
    } catch (error) {
      _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
      _setSnackbarMessage("Case creation failed");
      appInsightTrackException(error);
    }

    setSubmitToSynergiFlag(!submitToSynergiFlag);
  };

  const activateSubmitToSynergi = () => {

    let status = false;

    // disable submit to synergi if case is already submitted
    if (caseId !== 0 ) {
      return true;
    }
    //enable only if all the fields are filled by user
    //every will stop when it returns false
    currentMonthWorkHourData.forEach((row) => {
      if (
         row.synergiCompanyName  === null ||
        row.synergiUnit.length === NUMERAL.ZERO
      ) {
        status = true;
      }
    });

    return status;
  };

  const activateSubmitToSynergiIU = () => {
    let status = false;
    // disable submit to synergi if case is already submitted
    if (caseIdIU !== 0  ) {
      return true;
    }

    //enable only if all the fields are filled by user
    //every will stop when it returns false
   status=true
    currentMonthWorkHourDataIU.forEach((row) => {
      if (
      //  row.synergiCompanyName  === null ||
        row.synergiUnit.length > 0
      ) {
        status = false;
      }
    });

    return status;
  };
  const monthname = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  const today = new Date();
  const tablemonth = (moment().subtract(1, "month").format("MMMM"))
   const month = today.getMonth()+1;
   const year = today.getFullYear();
   const datee = today.getDate();
   const time=today.getHours();
   const minute=today.getMinutes();
   let prev_month=month-1;
 if(prev_month == 0)
 prev_month =12
 let downloadyear=year

 let prev_month_report=month-3
 if(prev_month_report == -2){prev_month_report=10
   downloadyear=year-1}
 
 if(prev_month_report == -1)
 {
 prev_month_report=11
 downloadyear=year-1
 }
 const prev_monthname=monthname[prev_month_report]
  const previousmonth = async (value) => {
    try {
      const response = await daWinciSynergiPreviousMonthWorkHours(props.accessToken,props.assetCode,prev_monthname,downloadyear);
      const newDataIU =  response.data.data.filter(
        (item) => (item.synergiCaseTypeDescription === DESCRIPTION.INVOLVED_UNIT) 
        && item.synergiUnit.length > 0
      )
        const previousvalue = {
          title: "Excel Download",
             rows: newDataIU.map(({ dawinciCompanyName,synergiCompanyName,departmentName,synergiUnit,pob, workHour}) => (
           
               { dawinciCompanyName,
                 synergiCompanyName,
                 departmentName,
                 synergiUnit:synergiUnit[synergiUnit.length-1],
                 pob,
                workHour:Math.trunc(workHour)
         })
           )
            } 
          
      const wb = utils.book_new();
            utils.book_append_sheet(wb, utils.json_to_sheet(previousvalue.rows));
             writeFileXLSX(wb,"Workhours"+">"+props.assetCode+"<"+datee+">"+month+">"+year+">"+".xlsx");   
            

    } catch (error) {

      console.error(error);

    }

  };
const synergilink=process.env.REACT_APP_SYNERGIURL+{caseId}
  const linkToCaseInSynergi = () => {
    window.open(process.env.REACT_APP_SYNERGIURL + `${caseId}`);
  };
  const synergilinkIU=process.env.REACT_APP_SYNERGIURL+{caseIdIU}
  const linkToCaseInSynergiIU = () => {
    window.open(process.env.REACT_APP_SYNERGIURL + `${caseIdIU}`);
  };
  const handleTabChange = (event, value) => {
    setTabValue(value);
    if(tabValue == 1){
      setTabResponse("IU")
    }
    else
    {setTabResponse("RU")}
  };
  //console.log(postContent)
  const dialogBoxJsx = () => {
    return (
      <BootstrapDialog onClose={handleCancel} open={open}>
        <DialogContent>
          <Typography gutterBottom>
            Would you like to go ahead with case creation in synergi?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirm}>
            Continue
          </Button>
          <Button autoFocus onClick={handleCancel} variant={"outlined"}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };
//Dialogue box for IU
const dialogBoxJsxIU = () => {
  return (
    <BootstrapDialog onClose={handleCancel} open={open}>
      <DialogContent>
        <Typography gutterBottom>
          Would you like to go ahead with case creation in synergi?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleConfirmIU}>
          Continue
        </Button>
        <Button autoFocus onClick={handleCancel} variant={"outlined"}>
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};



  
  return isLoading ? (
    <StarProgress style={{ marginLeft: "50%", marginTop: "10%" }} />
  ) : (
    <React.Fragment>
     
      <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tabValue}>
      <Box sx={{ borderBottom: 1, borderColor: "divider",borderBlockStyle:"shadow"}}>
      
              <TabList onChange={handleTabChange}>
                {/* <Tab label="Asset Configuration" value="1" />
                <Tab label="Current Month Workhours" value="2" /> */}
               
                <Tab label="Responsible Unit" value="1" />
                <Tab label="Involved Unit(PDP Drilling and Well)" value="2" />
              </TabList>
         
              
              </Box>

              <TabPanel value="1">
              {error.errorMessage || error.errorStatus ? 
     (
    <Container maxWidth="lg">
      <MonthDropdown onMonthYearChange={handleMonthYearChange} />
            <Grid container spacing={1}>
              <GenericError errorMessage={error.errorMessage} />
             
            </Grid>
          </Container>
    )
    :(<>
         <Grid item xs={6}>
         <Grid item xs={12}  style={{ textAlign: 'center' }}>       
          <MonthDropdown onMonthYearChange={handleMonthYearChange} />
</Grid>
<Grid item xs={12}>   <MonthlySumup
                accessToken={props.accessToken}
               currentMonthData={currentMonthWorkHourData}
               selectedMonth={selectedMonth}
               selectedYear={selectedYear}
              /></Grid>
          {caseId === NUMERAL.DEFAULT_CASE_ID ? null : (
             <Grid container style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              
            }}>
              
            
               <div style={{ display: 'flex', justifyContent: 'center', marginTop:"25px",height: '50px' }}>
      <Grid container alignItems="center">
        <Grid item>
        <CheckCircleOutlineIcon color="primary" style={{ marginTop: '20px' }} />
        
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" style={{ fontStyle: 'italic', marginTop: '10px', marginLeft: '10px' }}>
            Case created in synergi with case ID {caseId}
          </Typography>
        </Grid>
        <Grid item color="#007079">
          <Button variant="text" style={{marginTop:"20px"}}  onClick={linkToCaseInSynergi}>(Open In Synergi
          <BiExport style={{fontSize: '25px' }} />)
          </Button>
        </Grid>
      </Grid>
    </div>
            </Grid>
          )}
        </Grid>
            {caseId === NUMERAL.DEFAULT_CASE_ID ? (
              <Grid container spacing={2} alignItems="center">
             
              <Grid item xs={9}>
              <TextArea 
              
              maxlength={500}
              rows={4}
              variant="not filled"
              style={{ width:"1200px",marginTop:"10px" ,display: 'flex',backgroundColor:"rgba(230, 250, 236)",border: '1px solid rgba(0, 0, 0, 1)',padding:"4px",borderRadius: "10px"}}
              placeholder=" (Optional) Please provide the comment upto 500 characters"
              value={postContent} // ...force the input's value to match the state variable...
         onChange={e => setPostContent(e.target.value)} // ... and update the state variable on any edits!
           />    
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={handleClickOpen}
                  disabled={activateSubmitToSynergi()}
                  style={{ width: '100%', height: '100px',marginTop:'30px' ,fontSize: '18px'}}
                >
                  {!isLoaded ? <CircularProgress color="primary" size={24} /> : 'SUBMIT WORK HOURS REGISTRATION TO SYNERGI'}
                </Button>
              </Grid>
            </Grid>
            ) : null}
              {caseId === NUMERAL.DEFAULT_CASE_ID ? (
                <React.Fragment>
    
 <Grid container justifyContent="right">
  
                  
                  {dialogBoxJsx()}
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid >
                  
              </Grid>
              )}
              

      <Grid container spacing={2}>
        

        {caseId === NUMERAL.DEFAULT_CASE_ID ? (
          <Grid item xs={12}>
            <CaseTypeButtonGroup onCaseTypeChange={onCaseTypeChange} />
          </Grid>
        ) : null}
      </Grid>
     
      <AssetMetadataMappingTable
        accessToken={props.accessToken}
        assetCode={props.assetCode}
        location={props.location}
        levelOneRuData={props.levelOneRuData}
        synergiCompanyList={props.synergiCompanyList}
        // handleSelection={handleSelection}
        currentMonthData={currentMonthWorkHourData}
        searchText={props.searchText}
        textChange={props.textChange}
        childLevelsForRu={props.childLevelsForRu}
       
        assetId={props.assetId}
        caseId={caseId}
        assetName={props.assetName}
      /> 
      </>)}
      </TabPanel>

      <TabPanel value="2">
      {error.errorMessage || error.errorStatus ? 
     (
    <Container maxWidth="lg">
      <MonthDropdown onMonthYearChange={handleMonthYearChange} />
            <Grid container spacing={1}>
              <GenericError errorMessage={error.errorMessage} />
             
            </Grid>
          </Container>
    )
    :(<>
      {currentMonthWorkHourDataIU.length>0 ?

        <div>
          
         <Grid item xs={6}>
         <Grid item xs={12}  style={{ textAlign: 'center' }}>        <MonthDropdownIU onMonthYearChange={handleMonthYearChangeIU} />
</Grid>

<Grid item xs={12}>   <MonthlySumupIU
                accessToken={props.accessToken}
               currentMonthData={currentMonthWorkHourDataIU}
               selectedMonthIU={selectedMonthIU}
               selectedYearIU={selectedYearIU}
              /></Grid>
          {caseIdIU === NUMERAL.DEFAULT_CASE_ID ? null : (
            <Grid container style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              
            }}>
              <Grid item alignSelf="center">
              <div style={{ display: 'flex', justifyContent: 'center', marginTop:"25px",height: '50px' }}>
      <Grid container alignItems="center">
        <Grid item>
        <CheckCircleOutlineIcon color="primary" style={{ marginTop: '20px' }} />
        
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" style={{ fontStyle: 'italic', marginTop: '10px', marginLeft: '10px' }}>
            Case created in synergi with case ID {caseIdIU}
          </Typography>
        </Grid>
        <Grid item color="#007079">
          <Button variant="text" style={{marginTop:"20px"}}  onClick={linkToCaseInSynergiIU}>(Open In Synergi
          <BiExport style={{fontSize: '25px' }} />)
          </Button>
        </Grid>
      </Grid>
    </div>
              </Grid>
            </Grid>
          )}
        </Grid>
       
            {caseIdIU === NUMERAL.DEFAULT_CASE_ID ? (
              <Grid item>
               <TextArea fullWidth
               maxlength={500}
               rows={4}
               variant="not filled"
               style={{ display: 'flex',backgroundColor:"rgba(230, 250, 236)",border: '1px solid rgba(0, 0, 0, 1)',padding:"4px",borderRadius: "10px"}}
               placeholder=" (Optional) Please provide the comment upto 500 characters"
               value={postContentIU} // ...force the input's value to match the state variable...
          onChange={e => setPostContentIU(e.target.value)} // ... and update the state variable on any edits!
            />       
                       </Grid>
            ) : null}
              {caseIdIU === NUMERAL.DEFAULT_CASE_ID ? (
                <React.Fragment>
    
 <Grid container justifyContent="right">
   <Button
                    onClick={handleClickOpen}
                    style={{ width: "180px" }}
                    disabled={activateSubmitToSynergiIU()}
                  >
                    {!isLoaded ? (
                      <CircularProgress color="primary" size={24} />
                    ) : (
                      "SUBMIT TO SYNERGI"
                    )}
                  </Button>
                  
                  {dialogBoxJsxIU()}
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid container spacing={2} style={{marginLeft:"25px"}}>
                  <Grid item xs={9.7}>
                    </Grid>
                {/* <Grid item xs={2}>
                <Button
                  onClick={linkToCaseInSynergiIU}
                  style={{ width: "180px" }}
                >
                  OPEN IN SYNERGI
                </Button>
                </Grid> */}
              </Grid>
              )}
              

      <Grid container spacing={2}>
        

        {caseIdIU === NUMERAL.DEFAULT_CASE_ID ? (
          <Grid item xs={12}>
            <CaseTypeButtonGroup onCaseTypeChange={onCaseTypeChange} />
          </Grid>
        ) : null}
      </Grid> 
        <AssetMetadataMappingTableIU
         accessToken={props.accessToken}
         assetCode={props.assetCode}
         location={props.location}
         levelOneRuData={props.levelOneRuData}
         synergiCompanyList={props.synergiCompanyList}
        //  handleSelection={handleSelection}
         currentMonthData={currentMonthWorkHourDataIU}
         searchText={props.searchText}
         textChange={props.textChange}
         childLevelsForRu={props.childLevelsForRu}
        
         assetId={props.assetId}
         caseId={caseIdIU}
         assetName={props.assetName}/>
        </div>
    
    : <><div style={{marginTop:"10px",marginLeft:"40%"}}> <MonthDropdownIU  onMonthYearChange={handleMonthYearChangeIU} /> </div>
    <Typography style={{color:"#007079"}} variant="h6" marginTop={"130px"}>Involved unit report not found for <i> {props.assetName}</i> due to no pre-mapped D&W units. If relevant, please update from DaWinci-Synergi mapping screen -Involved Unit(PDP Drilling and Well)</Typography></>}
       </>)}
      </TabPanel>
      </TabContext>
      </Box>
    </React.Fragment>
  );
};

export default DaWinciScreenB;











