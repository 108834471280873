import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  Grid
} from "@material-ui/core";
import "./styles.css";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import Locationdropdown from "./Locationdropdown";
import { allocatedassets, assetallocation, assetsbycluster } from "../_axios";
import { appInsightTrackEvent,_setApiResponseStatus,_setSnackbarMessage, appInsightTrackException } from "../Logging/Logging";
import { snackbarContext } from "../../App";
import { StarProgress } from "@equinor/eds-core-react";
import { Container, Dialog, DialogActions, DialogContent, Tooltip, Typography } from "@mui/material";
import GenericError from "../GenericError/GenericError";
import { API_RESPONSE_STATUS } from "../Constant";
import CancelIcon from "@mui/icons-material/Cancel";
const LocationTagging = (props) => {
  const [tableData, setTableData] = useState([
  ]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
  });
  const[userLocation,setUserLocation]=useState();
  const [open, setOpen] = useState(false);
  const [deletealeret, setDeletealeret] = useState(false);
  const[save,setSave]=useState(false)
   const [confirmCallback, setConfirmCallback] = useState(false);
   let getassettagged
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
  useContext(snackbarContext);



  const handleEditRow = (index) => {
    const newTableData = [...tableData];
    newTableData[index].editing = true;
    setTableData(newTableData);
  };


  const handleSaveRow = (index) => {
    const newData = [...tableData];
    newData[index].editing = false;
    setTableData(newData);
    setFilteredTableData(newData.filter(
      (row) =>
        row.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.location?.map((loc) =>  loc?.assetName.toLowerCase()).join(", ").includes(searchTerm.toLowerCase())
    ));
  };

  const handleSubmitRow  = async (index) => {
    const newData = [...tableData];
    const row = newData[index];

    
    //newData[index].editing = false;
    setTableData(newData);
    
    setFilteredTableData(newData.filter(
      (row) =>
        row.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.location?.map((loc) =>  loc?.assetName.toLowerCase()).join(", ").includes(searchTerm.toLowerCase())
    ));  
const transformedData = tableData.map(item => ({
  actionName: item.actionName,
  userEmail: item?.name,
  assets: item.location.map(asset => ({
    ...asset,
    UserId: '',
  })),
}));




try {
      let response = await assetallocation(props.accessToken, transformedData);
      if (response.status === 200) {
       appInsightTrackEvent(response.data.message);
        _setSnackbarMessage(response.data.message);
        _setShouldOpen(true);
        _setApiResponseStatus(API_RESPONSE_STATUS.SUCCESS);

        try{
          const response= await allocatedassets(props.accessToken,1,25);
          setIsLoading(false)
          if (response.status === 200) {
         
            const updatedTableData = response.data.map((item) => ({
              actionName:"",
              id: item.userId,
              name: item.mailId,
              location:item.allocatedAssetsList,
              editing: false,
              
            }));
    
           
            setTableData(updatedTableData);
            // setUserinfo(response.f)
           
           
         
          } else if (response.status === 403) {
            appInsightTrackEvent(response.statusText);
            _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
             _setSnackbarMessage(response.statusText);
                       _setShouldOpen(true);
                       setIsLoading(false);
                       setError({
                         errorStatus: true,
                       errorMessage: response.statusText,
                     });
          }
          else if (response.status === 500) {
            appInsightTrackEvent("The site seems to be unavailable, please try after some time.");
            // _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
             _setSnackbarMessage("The site seems to be unavailable, please try after some time.");
                       _setShouldOpen(true);
                       setIsLoading(false);
                       setError({
                         errorStatus: true,
                       errorMessage: "The site seems to be unavailable, please try after some time.",
                     });
          }
           else {
            appInsightTrackEvent(response.data === "" ? "Something went wrong,Please try again" : response.data);
            //notification for error
            _setShouldOpen(true);
            // _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
            _setSnackbarMessage(response.data === "" ? "Something went wrong,Please try again" : response.data)
            setIsLoading(false);
            setError({
              errorStatus: true,
              errorMessage: (response.data === "" ? "Something went wrong,Please try again": response.data),
            });
          }
        } catch (error) {
         
           setError({
            errorStatus: true,
            errorMessage: "Something went wrong,Please try again"
          });
          appInsightTrackException(error);
    
        }


      } else {
        appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
        
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
      }
    } catch (error) {
      appInsightTrackException(error);
    }

  };


  const handleDeleteRow = (index) => {
    setDeletealeret(true)
    const rowToDelete = tableData[index];
    const updatedRow = {
      ...rowToDelete,
      location: [], // Remove all assets from the location array
      actionName: "delete", // Set the actionName to "delete" to indicate that the row should be deleted
    };
    setOpen(true); // Open the confirmation popup
    setConfirmCallback(() => () => { // Set the callback function to delete the row
      const newTableData = [...tableData];
      newTableData[index] = updatedRow;
      setTableData(newTableData);
    });
  };
  const handleConfirm = () => {
    confirmCallback(); // Call the callback function to delete the row
    setOpen(false);
    setConfirmCallback(() => () => {});
    
  };
  const handleCancel = () => {
    setOpen(false);
    setConfirmCallback(() => () => {});
  };

  const dialogBoxJsx = () => {
    return (
      <div inert={open ? "true" : null}>
        <Dialog onClose={handleCancel} open={open}>
          <DialogContent>
            <Typography gutterBottom>
              Would you like to go ahead with deleting record?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleConfirm}>
              Continue
            </Button>
            <Button autoFocus onClick={handleCancel} variant={"outlined"}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const handleAddRow = () => {
    const newTableData = [
      ...tableData,
      {
        ActionName: "add",
        id: tableData[tableData.length - 1].id + 1,
        name: "",
        location: [],
        editing: true,
      },
    ];
    setTableData(newTableData);
  };

  const handleNameChange = (event, index) => {
    const newTableData = [...tableData];
    newTableData[index].name = event.target.value;
    setTableData(newTableData);
  };
  const handleLocationChange = (event, row, index, userLocation) => {
    // Parse the new location from the event
    const newLocation = Array.isArray(event.target.value)
      ? event.target.value.map((name) => {
          const locationObj = row.location.find((loc) => loc.assetName === name.trim());
          const asset = locationObj ? locationObj : userLocation.find((loc) => loc.name === name.trim()); // Fetch the asset object from the userLocation array
          return { assetName: name.trim(), UserId: "", assetId: asset.assetId, assetAdminId: asset.assetAdminId }; // Include the assetAdminId in the returned object
        })
      : typeof event.target.value === "string"
      ? event.target.value.split(",").map((name) => {
          const locationObj = row.location.find((loc) => loc.assetName === name.trim());
          const asset = locationObj ? locationObj : userLocation.find((loc) => loc.name === name.trim()); // Fetch the asset object from the userLocation array
          return { assetName: name.trim(), UserId: "", assetId: asset.assetId, assetAdminId: asset.assetAdminId }; // Include the assetAdminId in the returned object
        })
      : [];
  
    // Create a new row with the updated location
    const newRow = { ...row, location: newLocation };
  
    // Check if the row is a new row
    const isNewRow = !row.id;
  
    // Access the id property of the row
    const rowId = row.id;
  
    // Create a new table data array with the updated row
    const newTableData = [...tableData];
    newTableData[index] = newRow;
    newTableData[index].actionName = isNewRow ? "update" : "add";
  
    // Update the table data state
    setTableData(newTableData);
  
    setFilteredTableData(newTableData.map((row) => ({
      ...row,
      location: row.location?.map((loc) => ({ ...loc, id: loc?.assetId }))
    })).filter(
      (row) =>
        row.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.location?.some((loc) => loc?.assetName.toLowerCase().includes(searchTerm.toLowerCase()))
    ));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };
  
  
  const [filteredTableData, setFilteredTableData] = useState([]);

  useEffect(() => {
    const data = tableData?.filter(
      (row) =>
        row.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.location?.map((loc) =>  loc?.assetName.toLowerCase()).join(", ").includes(searchTerm.toLowerCase())
    );
    setFilteredTableData(data);
  }, [tableData, searchTerm]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredTableData.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  useEffect(()=>{
     getassettagged = async ()=>{
      try{
        const response= await allocatedassets(props.accessToken,1,25);
        setIsLoading(false)
        if (response.status === 200) {
          const updatedTableData = response.data.map((item) => ({
            actionName:"",
            id: item.userId,
            name: item.mailId,
            location:item.allocatedAssetsList,
            editing: false,
            
          }));
  
         
          setTableData(updatedTableData);
          // setUserinfo(response.f)
         
         
       
        } else if (response.status === 403) {
          appInsightTrackEvent(response.statusText);
          _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
           _setSnackbarMessage(response.statusText);
                     _setShouldOpen(true);
                     setIsLoading(false);
                     setError({
                       errorStatus: true,
                     errorMessage: response.statusText,
                   });
        }
        else if (response.status === 500) {
          appInsightTrackEvent("The site seems to be unavailable, please try after some time.");
          // _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
           _setSnackbarMessage("The site seems to be unavailable, please try after some time.");
                     _setShouldOpen(true);
                     setIsLoading(false);
                     setError({
                       errorStatus: true,
                     errorMessage: "The site seems to be unavailable, please try after some time.",
                   });
        }
         else {
          appInsightTrackEvent(response.data === "" ? "Something went wrong,Please try again" : response.data);
          //notification for error
          _setShouldOpen(true);
          // _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
          _setSnackbarMessage(response.data === "" ? "Something went wrong,Please try again" : response.data)
          setIsLoading(false);
          setError({
            errorStatus: true,
            errorMessage: (response.data === "" ? "Something went wrong,Please try again": response.data),
          });
        }
      } catch (error) {
       
         setError({
          errorStatus: true,
          errorMessage: "Something went wrong,Please try again"
        });
        appInsightTrackException(error);
  
      }
    };
  
   
  
    getassettagged();
  }, [props.accessToken]);
  


  useEffect(()=>{
    const getlocation = async ()=>{
      try{
        const response=await assetsbycluster(props.accessToken,0)
        setIsLoading(false)
  
        if (response.status === 200) {
          setIsLoading(false)
          const locationValue =response.data.map((item) => {
            return { id: item.assetId, name: item.assetName };
          });
  
         
          setUserLocation(locationValue)
       
         
       
        } else if (response.status === 403) {
          appInsightTrackEvent("You are not Authorized to Access this Application");
        // _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
           _setSnackbarMessage("You are not Authorized to Access this Application");
                     _setShouldOpen(true);
                     setIsLoading(false);
                     setError({
                       errorStatus: true,
                     errorMessage: "You are not Authorized to Access this Application",
                   });
        }
        else if (response.status === 500) {
          appInsightTrackEvent("The site seems to be unavailable, please try after some time.");
          // _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
           _setSnackbarMessage("The site seems to be unavailable, please try after some time.");
                     _setShouldOpen(true);
                     setIsLoading(false);
                     setError({
                       errorStatus: true,
                     errorMessage: "The site seems to be unavailable, please try after some time.",
                   });
        }
         else {
          appInsightTrackEvent(response.data === "" ? "Something went wrong,Please try again" : response.data);
          //notification for error
          _setShouldOpen(true);
          // _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
          _setSnackbarMessage(response.data === "" ? "Something went wrong,Please try again" : response.data)
          setIsLoading(false);
          setError({
            errorStatus: true,
            errorMessage: (response.data === "" ? "Something went wrong,Please try again": response.data),
          });
        }
      } catch (error) {
         setError({
          errorStatus: true,
          errorMessage: "Something went wrong,Please try again"
        });
        appInsightTrackException(error);
  
      }
    };
  
   
  
    getlocation();
  }, [props.accessToken]);
  return (
    <React.Fragment>
    {isLoading ? (
            <StarProgress style={{ marginLeft: "50%", marginTop: "5%" }} />
          ) : error.errorStatus ? (
            <Container maxWidth="lg">
              <Grid container spacing={1}>
                <GenericError errorMessage={error.errorMessage} />
              </Grid>
            </Container>
          ) : (
    <div style={{ marginLeft: "55px", marginRight: "50px", marginTop: "40px" }}>
      <TextField label="Search by usermail or asset" variant="outlined" onChange={handleSearch} style={{ marginBottom: "20px", width: "25%" }} />

      <Table style={{ border: "1px solid #CCCCCC", fontSize: "12px" }}>
        <TableHead>
          <TableRow style={{ borderBottom: "1px solid #CCCCCC" }}>
            <TableCell style={{ padding: "16px", width: "15%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor" }}>Email</TableCell>
            <TableCell align="center" style={{ padding: "16px", width: "70%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor",align:"Center" }}>Asset</TableCell>
            <TableCell style={{ padding: "8px", width: "10%", backgroundColor: "#E6FAEC", fontWeight: "bold", fontFamily: "equinor" }}>Action</TableCell>
          </TableRow>
        </TableHead>
      
        <TableBody>
  {filteredTableData.length > 0 ? (
    filteredTableData
      .filter((row) => row.actionName !== "delete") // Filter out rows with actionName set to "delete"
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => (
    <TableRow key={row.mailId} style={{ borderBottom: "1px solid #CCCCCC" }}>
      <TableCell style={{ padding: "16px", fontFamily: "equinor" }}>
        {row.editing ? (
          <TextField value={row.name} onChange={(event) => handleNameChange(event, index)} />
        ) : (
          row.name
        )}
      </TableCell>
      <TableCell align="center">
        {row.editing ? (
          <Locationdropdown
            value={row.location.map((loc) => loc?.assetName)}
            onChange={(event, newRow) => handleLocationChange(event, newRow, index, userLocation)}
            options={userLocation}
            row={row}
            userLocation={userLocation}
          />
        ) : (
          <span style={{ fontFamily: "equinor" }}>
            {row.location.map((loc) => loc?.assetName).join(", ")}
          </span>
        )}
      </TableCell>
      <TableCell style={{ padding: "8px" }}>
        {row.editing ? (
          <>
            <Tooltip title="Close" placement="top">
              <Button variant="text">
                <CancelIcon style={{ color: "#007079" }} onClick={() => handleSaveRow(index)} />
              </Button>
            </Tooltip>
            <Button variant="text">
              <DeleteOutlineTwoToneIcon style={{ color: "#007079" }} onClick={() => handleDeleteRow(index)} />
            </Button>
          </>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button variant="text">
              <EditTwoToneIcon style={{ color: "#007079" }} onClick={() => handleEditRow(index)} />
            </Button>
            <Button variant="text">
              <DeleteOutlineTwoToneIcon style={{ color: "#007079" }} onClick={() => handleDeleteRow(index)} />
            </Button>
          </div>
        )}
      </TableCell>
    </TableRow>
  ))) : (
    <TableRow style={{ borderBottom: "1px solid #CCCCCC" }}>
      <TableCell colSpan={3} align="center" style={{ padding: "16px", fontFamily: "equinor",color:"red" }}>
        No records found.
      </TableCell>
    </TableRow>
  )}
</TableBody>
         
       
      </Table>
      {deletealeret === true ? (
                <React.Fragment>
    
 <Grid container justifyContent="right">
  
                  
                  {dialogBoxJsx()}
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid >
                  
              </Grid>
              )}
     
      <Grid container spacing={2}>
     <Grid Grid sx={8}style={{alignContent:"Left"}}>
      <Button style={{color: '#007079', fontWeight: 'bold', textTransform: 'none',marginTop:"10px"}} onClick={handleAddRow}>
  + New Asset Tagging
</Button>
</Grid>
<Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
  <TablePagination
    rowsPerPageOptions={[25]}
    component="div"
    count={filteredTableData.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    style={{ borderBottom: "none" }}
  />
</Grid>
        </Grid>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
  <Button variant="contained" onClick={handleSubmitRow} style={{ backgroundColor: "#007079", color: "#fff", padding: "12px 24px", fontSize: "16px" }}>
    Save
  </Button>
</div>

    </div>
    )}
    </React.Fragment>
  );
};

export default LocationTagging;