import React, { useState, useEffect, createContext, useContext } from "react";
import {
  daWinciSynergiReferenceData,
  assetCompanyMapping,
  daWinciSynergiReferenceSave,
  daWinciSynergiDepartmentSave,
  daWinciSynergiCurrentMonthWorkHours,
  daWinciSynergiReferenceSaveAssetWise
} from "../_axios";
import { StarProgress } from "@equinor/eds-core-react";
import { Grid, Button, Divider, Tab } from "@mui/material";
import _ from "lodash";
import CompanyMappingComponent from "./DaWinciSynergiReferenceTable/DaWinciSynergiCompanyComponent/CompanyMappingComponent";
import DeptRuComponent from "./DaWinciSynergiReferenceTable/DaWinciSynergiDeptComponent/DeptRuComponent";
import { IDENTIFIER, TABLE_ACTION, API_RESPONSE_STATUS } from "../Constant";
import { snackbarContext } from "../../App";
import {
  appInsightTrackEvent,
  appInsightTrackException,
} from "../Logging/Logging";
import Container from "@mui/material/Container";
import GenericError from "../GenericError/GenericError";
import { Tooltip} from "@mui/material";
import TabList from "@mui/lab/TabList/TabList";
import {Box} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import TabContext from "@mui/lab/TabContext/TabContext";
import DeptIuComponent from "./DaWinciSynergiReferenceTable/DaWinciSynergiDeptComponent/DeptIuComponent";
import { globalVariable } from "../../App";
import { DialogActions, DialogContent,Dialog, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
export const ruSelectionContext = createContext();

const DaWinciScreenA = (props) => {

  const [daWinciSynergiMapping, setDaWinciSynergiMapping] = useState({
    companyMapList: [],
  }); 
  const [deptMapping, setDeptMapping] = useState({
    deptRuMapList: [],
    deptIuMapList: [],
  }); 
  const [isLoading, setIsLoading] = useState(true);

  const [toggleSaveFlag, setToggleSaveFlag] = useState(false);
  const [open, setOpen] = useState(false);
 

 
  function handleChildClick(value) {
   
    setToggleSaveFlag(true)// Output: 42
  }

  
  //context for snackbar
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
    useContext(snackbarContext);

    const {setUserWarningComapny ,_setIsEditModeOn} = useContext(globalVariable);
    const [error, setError] = useState({
      errorStatus: false,
      errorMessage: "",
    });
  const newDeptRuRow = {
    id: null,
    action: null,
    dawinciDepartment: "",
    synergiUnitId: null,
  };
  const newDeptIuRow = {
    id: null,
    action: null,
    dawinciDepartment: "",
    synergiUnitId: null,
  };

  const newCompanyRow = {
    id: null,
    action: null,
    synergiCompanyId: null,
    dawinciCompanyName: "",
    synergiCompanyName: "",
  };

  const daWinciSynergiSaveRequest = {
    DawinciInstallation: "",
    companyMapLst: [],
  };
  const daWinciSynergDeptSaveRequest = {
    dawinciInstallationCode: "",
    synergiCaseTypeDescription: "",
    synergiCaseTypeId: 0,
    deptRuMapLst: []

  };
  const daWinciSynergDeptSaveRequestIU = {
    dawinciInstallationCode: "",
    synergiCaseTypeDescription: "",
    synergiCaseTypeId: 0,
    deptRuMapLst: []
    

  };
  const [tabValue, setTabValue] = useState("1");
  const[iuValue,setIuValue]=useState(false);
  const[iuValuecheck,setIuValuecheck]=useState(false);
  const [currentMonthWorkHourData, setCurrentMonthWorkHourData] = useState([]);
  const [caseStatus, setCaseStatus] = useState(true);
  const [currentMonthWorkHourDataIU, setCurrentMonthWorkHourDataIU] = useState([]);
  const [caseStatusIU, setCaseStatusIU] = useState(true);
  const selectedMonthYear = sessionStorage.getItem('selectedMonthYear');
  const [year, month] = (selectedMonthYear ?? "").split("-");
const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(`${year}-${month}-01`));

  useEffect(() => {
    const daWinciRefData = async () => {
      try {
        let response;
        response = await assetCompanyMapping(
          props.accessToken,
          props.assetId,
          monthName,
          year
        );
        if (
          response.status === 200 ||
          response.status === 204
        ) {
          if (response.status === 204) {
            appInsightTrackEvent(response.status.message);

            _setShouldOpen(true);
            _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
            _setSnackbarMessage(response.data.status.message);
          }
          //assign company mapping values from api
          let companyMappedData =
            response.data.data === null
              ? daWinciSynergiMapping.companyMapList
              : response.data.data;
          //assign dept-ru mapping value from api
          // let deptRuMappedData =
          // IUdata.data.data  === null
          //     ? daWinciSynergiMapping.deptRuMapList
          //     : IUdata.data.data
          //     ;
          //    

          // //adding an action field to each object
          // deptRuMappedData.forEach((element, index) => {
          //   element["guid"] = index;
          //   element["action"] = null;
          // });

          //   //temp for involved unit, should be removed when it gets
          //   //sent from api
          //  // element["synInvolvedUnit"] = "";
          //   //element["synInvolvedUnitId"] = null;
          // });

          // //adding an action field to each object
          // companyMappedData.forEach((element, index) => {
          //   element["guid"] = index;
          //   element["action"] = null;
          // });
         
          setDaWinciSynergiMapping({
            companyMapList: companyMappedData
            
          });
       
         
        } else {
          setError({
            errorStatus: true,
            errorMessage:  response.data
          });
          appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
           _setShouldOpen(true);
          _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
          _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
          setIsLoading(false);
        
       
        }

        setIsLoading(false);
      } catch (error) {
        appInsightTrackException(error);
      }
    };

    daWinciRefData();
  }, [props.accessToken, props.assetCode, toggleSaveFlag]);

  
  //Department Data
  useEffect(() => {
    const daWinciDeptData = async () => {
      try {
       
       let responsedept = await daWinciSynergiReferenceData(
          props.accessToken,
          props.assetCode,
        );
        if (
          responsedept.status === 200 ||
          responsedept.status === 204
        ) {
          if (responsedept.status === 204) {
            appInsightTrackEvent(responsedept.status.message);

            _setShouldOpen(true);
            _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
            _setSnackbarMessage(responsedept.data.status.message);
          }

          const RUdata= responsedept.data.data.filter((item) => item.synergiCaseTypeDescription === "Man Hours - Asset owner/Responsible unit");
          const IUdata= responsedept.data.data.filter((item) => item.synergiCaseTypeDescription === "Man Hours - Internal suppliers/Units involved");

          let deptRuMappedData =
          RUdata  === null
              ? deptMapping.deptRuMapList
              : RUdata
              ;

              let deptIuMappedData =
              IUdata  === null
                  ? deptMapping.deptIuMapList
                  : IUdata
                  ;
              setDeptMapping({
                deptRuMapList: deptRuMappedData,
                deptIuMapList :deptIuMappedData
                
              });
              
        

          // //adding an action field to each object
          // deptRuMappedData.forEach((element, index) => {
          //   element["guid"] = index;
          //   element["action"] = null;
          // });

          //   //temp for involved unit, should be removed when it gets
          //   //sent from api
          //  // element["synInvolvedUnit"] = "";
          //   //element["synInvolvedUnitId"] = null;
          // });

          // //adding an action field to each object
          // companyMappedData.forEach((element, index) => {
          //   element["guid"] = index;
          //   element["action"] = null;
          // });
         
        } else {
          setError({
            errorStatus: true,
            errorMessage:  responsedept.data
          });
          appInsightTrackEvent(responsedept.data === "" ? "Something went wrong,Please Try Again" : responsedept.data)
           _setShouldOpen(true);
          _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
          _setSnackbarMessage(responsedept.data === "" ? "Something went wrong,Please Try Again" : responsedept.data)
          setIsLoading(false);
        
       
        }

        setIsLoading(false);
      } catch (error) {
        appInsightTrackException(error);
      }
    };

    daWinciDeptData();
  }, [props.accessToken, props.assetCode, toggleSaveFlag]);

useEffect(()=>{
  const hasNull=deptMapping.deptIuMapList.some(item=>{;
  return item.synergiUnitId !== null})
            // setIuValue(IUdata.some(item=>item.synergiUnitId == null))
            setIuValuecheck(hasNull)
            //assign dept-ru mapping value from api
},[deptMapping.deptIuMapList])
  
  const handleTableRowAddition = (identifier) => {
    let tableData = _.cloneDeep(daWinciSynergiMapping);

    if (identifier === IDENTIFIER.COMPANY) {
      tableData.companyMapList.push(newCompanyRow);
    }

    setDaWinciSynergiMapping(tableData);
  };
  const handleTableRowAdditionRUIU = (identifier) => {
    let tableData = _.cloneDeep(deptMapping);


    if (identifier === IDENTIFIER.DEPARTMENTRU) {
      tableData.deptRuMapList.push(newDeptRuRow);
    }
    if (identifier === IDENTIFIER.DEPARTMENTIU) {
      tableData.deptIuMapList.push(newDeptIuRow);
    }

    setDeptMapping(tableData);
  };

 

  const handleRuAssignmentToDept = (
    selection,
    index,
    selectionId,
    identifier,
    idList,
  ) => {
   
    let tableData = _.cloneDeep(deptMapping);
    tableData.selectionMade = !!selection
    !tableData.selectionMade ? setIuValue(false) : setIuValue(true) 
    if (identifier === "respUnit") {
      //adding ru data
      tableData.deptRuMapList[index].synergiUnit = selection;
      tableData.deptRuMapList[index].synergiUnitId = selectionId;
    }

    if (identifier === "involvedUnit") {
      //adding iu data
  
      for (let i = 0; i < tableData.deptIuMapList.length; i++) {
       
        if (idList.includes(tableData.deptIuMapList[i].id)) {
       
          tableData.deptIuMapList[i].synergiUnit = selection;
          tableData.deptIuMapList[i].synergiUnitId = selectionId;
          tableData.deptIuMapList[i].action = TABLE_ACTION.UPDATE
        }
      }
    }
    if (identifier === "respUnit") {
    //decide action based on id assigned
    tableData.deptRuMapList[index].id === null
      ? (tableData.deptRuMapList[index].action = TABLE_ACTION.ADD)
      : (tableData.deptRuMapList[index].action = TABLE_ACTION.UPDATE);
    }
    if (identifier === "involvedUnit") {
       //decide action based on id assigned
    tableData.deptIuMapList[index].id === null
    ? (tableData.deptIuMapList[index].action = TABLE_ACTION.ADD)
    : (tableData.deptIuMapList[index].action = TABLE_ACTION.UPDATE);
    }
      setDeptMapping(tableData);
              
  };

  const handleSynergiCompanyAssignment = (
    synergiCompany,
    index,
    synergiCompanyId
  ) => {
    let tableData = _.cloneDeep(daWinciSynergiMapping);
    tableData.companyMapList[index].synergiCompanyName = synergiCompany;
    tableData.companyMapList[index].synergiCompanyId = synergiCompanyId;

    //decide action based on id assigned
    tableData.companyMapList[index].id === null
      ? (tableData.companyMapList[index].action = TABLE_ACTION.ADD)
      : (tableData.companyMapList[index].action = TABLE_ACTION.UPDATE);

    setDaWinciSynergiMapping(tableData);
  };

  const handleTableRowDelete = (index, identifier) => {
   
    let tableData = _.cloneDeep(daWinciSynergiMapping);
    if (identifier === IDENTIFIER.COMPANY) {
      tableData.companyMapList[index].action = TABLE_ACTION.DELETE;
    }

    // if (identifier === IDENTIFIER.DEPARTMENTRU) {
    //   tableData.deptRuMapList[index].action = TABLE_ACTION.DELETE;
    // }

    setDaWinciSynergiMapping(tableData);
  };
  
//Handle Delete change Department
  const handleTableRowDeleteDept = (index, identifier,finalRows) => {
 
   
    let tableData = _.cloneDeep(deptMapping);
    if (identifier === IDENTIFIER.DEPARTMENTRU) {
      tableData.deptRuMapList[index].action = TABLE_ACTION.DELETE;
    }
    if (identifier === IDENTIFIER.DEPARTMENTIU) {
     
      const rowsid = finalRows[index].departments.map(ids => ids.id);
      // Loop through finalRows array to find corresponding department in deptIuMapList
      for (let i = 0; i < rowsid.length; i++) {
        const matchingEntry = tableData.deptIuMapList.find(
          entry => entry.id === rowsid[i]
        );
        if (matchingEntry) {
         
          // Find the index of the matching entry in deptIuMapList
          const matchingEntryIndex = tableData.deptIuMapList.findIndex(
            entry => entry.id === matchingEntry.id
          );
          // If department id matches, update synergiUnit and synergiUnitId to null
          tableData.deptIuMapList[matchingEntryIndex].action = TABLE_ACTION.DELETE;
          tableData.deptIuMapList[matchingEntryIndex].synergiUnit = null;
          tableData.deptIuMapList[matchingEntryIndex].synergiUnitId = null;
        }
      }
       
      
    }

    setDeptMapping(tableData);

  };

  //Text change Department
  const handleDawinciTextChangeDept = (event, index, identifier,idList,IUidfordept,IUnamefordept) => {
    let tableData = _.cloneDeep(deptMapping);

    if (identifier === IDENTIFIER.DEPARTMENTRU) {
      tableData.deptRuMapList[index].dawinciDepartment = event.target.value;

      tableData.deptRuMapList[index].id === null
        ? (tableData.deptRuMapList[index].action = TABLE_ACTION.ADD)
        : (tableData.deptRuMapList[index].action = TABLE_ACTION.UPDATE);
    }
    if (identifier === IDENTIFIER.DEPARTMENTIU) {
      for (let i = 0; i < tableData.deptIuMapList.length; i++) {
        if (idList.some(obj => obj.id === tableData.deptIuMapList[i].id)) {
          tableData.deptIuMapList[i].action = TABLE_ACTION.UPDATE
          tableData.deptIuMapList[i].synergiUnit = IUnamefordept;
          tableData.deptIuMapList[i].synergiUnitId = IUidfordept;
          
        }
        if (IUidfordept === tableData.deptIuMapList[i].synergiUnitId && !idList.some(obj => obj.id === tableData.deptIuMapList[i].id))
      {
        tableData.deptIuMapList[i].action = TABLE_ACTION.UPDATE
        tableData.deptIuMapList[i].synergiUnit = null;
        tableData.deptIuMapList[i].synergiUnitId = null;
      }
      //badfix for iu
        // if(IUidfordept === tableData.deptIuMapList[i].synergiUnitId && idList.some(obj => obj.id != tableData.deptIuMapList[i].id)  )
        //   {
        //     console.log("test scenario")
        //     tableData.deptIuMapList[i].synergiUnit = "";
        //     tableData.deptIuMapList[i].synergiUnitId = null;
        //   }
      }
      tableData.deptIuMapList[index].id === null
        ? (tableData.deptIuMapList[index].action = TABLE_ACTION.ADD)
        : (tableData.deptIuMapList[index].action = TABLE_ACTION.UPDATE);
    }

    setDeptMapping(tableData);
  };
  
//Text change company
const handleDawinciTextChange = (event, index, identifier) => {
  let tableData = _.cloneDeep(daWinciSynergiMapping);

  if (identifier === IDENTIFIER.COMPANY) {
    tableData.companyMapList[index].dawinciCompanyName = event.target.value;

    tableData.companyMapList[index].id === null
      ? (tableData.companyMapList[index].action = TABLE_ACTION.ADD)
      : (tableData.companyMapList[index].action = TABLE_ACTION.UPDATE);
  }

  setDaWinciSynergiMapping(tableData);
};


  //for saving company data
  const saveDawinciSynergiMapping = async () => {
    setUserWarningComapny(false)
    let body = _.cloneDeep(daWinciSynergiSaveRequest);

  
    //warning for saving data
    
    //remove all action = null
    // let deptRuData = _.cloneDeep(daWinciSynergiMapping.deptRuMapList).filter(
    //   (element) => !(element.action === null)
    // );
    let companyData = _.cloneDeep(daWinciSynergiMapping.companyMapList).filter(
      (element) => !(element.action === null)
    );

    //creating put request body
    body.DawinciInstallation = props.assetCode;
    body = companyData;
   // body.deptRuMapLst = deptRuData;

    try {
      let response = await daWinciSynergiReferenceSaveAssetWise(props.accessToken, body);

      if (response.status === 200) {
        _setApiResponseStatus(API_RESPONSE_STATUS.SUCCESS);
        _setSnackbarMessage("Saved successfully");
      } else {
        appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
        
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
      }
    } catch (error) {
      appInsightTrackException(error);
    }

    setToggleSaveFlag(!toggleSaveFlag);
    _setShouldOpen(true);
  };


// Department saving for RU

const saveDawinciSynergiDeptMapping = async () => {
  setUserWarningComapny(false)
  let body = _.cloneDeep(daWinciSynergDeptSaveRequest);
  //warning for saving data
  
  //remove all action = null
  let deptRuData = _.cloneDeep(deptMapping.deptRuMapList).filter(
    (element) => !(element.action === null)
  );

  //creating put request body
  body.dawinciInstallationCode = props.assetCode;
  body.synergiCaseTypeDescription = "Man Hours - Asset owner/Responsible unit";
  body.synergiCaseTypeId = 1;
  body.deptRuMapLst = deptRuData;

  try {
    let response = await daWinciSynergiDepartmentSave(props.accessToken, body);

    if (response.status === 200) {
      _setApiResponseStatus(API_RESPONSE_STATUS.SUCCESS);
      _setSnackbarMessage("Saved successfully");
    }
   
    else {
      appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
      
      _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
      _setSnackbarMessage("Something went wrong,Please Try Again")
     
    }
  } catch (error) {
    appInsightTrackException(error);
    _setSnackbarMessage( "Something went wrong,Please Try Again Later")
  }

  setToggleSaveFlag(!toggleSaveFlag);
  _setShouldOpen(true);
};

// Department saving for IU

const saveDawinciSynergiDeptMappingIU = async () => {
  setUserWarningComapny(false)
  
  let body = _.cloneDeep(daWinciSynergDeptSaveRequestIU);


  //warning for saving data
  
  //remove all action = null
  // let deptRuData = _.cloneDeep(deptMapping.deptRuMapList).filter(
  //   (element) => !(element.action === null)
  // );
  let deptIuData = _.cloneDeep(deptMapping.deptIuMapList)
  .filter(element => element.action !== null)
  .map(element => {
    return {...element};
  });//bad fix sending update for the iu records

  // let companyData = _.cloneDeep(daWinciSynergiDepartmentSave.companyMapList).filter(
  //   (element) => !(element.action === null)
  // );


  //creating put request body
  body.dawinciInstallationCode = props.assetCode;
  body.synergiCaseTypeDescription = "Man Hours - Internal suppliers/Units involved";
  body.synergiCaseTypeId = 2;
  body.deptRuMapLst = deptIuData;

  try {
    let response = await daWinciSynergiDepartmentSave(props.accessToken, body);

    if (response.status === 200) {
      _setApiResponseStatus(API_RESPONSE_STATUS.SUCCESS);
      _setSnackbarMessage("Saved successfully");
    } else {
      appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
      
      _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
      _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
    }
  } catch (error) {
    appInsightTrackException(error);
  }

  setToggleSaveFlag(!toggleSaveFlag);
  _setShouldOpen(true);
};



  //use effects
//   useEffect(() => {
//     const getCurrentMonthWorkHour = async () => {
//       try {
//         let response = await daWinciSynergiCurrentMonthWorkHours(
//           props.accessToken,
//           props.assetCode,
//         );
//         //handle error case notifications
//         if (response.status !== 200 ) {
//           appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)

//           _setShouldOpen(true);
//           if (response.status === 204) {
//             _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
//             _setSnackbarMessage(response.data);
//             setIsLoading(false);
//         setError({
//           errorStatus: true,
//           errorMessage:  response.data
//         });
//           } else {
//             setIsLoading(false);
//         setError({
//           errorStatus: true,
//           errorMessage: response.data
//         });
//             _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
//             _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data)

//           }
//        return
//         }
     
     
//       // Set the state for Responsible Unit
//      setCurrentMonthWorkHourData((prevData) =>{
//      const newData = response.data.data.filter(
//        (item) => item.synergiCaseTypeDescription === "Man Hours - Asset owner/Responsible unit")
//        if(newData[0].caseStatus == "Submitted" )
//        setCaseStatus(false)
//       else
//       setCaseStatus(true)

//        return newData;
//      });

//    // Set the state for Internal Unit
//    setCurrentMonthWorkHourDataIU((prevDataIU) =>{
//    const newDataIU =  response.data.data.filter(
//        (item) => item.synergiCaseTypeDescription === "Man Hours - Internal suppliers/Units involved"
//      )
//      if(newDataIU[0].caseStatus == "Submitted")
//       setCaseStatusIU(false);
//     else 
//     setCaseStatusIU(true);

//      return newDataIU
//     } );
//         setIsLoading(false);
//       } catch (error) {
//         appInsightTrackException(error);
//       }
//     };
// getCurrentMonthWorkHour();
//   }, [
//     props.accessToken,
//     props.assetCode,
//   ]);
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  //console.log(props.isEditModeOn_iu)
  const[mappingtab,setMappingtab]=useState()
  const handleTabChange = (event, value) => {
    const editstatus = props.isEditModeOn
    const userwarningcompany=props.userwarningcompany
    setUserWarningComapny(false)
    setMappingtab(value)
    if(userwarningcompany) return  setOpen(true)
    setTabValue(value);
  };
  const handleConfirm = (value) => {
    setOpen(false);
    setTabValue(mappingtab);
  };
  const handleCancel = () => {
    setOpen(false);

  };
  if (error.errorMessage || error.errorStatus){
    return (
    <Container maxWidth="lg">
            <Grid container spacing={1}>
              <GenericError errorMessage={error.errorMessage} />
            </Grid>
          </Container>
    )
  }

  return (
    <ruSelectionContext.Provider
      value={{
        _handleRuAssignmentToDept: handleRuAssignmentToDept,
        _handleDawinciTextChange: handleDawinciTextChange,
        _handleDawinciTextChangeDept:handleDawinciTextChangeDept,
   _handleSynergiCompanyAssignment: handleSynergiCompanyAssignment,
      }}
    >
       <React.Fragment>
      <BootstrapDialog onClose={handleCancel} open={open}>
        <DialogContent>
          <Typography gutterBottom>
            Please ensure to save your data before leaving
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirm}>
            Continue
          </Button>
          <Button autoFocus onClick={handleCancel} variant={"outlined"}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>

 
      <TabContext value={tabValue}>
      <Box sx={{ borderBottom: 1, borderColor: "divider",borderBlockStyle:"shadow"}}>
     <TabList onChange={handleTabChange}>
     <Tab label="Company" value="1" />
     <Tab label="Responsible Unit" value="2" />
     <Tab label="Involved Unit(PDP Drilling and Well)" value="3" />
                
     </TabList>
     
     </Box>
     <TabPanel value="1">
       <Grid container item xs={12} justifyContent="right" marginTop="10px" >
       <Tooltip title="Click here to save Company mapping" placement="top">
              <Button onClick={saveDawinciSynergiMapping} style={{ fontWeight: "bolder",marginRight:"30px"}}>SAVE MAPPING</Button>
              </Tooltip>
            </Grid>
            
      <React.Fragment>
        {isLoading ? (
          <StarProgress style={{ marginLeft: "50%", marginTop: "10%" }}/>
        ) : (
          <React.Fragment>
            <Grid container spacing={1} justifyContent="space-around">
                <CompanyMappingComponent
                  accessToken={props.accessToken}
                  searchText={props.searchText}
                  textChange={props.textChange}
                  data={daWinciSynergiMapping.companyMapList}

                  synergiCompanyList={props.synergiCompanyList}
                  handleRowAddition={handleTableRowAddition}
                  handleTableRowDelete={handleTableRowDelete}
                  isEditModeOn={props.isEditModeOn}
                />
              
             
            </Grid>
            <Divider />
            
          </React.Fragment>
        )}
       
      </React.Fragment>
      </TabPanel>
      <TabPanel value="2">
      <Grid container item xs={12} justifyContent="right" marginTop="10px" >
       <Tooltip title="Click here to save Responsible Unit mapping" placement="top">
              <Button 
             
              onClick={saveDawinciSynergiDeptMapping} 
              style={{ fontWeight: "bolder",marginRight:"30px"}}
              
              >SAVE MAPPING</Button>
              </Tooltip>
            </Grid>
                <DeptRuComponent
                  accessToken={props.accessToken}
                  data={deptMapping.deptRuMapList}
                  handleRowAddition={handleTableRowAdditionRUIU}
                  handleTableRowDeleteDept={handleTableRowDeleteDept}
                  childLevelsForRu={props.childLevelsForRu}
                 
                  assetId={props.assetId}
                  isEditModeOn_ru={props.isEditModeOn_ru}
                  
                />
        </TabPanel>
        <TabPanel value="3">
        <Grid container item xs={12} justifyContent="right" marginTop="10px" >
        
       <Tooltip title="Click here to save Involved Unit mapping" placement="top">
              <Button 
             
                     onClick={saveDawinciSynergiDeptMappingIU}
                      style={{ fontWeight: "bolder"}}
                      disabled={deptMapping.deptIuMapList.length === 0}
                      >SAVE MAPPING</Button>
              </Tooltip>
             
            </Grid>
            <Grid container item xs={12} justifyContent="right"marginBottom="-20px" >Note: Departments that have not been mapped to an Involved Unit can be assigned to one</Grid> 
                <DeptIuComponent
                  accessToken={props.accessToken}
                  dawinciInstallationCode={props.assetCode}
                  data={deptMapping.deptIuMapList}
                  handleRowAddition={handleTableRowAdditionRUIU}
                  handleTableRowDeleteDept={handleTableRowDeleteDept}
                  childLevelsForIu={props.childLevelsForIu}
                  assetId={props.assetId}
                  isEditModeOn_iu={props.isEditModeOn_iu}
                  onChildClick={handleChildClick}
                />
        </TabPanel>
        </TabContext>
        </React.Fragment>
    </ruSelectionContext.Provider>
  );
};

export default DaWinciScreenA;
