import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button, Tooltip, Grid } from "@mui/material";
import "./AssetDetail.css";
import DropDownB from "../DropDown/DropDownB";
import { levelOneLocationData } from "../_axios";
import { levelOneRespUnitData } from "../_axios";
import { assetDetailsAsync } from "../_axios";
import { arrayToChip } from "../CommonFunction";
import { snackbarContext } from "../../App";
import { API_RESPONSE_STATUS } from "../Constant";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import {
  appInsightTrackEvent,
  appInsightTrackException,
} from "../Logging/Logging";
import Divider from '@mui/material/Divider';
import SubscriptionButton from "../Notification/SubscriptionButton";
const AssetDetailB = (props) => {
  const [levelOneLocData, setLevelOneLocData] = useState([]);
  const [levelOneRuData, setLevelOneRuData] = useState([]);
  const[isSubscribed,setIsSubscribed]=useState()
  //state for snackbar
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
    useContext(snackbarContext);

  const assignMetadata = (value, identifier) => {
    let _metadata = { ...props.metadata };
    if (identifier === "location") {
      _metadata.location = value;
      props.setMetadata(_metadata);
    }

    if (identifier === "respUnit") {
      _metadata.responsibleUnit = value;
      props.setMetadata(_metadata);
    }
  };

  //loading first level data of locatin /ru
  const loadLevelOneLocData = useCallback(async () => {
    try {
      let responseLocData = await levelOneLocationData(props.accessToken);
      if (responseLocData.status === 200) {
        setLevelOneLocData(responseLocData.data.data);
      } 
      else {
      appInsightTrackEvent(responseLocData.data === "" ? "Something went wrong,Please Try Again" : responseLocData.data);
        _setShouldOpen(true);
        if (responseLocData.status === 204) {
          _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
          _setSnackbarMessage(responseLocData.data.status.message);
        } else {
          _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
          _setSnackbarMessage(responseLocData.data === "" ? "Something went wrong,Please Try Again" : responseLocData.data)
        }
      }
    } catch (error) {
      appInsightTrackException(error);
    }
  }, [props.accessToken]);

  //loading levels further down first level data of locatin /ru
  const loadLevelOneRuData = useCallback(async () => {
    try {
      let responseRuData = await levelOneRespUnitData(props.accessToken);

      if (responseRuData.status === 200) {
        setLevelOneRuData(responseRuData.data.data);
      } else {
        appInsightTrackEvent(responseRuData.data);
      }
    } catch (error) {
      appInsightTrackException(error);
    }
  }, [props.accessToken]);

  //get asset metadata. Currently being utilised to get location and ru detials of asset loaded
  const assetMetadataDetails = useCallback(async () => {
    try {
      let assetData = await assetDetailsAsync(props.accessToken, props.assetId);
      setIsSubscribed(assetData.data.data.isSubscribed)
      if (assetData.status !== 200) {
        appInsightTrackEvent(assetData.data);
      }

      // console.log(assetData.data.status);

      let _location = assetData.data.data.locationName.split("->");
      let _ru = assetData.data.data.ruName.split("->");

      props.setMetadata({
        location: _location.length > 0 ? _location : [],
        responsibleUnit: _ru.length > 0 ? _ru : [],
        locationId: assetData.data.data.locationId,
        ruId: assetData.data.data.ruId,
      });
    } catch (error) {
      appInsightTrackException(error);
    }
  }, [props.accessToken, props.assetId]);
  //use effect statements
  useEffect(() => {
    loadLevelOneLocData();
  }, [loadLevelOneLocData]);

  useEffect(() => {
    loadLevelOneRuData();
  }, [loadLevelOneRuData]);

  useEffect(() => {
    assetMetadataDetails();
  }, [assetMetadataDetails]);
  //screen alignment 3
  const locationJsx = () => {
    return (
      <React.Fragment>
        {/* <Grid container item xs={12} justifyContent="right">
        <SubscriptionButton
          accessToken={props.accessToken}
          assetId={props.assetId}
          isSubscribed={isSubscribed}
          />
                <span>
                  <div
                    style={{marginTop:"55px",marginLeft:"5px"}}
                   
                  >
                  
                  </div>
                  

                </span>
             
              </Grid>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent={"space-between"}
          textAlign={"center"}
          alignContent={"center"}
        >
          
          <Grid container>
            <Grid item xs={1}>
              <DropDownB
                levelOneData={levelOneLocData}
                ChildLevelData={props.childLevelsForLocation}
                accessToken={props.accessToken}
                assetId={props.assetId}
                buttonName={"Location"}
                identifier={"location"}
                assignMetadata={assignMetadata}
              />
            </Grid>
            <Grid item xs={11} alignSelf={"center"}>
              <Grid container direction="row">
                {arrayToChip(props.metadata.location)}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={1} justifyContent="center">
              <DropDownB
                levelOneData={levelOneRuData}
                ChildLevelData={props.childLevelsForRu}
                accessToken={props.accessToken}
                assetId={props.assetId}
                buttonName={"Responsible Unit"}
                identifier={"respUnit"}
                assignMetadata={assignMetadata}
              />
            </Grid>
            <Grid item xs={11} alignSelf={"center"}>
              <Grid container direction="row">
                {props.metadata.responsibleUnit && arrayToChip(props.metadata.responsibleUnit)}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
          </Grid>
        </Grid>
        <Divider></Divider> */}
      </React.Fragment>
    );
  };

  return <React.Fragment>{locationJsx()}</React.Fragment>;
};

export default AssetDetailB;
