// PopupForm.js
import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle,Select, TextField, MenuItem, FormControl, InputLabel, Button, ListSubheader } from '@mui/material';
import MultiLevelDropdown from './LocoationDropdown/AssetFormDropdown';
import MultiLevelDropdown2 from './ResponsibleUnitDropdown/Assetformdrop_resp';
import PopOverContentTest from './ResponsibleUnitDropdown/Assetformdrop_resp';
import { assetPost, getcluster } from '../_axios';
import { snackbarContext } from '../../App';
import { API_RESPONSE_STATUS } from '../Constant';
import { appInsights,
  appInsightTrackEvent,
  appInsightTrackException, } from '../Logging/Logging';
import MultiLevelDropdown_Parent from './LocoationDropdown/AssetFormDropdown_Parent';
import MultiLevelDropdown_Resp from './ResponsibleUnitDropdown/Assetformdrop_resp';
import MultiLevelDropdown_Resp_Parent from './ResponsibleUnitDropdown/Assetformdrop_resp_parent';
import { MyContext } from './AssetTable';
function PopupForm({ props,accessToken,open, rowData, handleClose, handleSaveRow  }) {
  

  const defaultFormValues = {
    id: '',
    textField1: '',
    textField2: '',
    dropdown1: '',
    dropdown2: '',
    dropdown3: '',
    dropdown4: '',
    dropdown5: '',
  };
  const { refresh, setRefresh } = useContext(MyContext);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [cluster, setCluster] = useState([]);
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
  });
  const [formValue, setFormValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [clustername, setClustername] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const[clusterId,setClusterId]=useState(formValues.clusterId)
  const [locationId, setLocationId] = useState();
  useEffect(() => {
  setLocationId(formValues.locationId || "");
  }, [formValues.locationId]);

  const [parentLocationId, setParentLocationId] = useState();
  useEffect(() => {
    setParentLocationId(formValues.parentLocationId || "");
  }, [formValues.parentLocationId]);
  
  const [respId, setRespId] = useState();
  useEffect(() => {
    setRespId(formValues.unitId || "");
  }, [formValues.unitId]);

  const [parentRespId, setParentRespId] = useState(formValues.parentUnitId);
  useEffect(() => {
    setParentRespId(formValues.parentUnitId || "");
  }, [formValues.unitId]);

  const handleLocationIdChange = (locationId) => {
    setLocationId(locationId);
  };

const parenthandleLocationIdChange = (parentLocationId) => {
  setParentLocationId(parentLocationId);
};

const handleRespIdChange = (respId) => {
  setRespId(respId);
};
const parenthandleRespIdChange = (parentRespId) => {
  setParentRespId(parentRespId);
};

    //state for snackbar
    const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
    useContext(snackbarContext);
  useEffect(() => {
    setFormValues({
      ...defaultFormValues,
      ...rowData // Populate with rowData if provided
    });
  }, [rowData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const selectedItem = cluster.find(item => item.name === value); // find item by name
  
    
 
   
      setFormValues(prevValues => ({
        ...prevValues,
        textField1: name === 'textField1' ? value : prevValues.textField1,
        textField2: name === 'textField2' ? value : prevValues.textField2,
        dropdown1: name === 'dropdown1' ? selectedItem.name : prevValues.dropdown1,
        dropdown2: name === 'dropdown2' ? value : prevValues.dropdown2,
        dropdown3: name === 'dropdown3' ? value : prevValues.dropdown3,
        dropdown4: name === 'dropdown4' ? value : prevValues.dropdown4,
        dropdown5: name === 'dropdown5' ? value : prevValues.dropdown5,
        // Add any other keys you want to update here
      }));

      if (selectedItem) {
        const selectedName = event.target.value;
        const selectedId = cluster.find((item) => item.name === selectedName).id;
        setClusterId(selectedId); // set id as clusterId
      }


    
  };
  const handleSave = async () => {
    const transformedData = {
      actionName: formValues.actionType,
      userEmail: rowData?.name,
      assetName: formValues.textField1,
      assetId:formValues.assetId  || 0,
      dawinciInstallationCode: formValues.textField2,
      clusterId: clusterId ,
      locationId: locationId, 
      parentLocationId: parentLocationId,
      unitId: respId,
      parentUnitId: parentRespId,
    };
  
    try {
      let response = await assetPost(accessToken, transformedData);
   if (response.status === 200) {
        _setApiResponseStatus(API_RESPONSE_STATUS.SUCCESS);
        _setSnackbarMessage("Saved successfully");
        // Call callback function with true value if API call is successful
      
       
      } else {
        appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data);
  
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data);
      }
    } catch (error) {
      appInsightTrackException(error);
    }
   
    setRefresh(formValues)
    handleSaveRow(formValues);
    handleClose();
  };
 
  //use effect to load cluster
useEffect(()=>{
  const getclusterdata = async ()=>{
    try{
      const response= await getcluster(accessToken);
     
      setIsLoading(false)
      if (response.status === 200) {
        setError({...error,errorStatus:false,errorMessage:""})
        setCluster(response.data);
       if(!selectedItem){
        setClustername(response.data[0].name)
     setSelectedItem(response.data[0].name)
       }
    

      } else if (response.status === 403) {
        appInsightTrackEvent("You are not Authorized to Access this Application");
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
         _setSnackbarMessage("You are not Authorized to Access this Application");
                   _setShouldOpen(true);
                   setIsLoading(false);
                   setError({
                     errorStatus: true,
                   errorMessage: "You are not Authorized to Access this Application",
                 });
      }
      else if (response.status === 500) {
        appInsightTrackEvent("The site seems to be unavailable, please try after some time.");
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
         _setSnackbarMessage("The site seems to be unavailable, please try after some time.");
                   _setShouldOpen(true);
                   setIsLoading(false);
                   setError({
                     errorStatus: true,
                   errorMessage: "The site seems to be unavailable, please try after some time.",
                 });
      }
      else if (response.status === 404) {
        appInsightTrackEvent("The site seems to be unavailable, please try after some time.");
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
         _setSnackbarMessage("The site seems to be unavailable, please try after some time.n");
                   _setShouldOpen(true);
                   setIsLoading(false);
                   setError({
                     errorStatus: true,
                   errorMessage: "The site seems to be unavailable, please try after some time.",
                 });
      }
       else {
        appInsightTrackEvent(response.data === "" ? "Something went wrong,Please try again" : response.data);
        //notification for error
        _setShouldOpen(true);
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage(response.data === "" ? "Something went wrong,Please try again" : response.data)
        setIsLoading(false);
        setError({
          errorStatus: true,
          errorMessage: (response.data === "" ? "Something went wrong,Please try again": response.data),
        });
      }
    } catch (error) {
      setIsLoading(false);
       setError({
        errorStatus: true,
        errorMessage: "Something went wrong,Please try again"
      });
      appInsightTrackException(error);

    }
  };

 

  getclusterdata();
}, [accessToken]);

const handleSelectChange = (event) => {
  setSelectedItem(event.label);
  setClustername(event.label)
  //console.log(event.label)
};
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
          style: {
            width: '1050px',
            maxWidth: '1050px', 
            fontFamily:"Equinor",
            // Adjust this value as required
          }
      }}
    >
      <DialogTitle id="form-dialog-title">Add/Edit Asset</DialogTitle>
      <DialogContent style={{marginLeft:"50px",marginRight:"50px"}}>
        {/* Text Field 1 */}
       
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ marginRight: '5px' }}>
     Asset Name
       </div>
    <TextField
      autoFocus
      margin="dense"
      id="textField1"
      type="text"
      style={{ width: 530,marginRight:"115px"}} // Set the width as desired
      name="textField1"
      value={formValues.textField1}
      onChange={handleChange}
      disabled={formValues.textField1 ? true : false}
    />
  </div>


        {/* Text Field 2 */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ marginRight: '5px' }}>
     DawinciInstallation Code
            </div>
            <TextField
  margin="dense"
  id="textField2"
  type="text"
  style={{ width: 530 , marginRight:"115px"}}
  name="textField2"
  value={formValues.textField2}
  onChange={handleChange}
  disabled={formValues.textField2 ? true : false}
/>
  </div>

   {/* Dropdown 1 */}
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div>
    Cluster
            </div>

            <Select 
      id="dropdown-1"
      name="dropdown1"
      onChange={handleChange}
      value={formValues?.dropdown1} 
      style={{ width: 530 ,height:40,marginRight:"115px",marginTop:"8px",marginBottom:"8px"}}
      MenuProps={{ MenuListProps: { disableSelectionOnKeyDown: false } }}
    >
      {cluster.map(item => (
        <MenuItem key={item.id} value={item.name} id={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
       </div>
       <div style={{ display: 'inline-block', position: 'relative', whiteSpace: 'nowrap', overflow: 'hidden' }}>
  <div>
    <div style={{ display: 'inline-block', width: '120px' }}>
      Location
    </div>
    <div style={{ display: 'inline-block', marginLeft: '125px'}}>
    <MultiLevelDropdown
  style={{ position: 'absolute', top: 'auto', left: 0, zIndex: 1, backgroundColor: 'white' }}
  accessToken={accessToken}
  dropdownValue={formValues?.dropdown2}
  onLocationIdChange={handleLocationIdChange} // Pass the callback function as a prop
/>
 </div>
  </div>
</div>
    {/* Dropdown 2 */}
    <div style={{ display: 'inline-block', position: 'relative', whiteSpace: 'nowrap', overflow: 'hidden' }}>
  <div>
    <div style={{ display: 'inline-block', width: '120px' }}>
      Parent Location
    </div>
    <div style={{ display: 'inline-block', marginLeft: '125px' }}>
      <MultiLevelDropdown_Parent
       style={{ position: 'absolute', top: 'auto', left: 0, zIndex: 1, backgroundColor: 'white' }}
       accessToken={accessToken}
        dropdownValue={formValues?.dropdown3}
        onLocationIdChange={parenthandleLocationIdChange} />
    </div>
  </div>
</div>
        {/* Dropdown 3 */}
        <div style={{ display: 'inline-block', position: 'relative', whiteSpace: 'nowrap', overflow: 'hidden' }}>
  <div>
    <div style={{ display: 'inline-block', minWidth: '100px' }}>
      Responsible Unit
    </div>
    <div style={{ display: 'inline-block', marginLeft: '132px' }}>
       <MultiLevelDropdown_Resp 
       style={{ position: 'absolute', top: 'auto', left: 0, zIndex: 1, backgroundColor: 'white' }} 
       accessToken={accessToken}
       dropdownValue={formValues?.dropdown4} 
       onhandleRespIdChange={handleRespIdChange}
       />
    </div>
  </div>
</div>
        {/* Dropdown 4 */}
        <div style={{ display: 'inline-block', position: 'relative', whiteSpace: 'nowrap', overflow: 'hidden' }}>
  <div>
    <div style={{ display: 'inline-block', minWidth: '100px' }}>
      Parent Responsible Unit
    </div>
    <div style={{ display: 'inline-block', marginLeft: '82px' }}>
       <MultiLevelDropdown_Resp_Parent 
       style={{ position: 'absolute', top: 'auto', left: 0, zIndex: 1, backgroundColor: 'white' }} 
       accessToken={accessToken}
        dropdownValue={formValues?.dropdown5}  
        onhandleRespIdChange={parenthandleRespIdChange}
       />
    </div>
  </div>
</div>
        
        {/* <PopOverContentTest
        props={props}/> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PopupForm;