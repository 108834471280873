export const IDENTIFIER = {
  COMPANY: "company",
  DEPARTMENTRU: "deptRu",
  //RESPONSIBLE_UNIT: "respUnit",
  DEPARTMENTIU: "deptIu",
};

export const TABLE_ACTION = {
  ADD: "add",
  UPDATE: "update",
  DELETE: "delete",
};

export const NUMERAL = {
  ZERO: 0,
  THREE: 3,
  AUTO_SAVE_TIME: 1000 * 120, //1000 * x seconds
  DEFAULT_CASE_ID: 0,
};

export const API_RESPONSE_STATUS = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFORMATION: "info",
};

export const APP_INSIGHT_TYPE = {
  HTTP_RESPONSE: "httpResponse",
};

export const CASE_TYPE = {
  CASE_TYPE_RESPONSIBLE_UNIT : "ru",
  CASE_TYPE_INVOLVED_UNIT: "iu",
  CASE_TYPE_BOTH: "both",
}
 
export const DESCRIPTION = {
  RESPONSIBLE_UNIT: "Man Hours - Asset owner/Responsible unit",
  INVOLVED_UNIT: "Man Hours - Internal suppliers/Units involved",
};