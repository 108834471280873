import React, { useState, useEffect, createContext, useContext } from "react";

import { StarProgress } from "@equinor/eds-core-react";
import { Grid, Button, Divider, Tab } from "@mui/material";
import _ from "lodash";
import Container from "@mui/material/Container";
import { Tooltip} from "@mui/material";
import TabList from "@mui/lab/TabList/TabList";
import {Box} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import TabContext from "@mui/lab/TabContext/TabContext";

import { DialogActions, DialogContent,Dialog, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { daWinciSynergiReferenceData,
  daWinciSynergiCompanyReferenceData,
    daWinciSynergiReferenceSave,
    daWinciSynergiDepartmentSave,
    daWinciSynergiCurrentMonthWorkHours,
    daWinciSynergiCompanyListWithSearch
 } from "../../_axios";
import { globalVariable, snackbarContext } from "../../../App";
import GlobalCompanyMappingComponent from "./GlobalCompanyMappingComponent";
import GenericError from "../../GenericError/GenericError";
import { appInsightTrackEvent,appInsightTrackException } from "../../Logging/Logging";
import { API_RESPONSE_STATUS, IDENTIFIER, TABLE_ACTION } from "../../Constant";
import GlobalCompany from "./GlobalCompany";
export const ruSelectionContext = createContext();

const ParentCompany = (props) => {

  const [daWinciSynergiMapping, setDaWinciSynergiMapping] = useState({
    companyMapList: [],
  }); 
  const [isLoading, setIsLoading] = useState(true);
  const [toggleSaveFlag, setToggleSaveFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [synergiCompanyListAuto, setSynergiCompanyListAuto] = useState([]);

 
  function handleChildClick(value) {
   
    setToggleSaveFlag(true)// Output: 42
  }

  
  //context for snackbar
  const { _setShouldOpen, _setApiResponseStatus, _setSnackbarMessage } =
    useContext(snackbarContext);

    const {setUserWarningComapny ,_setIsEditModeOn} = useContext(globalVariable);
    const [error, setError] = useState({
      errorStatus: false,
      errorMessage: "",
    });
  const newDeptRuRow = {
    id: null,
    action: null,
    dawinciDepartment: "",
    synergiUnitId: null,
  };
  const newDeptIuRow = {
    id: null,
    action: null,
    dawinciDepartment: "",
    synergiUnitId: null,
  };

  const newCompanyRow = {
    id: null,
    action: null,
    synergiCompanyId: null,
    dawinciCompanyName: "",
    synergiCompanyName: "",
  };

  const daWinciSynergiSaveRequest = {
    DawinciInstallation: "",
    companyMapLst: [],
  };
  const daWinciSynergDeptSaveRequest = {
    dawinciInstallationCode: "",
    synergiCaseTypeDescription: "",
    synergiCaseTypeId: 0,
    deptRuMapLst: []

  };
  const daWinciSynergDeptSaveRequestIU = {
    dawinciInstallationCode: "",
    synergiCaseTypeDescription: "",
    synergiCaseTypeId: 0,
    deptRuMapLst: []
    

  };
  const [tabValue, setTabValue] = useState("1");
  const[iuValue,setIuValue]=useState(false);
  const[iuValuecheck,setIuValuecheck]=useState(false);
  const [currentMonthWorkHourData, setCurrentMonthWorkHourData] = useState([]);
  const [caseStatus, setCaseStatus] = useState(true);
  const [currentMonthWorkHourDataIU, setCurrentMonthWorkHourDataIU] = useState([]);
  const [caseStatusIU, setCaseStatusIU] = useState(true);
  useEffect(() => {
    const daWinciRefData = async () => {
      try {
        let response;
        response = await daWinciSynergiCompanyReferenceData(
          props.accessToken,
        );
        if (
          response.status === 200 ||
          response.status === 204
        ) {
          if (response.status === 204) {
            appInsightTrackEvent(response.status.message);

            _setShouldOpen(true);
            _setApiResponseStatus(API_RESPONSE_STATUS.INFORMATION);
            _setSnackbarMessage(response.data.status.message);
          }
          //assign company mapping values from api
          let companyMappedData =
            response.data.data === null
              ? daWinciSynergiMapping.companyMapList
              : response.data.data;
         
         
          setDaWinciSynergiMapping({
            companyMapList: companyMappedData
            
          });
       
         
        } else {
          setError({
            errorStatus: true,
            errorMessage:  response.data
          });
          appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
           _setShouldOpen(true);
          _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
          _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
          setIsLoading(false);
        
       
        }

        setIsLoading(false);
        
      } catch (error) {
        appInsightTrackException(error);
      }
    };

    daWinciRefData();
  }, [props.accessToken, toggleSaveFlag]);

  useEffect(() => {
    const daWinSynCompanyData = async () => {
      let response;

      try {
        response = await daWinciSynergiCompanyListWithSearch(
          props.accessToken,
        );
     
        if (
          response.status === 200 ||
          response.status === 204
        ) {
          if (response.status === 204) {
            appInsightTrackEvent(response.data.message);
          }
          setSynergiCompanyListAuto(response.data.data);
        } else {
          appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data);

          //handle error
          _setShouldOpen(true);
          _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
          _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data);
        }
      } catch (error) {
        appInsightTrackException(error);
      }
    };

    //execute the api only when user types in atleast 3 alphabets
   
      daWinSynCompanyData();
  }, [props.accessToken]);

  const handleSynergiCompanyAssignment = (
    synergiCompany,
    index,
    synergiCompanyId
  ) => {
    let tableData = _.cloneDeep(daWinciSynergiMapping);
    tableData.companyMapList[index].synergiCompanyName = synergiCompany;
    tableData.companyMapList[index].synergiCompanyId = synergiCompanyId;

    //decide action based on id assigned
    tableData.companyMapList[index].id === null
      ? (tableData.companyMapList[index].action = TABLE_ACTION.ADD)
      : (tableData.companyMapList[index].action = TABLE_ACTION.UPDATE);

    setDaWinciSynergiMapping(tableData);
  };

  const handleTableRowDelete = (index, identifier) => {
    let tableData = _.cloneDeep(daWinciSynergiMapping);
    if (identifier === IDENTIFIER.COMPANY) {
      tableData.companyMapList[index].action = TABLE_ACTION.DELETE;
    }

    // if (identifier === IDENTIFIER.DEPARTMENTRU) {
    //   tableData.deptRuMapList[index].action = TABLE_ACTION.DELETE;
    // }

    setDaWinciSynergiMapping(tableData);
  };


  const saveDawinciSynergiMapping = async () => {
    setUserWarningComapny(false)
    let body = _.cloneDeep(daWinciSynergiSaveRequest);

  
    //warning for saving data
    
    //remove all action = null
    // let deptRuData = _.cloneDeep(daWinciSynergiMapping.deptRuMapList).filter(
    //   (element) => !(element.action === null)
    // );
    let companyData = _.cloneDeep(daWinciSynergiMapping.companyMapList).filter(
      (element) => !(element.action === null)
    );

    //creating put request body
    body.DawinciInstallation = props.assetCode;
    body = companyData;
   // body.deptRuMapLst = deptRuData;

    try {
      let response = await daWinciSynergiReferenceSave(props.accessToken, body);

      if (response.status === 200) {
        _setApiResponseStatus(API_RESPONSE_STATUS.SUCCESS);
        _setSnackbarMessage("Saved successfully");
      } else {
        appInsightTrackEvent(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
        
        _setApiResponseStatus(API_RESPONSE_STATUS.ERROR);
        _setSnackbarMessage(response.data === "" ? "Something went wrong,Please Try Again" : response.data)
      }
    } catch (error) {
      appInsightTrackException(error);
    }

    setToggleSaveFlag(!toggleSaveFlag);
    _setShouldOpen(true);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  //console.log(props.isEditModeOn_iu)
  const[mappingtab,setMappingtab]=useState()
  const handleTabChange = (event, value) => {
    const editstatus = props.isEditModeOn
    const userwarningcompany=props.userwarningcompany
    setUserWarningComapny(false)
    setMappingtab(value)
    if(userwarningcompany) return  setOpen(true)
    setTabValue(value);
  };
  const handleConfirm = (value) => {
    setOpen(false);
    setTabValue(mappingtab);
  };
  const handleCancel = () => {
    setOpen(false);

  };
  if (error.errorMessage || error.errorStatus){
    return (
    <Container maxWidth="lg">
            <Grid container spacing={1}>
              <GenericError errorMessage={error.errorMessage} />
            </Grid>
          </Container>
    )
  }
  return (
    <ruSelectionContext.Provider
      value={{
   _handleSynergiCompanyAssignment: handleSynergiCompanyAssignment,
      }}
    >
       <React.Fragment>
      <BootstrapDialog onClose={handleCancel} open={open}>
        <DialogContent>
          <Typography gutterBottom>
            Please ensure to save your data before leaving
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirm}>
            Continue
          </Button>
          <Button autoFocus onClick={handleCancel} variant={"outlined"}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>


      <Box sx={{ borderBottom: 1, borderColor: "divider",borderBlockStyle:"shadow"}}>
     
     
     </Box>
     <Grid container item xs={12} justifyContent="right" marginTop="10px" >
       <Tooltip title="Click here to save Company mapping" placement="top">
       <Button onClick={saveDawinciSynergiMapping} style={{ fontWeight: "bolder",marginRight:"30px"}}>SAVE MAPPING</Button>
              </Tooltip>
            </Grid>
            
      <React.Fragment>
        {isLoading ? (
          <StarProgress style={{ marginLeft: "50%", marginTop: "10%" }}/>
        ) : (
          <React.Fragment>
      <Grid style={{width:"95%", marginLeft:"35px",marginRight:"25px"}}>
  <GlobalCompanyMappingComponent
    data={daWinciSynergiMapping.companyMapList}
    searchText={searchText}
    synergiCompanyList={synergiCompanyListAuto}
    handleTableRowDelete={handleTableRowDelete}
    textChange={props.textChange}
    isEditModeOn={props.isEditModeOn}
    isEditModeOn_iu={props.isEditModeOn_iu}
  />
</Grid>
            <Divider />
            
          </React.Fragment>
        )}
       
      </React.Fragment>
    
        </React.Fragment>
    </ruSelectionContext.Provider>
  );
};

export default ParentCompany;
